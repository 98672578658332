<ng-template #loadingTemplate>
  <div class="page-padding">
    Загрузка документов <fa-icon [icon]="iconCircleNotch" [spin]="true"></fa-icon>
  </div>
</ng-template>

<ng-template #noDocsTemplate>
  <div class="page-padding font-italic">В этой категории нет документов</div>
</ng-template>

<ng-container *ngIf="docs$ | async as docs; else loadingTemplate">
  <ng-container *ngIf="docs.data.length; else noDocsTemplate">
    <div #heading class="row heading d-none d-md-flex">
      <div class="col-8">Название</div>
      <div class="col-3">Размер</div>
      <div class="col"></div>
    </div>

    <div class="row doc-card" *ngFor="let doc of docs.data">
      <div class="col-12 col-md-8 mb-3 mb-md-0 d-flex align-items-center">
        <span><img alt="Файл" class="mr-2" src="/assets/svg/redesign/file.svg"></span>
        <span class="text-overflow-ellipsis overflow-hidden text-nowrap" [title]="doc.filename">{{ doc.filename }}</span>
      </div>
      <div class="col-3 text-nowrap">{{ doc.human_size | uppercase }}</div>
      <div class="ml-auto"><a [href]="doc.doc" [title]="'Скачать (' + doc.human_size + ')'"><img alt="Скачать файл" src="/assets/svg/download.svg" /></a></div>
    </div>

    <app-paginator *ngIf="pagesCount > 1" [startPage]="page" (pageChange)="docs$ = getPage$($event)" [alignCenter]="true" [pagesCount]="pagesCount"></app-paginator>
  </ng-container>
</ng-container>
