<form [formGroup]="form">
  <div class="row">
    <div class="col-12 col-md-9 col-lg-7 col-xl-5">
      <div class="form-group mb-5">
        <label class="mb-3">Цель кредита</label>
        <select formControlName="credit_target" class="form-control">
          <option *ngFor="let target of creditTargets | keyvalue" [value]="target.key">{{ target.value.label }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="form-group mb-5">
    <label class="mb-3">Срок, мес</label>
    <div class="row">
      <app-changers-input
        class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2"
        formControlName="credit_term"
        [invalid]="form.get('credit_term').touched && form.get('credit_term').invalid"
        [separate]="true"
        min="1"
      ></app-changers-input>
    </div>
    <ng-container *ngIf="form.get('credit_term').touched">
      <p class="text-danger help-block mt-1" *ngIf="form.get('credit_term').errors?.required">Обязательное поле</p>
      <p class="text-danger help-block mt-1" *ngIf="form.get('credit_term').errors?.min">Не может быть меньше 1</p>
      <p class="text-danger help-block mt-1" *ngIf="form.get('credit_term').errors?.pattern">Только целые числа</p>
      <p class="text-danger help-block mt-1" *ngIf="form.get('credit_term').errors?.limit">Не может быть больше {{ creditTargets[form.get('credit_target').value].term_limit }}</p>
    </ng-container>
  </div>

  <div class="row">
    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
      <div class="form-group mb-5" formArrayName="revenue">
        <label class="mb-3">Выручка за последние 5 кварталов, ₽</label>
        <div class="form-group row" *ngFor="let revenueItem of revenue.controls | keyvalue">
          <label class="col flex-grow-0 text-nowrap col-form-label" [attr.for]="'revenue_' + revenueItem.key">{{ revenueLabel(revenueItem.key) }}</label>
          <div class="col">
            <input
              [id]="'revenue_' + revenueItem.key"
              [formControlName]="revenueItem.key"
              class="form-control no-appearance"
              [class.is-invalid]="revenueItem.value.touched && revenueItem.value.invalid"
              type="text"
              appFormat
              formatType="float"
              [separate]="true"
            />
          </div>
        </div>
        <ng-container *ngIf="returnFirstError('revenue')">
          <p class="text-danger help-block mt-1" *ngIf="returnFirstError('revenue').min">Не может быть меньше 100000</p>
          <p class="text-danger help-block mt-1" *ngIf="returnFirstError('revenue').max">Не может быть больше 10 млрд</p>
          <p class="text-danger help-block mt-1" *ngIf="returnFirstError('revenue').required">Заполните все поля</p>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="form-group mb-5">
    <label class="mb-3">Сумма кредита, ₽</label>
    <input
      type="text"
      formControlName="sum"
      class="form-control w-auto"
      [class.is-invalid]="form.get('sum').touched && form.get('sum').invalid"
      appFormat
      formatType="float"
      [separate]="true"
    />
    <p class="invalid-feedback" *ngIf="form.get('sum').errors?.required">Обязательное поле</p>
    <p class="invalid-feedback" *ngIf="form.get('sum').errors?.min">Не может быть меньше 100 тыс.</p>
    <p class="invalid-feedback" *ngIf="form.get('sum').errors?.max">Не может быть больше 10 млрд</p>
    <p class="invalid-feedback" *ngIf="form.get('sum').errors?.limit">Максимальная одобренная сумма: {{ getSumLimit() | septhousand }}</p>
    <p class="invalid-feedback" *ngIf="form.get('sum').errors?.no_revenue">Небходимо заполнить поля выручки</p>
  </div>

  <div class="form-group mb-5">
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input"
        formControlName="material_losses"
        id="material_loses"
        type="checkbox"
      />
      <label class="custom-control-label" for="material_loses">Отсутствие убытков более двух отчетных периодов подряд (2 года)</label>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
      <div class="form-group mb-5">
        <label>ИНН заявителя</label>
        <div class="separated-input-group">
          <input formControlName="inn" class="form-control" type="text" [class.is-invalid]="form.get('inn').touched && (form.get('inn').invalid || inn1Error)" [readOnly]="lockInn" />
          <button class="btn btn-primary btn-hollow btn-lg" (click)="loadInn1()" [disabled]="loading || enrichOngoing || !validScoring1()">Скоринг</button>
        </div>
        <p class="help-block text-danger" *ngIf="form.get('inn').touched && form.get('inn').invalid && form.get('inn').errors.required">Введите ИНН</p>
        <p class="help-block text-danger" *ngIf="form.get('inn').touched && form.get('inn').invalid && form.get('inn').errors.pattern">Недопустимый ИНН</p>
        <p class="help-block mt-1" *ngIf="inn1Error">{{ inn1Error }}</p>
      </div>
    </div>
  </div>
</form>
