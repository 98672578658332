import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CodeConfirmModalComponent } from '../modals/code-confirm-modal/code-confirm-modal.component';
import { AuthService } from '../services/auth.service';
import { PhoneConfirmModalComponent } from '../modals/phone-confirm-modal/phone-confirm-modal.component';

@Injectable()
export class ConfirmCodeInterceptor implements HttpInterceptor {
  static isResponseAboutNullCode(response: HttpErrorResponse) {
    return response.error.hasOwnProperty('sms_code')
      && response.error.sms_code !== true;
  }

  constructor(private _modal: NgbModal, private _auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 400
            && (!req.body || !req.body.sms_code)
            && ConfirmCodeInterceptor.isResponseAboutNullCode(response)
            && !req.url.endsWith('change-mobile-phone')
          ) {
            const user = this._auth.authenticatedUser();
            if (user && !user.mobile_phone) {
              return this.updatePhone()
                .pipe(
                  switchMap(() => this.intercept(req, next)),
                  catchError(() => throwError(response))
                );
            }

            return this.confirmCode(req);
          }

          return throwError(response);
        })
      );
  }

  updatePhone() {
    const modal = this._modal.open(PhoneConfirmModalComponent, { size: 'lg', beforeDismiss: () => false });
    return from(modal.result);
  }

  confirmCode(req: HttpRequest<any>) {
    const modal = this._modal.open(CodeConfirmModalComponent, { size: 'lg', beforeDismiss: () => false });
    const instance = modal.componentInstance as CodeConfirmModalComponent;

    instance.title = 'Подтвердите действие';
    instance.endpoint = req.url;
    instance.data = req.body || {};
    instance.method = req.method.toLowerCase();

    return from(modal.result);
  }
}
