import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { InputFormatDirective } from '../../directives/input-format/input-format.directive';

function stopEvents(element: HTMLElement): Observable<MouseEvent | TouchEvent> {
  return merge<MouseEvent | TouchEvent>(
    fromEvent(element, 'mouseup'),
    fromEvent(element, 'touchend'),
    fromEvent(element, 'mouseleave')
  );
}

function makeForceTouchObservable(
  element: HTMLElement,
  dueTime: number,
  period: number,
  callback: (x?: any) => void
): Observable<number> {
  return timer(dueTime, period).pipe(
    tap(x => callback(x)),
    takeUntil(stopEvents(element))
  );
}

@Component({
  selector: 'app-changers-input',
  templateUrl: './changers-input.component.html',
  styleUrls: ['./changers-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ChangersInputComponent)
  }]
})
export class ChangersInputComponent implements OnInit, ControlValueAccessor {
  @Input() invalid = false;
  @Input() separate = false;
  @Input() max: number;
  @Input() min: number;

  @ViewChild('input', { static: true }) input: InputFormatDirective;

  @ViewChild('addBtn', { static: true })
  set addBtn(btn: ElementRef<HTMLButtonElement>) {
    this.forceAdd$ = makeForceTouchObservable(btn.nativeElement, 500, 100, () => this.add());
  }

  @ViewChild('subBtn', { static: true })
  set subBtn(btn: ElementRef<HTMLButtonElement>) {
    this.forceSub$ = makeForceTouchObservable(btn.nativeElement, 500, 100, () => this.sub());
  }

  onChange: (...a: any[]) => any;
  onTouched: (...a: any[]) => any;
  disabled = false;

  forceAdd$: Observable<any>;
  forceSub$: Observable<any>;

  @Input()
  set value(value: number) {
    this._value = value;

    if (this.onChange) {
      this.onChange(value);
    }
  }

  get value() {
    if (typeof this._value === 'number') {
      return this._value;
    } else {
      return this.min || 0;
    }
  }

  private _value: number;

  constructor(private _renderer: Renderer2) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: number) {
    this._value = value;
  }

  ngOnInit(): void {
  }

  addMouseDown() {
    this.add();
    if (this.onTouched) {
      this.onTouched();
    }
    this.forceAdd$.subscribe();
  }

  subMouseDown() {
    this.sub();
    if (this.onTouched) {
      this.onTouched();
    }
    this.forceSub$.subscribe();
  }

  add() {
    if (this.max === undefined || this.value < this.max) {
      this.value++;
    }
  }

  sub() {
    if (this.min === undefined || this.value > this.min) {
      this.value--;
    }
  }
}
