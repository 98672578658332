import { IGuaranteeDetailed, IScoringValue } from '../../app/types';
import { Guarantee } from './guarantee';
import { IDoc, IDocType } from '../../app/services/files';

export class GuaranteeDetailed extends Guarantee implements IGuaranteeDetailed {
  scoring: IScoringValue[];
  docs: IDoc[];

  getDocsByType(typeId: IDocType['id']) {
    return this.docs.filter(d => d.doc_type.id === typeId);
  }

  getDocTypes(): IDocType[] {
    const knownId: number[] = [];

    return this.docs
      .filter((t => !knownId.includes(t.doc_type.id) && knownId.push(t.doc_type.id)))
      .map(d => d.doc_type);
  }
}
