import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concat, EMPTY, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChatMessage } from '../types';
import { tap } from 'rxjs/operators';

type MessageStatus = 'delivery' | 'read';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private readonly _endpoint = `${environment.apiEndpoint}/chat`;

  constructor(private _http: HttpClient) { }

  send(offerType: string, offerId: number, message: string, docs: number[]) {
    return this._http.post<ChatMessage>(this._endpoint, {
      message,
      offer_type: offerType,
      offer_id: offerId,
      docs: docs || undefined
    });
  }

  markDelivered(ids: number[]): Observable<object> {
    return this._mark(ids, 'delivery');
  }

  markRead(ids: number[]): Observable<object> {
    return this._mark(ids, 'read');
  }

  getAll(requestType: string, offerId: number): Observable<ChatMessage[]> {
    return this._http.get<ChatMessage[]>(`${this._endpoint}/${requestType}/${offerId}`)
      .pipe(
        tap(messages => this._findNotReadAndMark(messages).subscribe())
      );
  }

  private _findNotReadAndMark(messages: ChatMessage[]): Observable<any> {
    const notMine = messages.filter(msg => !msg.is_mine);
    const notRead = notMine.filter(msg => !msg.read_at);
    const notDelivered = notRead.filter(msg => !msg.delivery_at);

    const notDeliveredIds = notDelivered.map(msg => msg.id);
    const delivery$: Observable<any> = notDeliveredIds.length ? this.markDelivered(notDeliveredIds) : EMPTY;

    const notReadIds = notRead.map(msg => msg.id);
    const read$: Observable<object> = notReadIds.length ? this.markRead(notReadIds) : EMPTY;

    return concat(delivery$, read$);
  }

  private _mark(messages: number[], markType: MessageStatus): Observable<object> {
    return this._http.post(`${this._endpoint}/${markType}`, { messages });
  }
}
