import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { AnalyticService } from '../../../services/analytic.service';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { FilesService, IDoc, IDocTypeDetailed, IFileUploadEvent, FileUploadEventType } from '../../../services/files';
import { FileUploadEventBuilder } from '../../../services/files/file-upload-event-builder';
import { ProgressBarType } from '../../../types';
import { DocAndSignature } from './interfaces';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FileUploaderComponent,
    multi: true
  }]
})
export class FileUploaderComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() swapButton = false;
  @Input() hideDescription = false;
  @Input() label = 'Загрузить';
  @Input() barType: ProgressBarType = 'info';
  @Input() barStriped = false;
  @Input() barAnimated = false;

  @Input() docType: IDocTypeDetailed;
  @Input() optional = true;
  @Input() single = false;
  @Input() noSignature = false;
  @Input() preloaded: IDoc[] | IDoc;

  @Output() selected: EventEmitter<IFileUploadEvent> = new EventEmitter<IFileUploadEvent>();
  @Output() startUploading: EventEmitter<Observable<IFileUploadEvent>> = new EventEmitter<Observable<IFileUploadEvent>>();
  @Output() progressReport: EventEmitter<IFileUploadEvent> = new EventEmitter<IFileUploadEvent>();
  @Output() complete: EventEmitter<IFileUploadEvent> = new EventEmitter<IFileUploadEvent>();
  @Output() deleted: EventEmitter<IDoc> = new EventEmitter<IDoc>();
  @Output() catch: EventEmitter<any> = new EventEmitter<IFileUploadEvent>();

  @ViewChild('fileInput', {static: true}) private _fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('signatureInput', {static: false}) private _signatureInput: ElementRef<HTMLInputElement>;

  readonly fileIcon = faFile;

  disabled = false;
  uploading = false;
  progress = 0;
  error = '';

  private _onChangeFn: (docs: IDoc[] | IDoc) => any;
  private _onTouchFn: () => void;

  private readonly _customErrors = {
    413: 'Файл слишком большой'
  };

  private _uploadedDocs: DocAndSignature[] = [];
  private _eventFactory: FileUploadEventBuilder;

  constructor(
    private readonly _http: HttpClient,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _router: Router,
    private readonly _analytics: AnalyticService,
    private readonly _files: FilesService
  ) {}

  ngOnInit(): void {
    if (this.preloaded) {
      if (this.preloaded instanceof Array) {
        this._loadDocs(this.preloaded);
      } else {
        this._loadDocs([this.preloaded]);
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(docs: IDoc[] | IDoc | null): void {
    if (docs instanceof Array) {
      this._loadDocs(docs);
    } else {
      this._loadDocs(docs ? [docs] : []);
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChangeFn = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};
    let valid = true;

    if (!this.optional && !this._uploadedDocs.length) {
      errors['required'] = true;
      valid = false;
    }

    if (valid) {
      return null;
    }

    return errors;
  }

  maxFiles(): number {
    return this.single ? 1 : 0;
  }

  canUpload() {
    return !(this.uploading || this._countLimitReached());
  }

  getUploadedFiles(): DocAndSignature[] {
    return this._uploadedDocs;
  }

  reset() {
    this._uploadedDocs = [];
    this.progress = 0;
    this._fileInput.nativeElement.value = '';
    this._changeDetector.detectChanges();
  }

  openSelector() {
    if (!this.canUpload()) return;
    this._fileInput.nativeElement.click();
  }

  select() {
    this.error = '';
    const files: FileList = this._fileInput.nativeElement.files;

    if (files.length === 0 && this._uploadedDocs.length === 0) {
      if (!this.optional) {
        this.error = 'Не был выбран ни один файл';
        this.catch.emit(this._eventFactory.buildError(this.error));
      }

      return;
    }

    this._eventFactory = new FileUploadEventBuilder(this.docType ? this.docType.id : null, files[0].name);
    this.selected.emit(this._eventFactory.buildSelect());

    if (this._countLimitReached()) {
      this.error = 'Максимальное количество файлов: ' + this.maxFiles();
      this.catch.emit(this._eventFactory.buildError(this.error));
      return;
    }

    // File mime type checking

    // if (this.fileType.mime) {
    //   for (let file of Object.values(files)) {
    //     if (!this.fileType.mime.split(',').includes(file.type)) {
    //       console.log("Недопустимый тип файла:\nдопустимые:" + this.fileType.mime + "\nэтот файл:" + file.type);
    //       this.error = 'Недопустимый тип файла';
    //       this.catch.emit(this._eventFactory.createError(this.error));
    //       return;
    //     }
    //   }
    // }

    this._upload(this._fileInput.nativeElement.files[0]);
  }

  selectSignature(forFile: DocAndSignature) {
    const signatureFile = new File([this._signatureInput.nativeElement.files[0]], forFile.doc.filename + '.sig');
    this._upload(signatureFile);
    this._signatureInput.nativeElement.value = '';
  }

  remove(i: number) {
    const deletedDoc = this._uploadedDocs.splice(i, 1)[0];

    if (deletedDoc?.doc) {
      this.deleted.emit(deletedDoc.doc);
    }

    if (deletedDoc?.signature) {
      this.deleted.emit(deletedDoc.signature);
    }

    if (this._uploadedDocs.length === 0) {
      this.reset();

      if (!this.optional) {
        this.error = 'Необходимо загрузить хотя бы один файл';
      }
    }

    this._valueChanged();
  }

  private _upload(file: File) {
    if (this.error) return;

    this.uploading = true;
    const uploader$ = this._files.upload(file, this.docType?.id);

    uploader$.subscribe(
      event => {
        switch (event.type) {
          case FileUploadEventType.Progress:
            this.progressReport.emit(event);
            this.progress = event.progress;
            break;

          case FileUploadEventType.Complete:
            this.complete.emit(event);
            this.progress = 0;
            this.uploading = false;

            this._addDoc(event.response);
        }
      },
      response => {
        this.catch.emit(response);
        this.error = this._fetchError(response);
        this.progress = 0;
        this.uploading = false;
      }
    );

    this.startUploading.emit(uploader$);
  }

  private _getValue(): IDoc[] | IDoc {
    if (this.single) {
      return this._uploadedDocs[0]?.doc;
    }

    const docs: IDoc[] = [];

    for (const docPair of this._uploadedDocs) {
      if (docPair.doc) {
        docs.push(docPair.doc);
      }

      if (docPair.signature) {
        docs.push(docPair.signature);
      }
    }

    return docs;
  }

  private _valueChanged() {
    if (this._onChangeFn) {
      this._onChangeFn(this._getValue());
    }

    if (this._onTouchFn) {
      this._onTouchFn();
    }
  }

  private _loadDocs(docs: IDoc[]) {
    for (const doc of docs) {
      this._addDoc(doc);
    }
  }

  private _addDoc(doc: IDoc) {
    if (doc.is_signature) {
      let findDoc = this._uploadedDocs.find(d => d.doc?.signature?.id === doc.id);

      if (!findDoc) {
        findDoc = this._uploadedDocs.find(d => d.doc?.filename + '.sig' === doc.filename);
      }

      if (findDoc) {
        findDoc.signature = doc;
      } else {
        this._uploadedDocs.push({ signature: doc });
      }
    } else {
      if (doc.signature) {
        const findSignature = this._uploadedDocs.find(d => d.signature?.id === doc.signature.id);
        if (findSignature) {
          findSignature.doc = doc;
        } else {
          this._uploadedDocs.push({ doc });
        }
      } else {
        this._uploadedDocs.push({ doc });
      }
    }

    this._valueChanged();
  }

  private _fetchError(response) {
    if (response.error) {
      if (response.error.doc) {
        return response.error.doc[0];
      } else {
        return response.error.__all__ || response.error.non_field_errors || this._customErrors[response.status] || response.statusText;
      }
    }

    return response.statusText;
  }

  private _countLimitReached() {
    const max = this.maxFiles();
    return max > 0
      ? this._uploadedDocs.length >= max
      : false;
  }
}
