import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-width-spoiler',
  templateUrl: './full-width-spoiler.component.html',
  styleUrls: ['./full-width-spoiler.component.scss']
})
export class FullWidthSpoilerComponent {
  @Input() title;
  @Input() opened = false;
}
