import { Pipe, PipeTransform } from '@angular/core';
import { pluralize } from '../../helpers';

@Pipe({
  name: 'pluralizeRu'
})
export class PluralizeRuPipe implements PipeTransform {
  transform(value: number, form1: string, form2: string, form3: string): string {
    return pluralize(value, form1, form2, form3);
  }
}
