import { Directive, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsultModalComponent } from '../../../modals/consult-modal/consult-modal.component';

@Directive({
  selector: '[appOpenConsult]'
})
export class OpenConsultDirective {

  constructor(private _modals: NgbModal) { }

  @HostListener('click')
  click() {
    this._modals.open(ConsultModalComponent, { size: 'lg' });
  }

}
