<ng-template #loadingTemplate>
  <div class="text-center">
    <fa-icon [icon]="iconCircleNotch" [spin]="true" size="2x"></fa-icon>
  </div>
</ng-template>

<div class="container pb-5">
  <ng-container *ngIf="types$ | async as types; else loadingTemplate">
    <ng-container *ngFor="let type of types">
      <p class="category-spoiler mb-4" [class.active]="expanded(type.id)" (click)="toggle(type.id)" role="button">{{ type.label }}</p>
      <div class="mb-4">
        <app-doc-type-list *ngIf="expanded(type.id)" [type]="type"></app-doc-type-list>
      </div>
    </ng-container>
  </ng-container>
</div>

<app-consult-block></app-consult-block>
