import { IClientService, ICompanyInnInfo, IClientRequest, IOffer, IOfferData, IClientRequestStatusObject } from '../../app/types';
import { Observable } from 'rxjs';
import { AbstractUaHttpModel } from '../abstract-ua-http-model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export abstract class ClientRequest<O extends IOffer> extends AbstractUaHttpModel implements IClientRequest {
  id: number;
  company_declarer: ICompanyInnInfo;
  company_contractor?: ICompanyInnInfo;
  created_at: string;
  offers_count: number;
  service: IClientService;
  status: IClientRequestStatusObject;
  updated: boolean;
  viewed_at: string;
  draft: boolean;
  archived: boolean;
  reminder_time: string | null;

  constructor(http: HttpClient, data: IClientRequest) {
    super(http);
    Object.assign(this, data);
  }

  abstract getTerm(): number;
  abstract getSum(): number;

  protected abstract _castOffer(data: IOfferData): O;

  getStyle(): string {
    if (this.archived) {
      return 'success';
    }

    switch (this.status.code) {
      case 'IS_CANCELLED':
      case 'IS_REJECTED':
        return 'danger';
      case 'CLIENT_IS_FINANCED':
      case 'CREDITOR_IS_FINANCED':
      case 'IS_FINANCED':
        return 'success';
      case 'NEW_MESSAGE':
      case 'IS_ACCEPTED':
      case 'OFFERS':
        return 'warning';
    }

    return 'no-style';
  }

  getStatusText(): string {
    if (this.archived) {
      return 'Завершено';
    }

    if (this.draft) {
      return 'Черновик';
    }

    return this.status.msg;
  }

  getPageUrl(): string {
    let url = `${this.getCategoryUrl()}/${this.id}`;

    if (this.draft) {
      url += '/edit';
    }

    return url;
  }

  getCategoryUrl(): string {
    return `/account/${this._uaEndpoint}`;
  }

  loadOffers(): Observable<O[]> {
    return this._http.get<O[]>(this.getOffersEndpoint())
      .pipe(map(offers => offers.map(offer => this._castOffer(offer))));
  }

  newOffer(data: IOfferData): Observable<O> {
    return this._http.post<O>(this.getOffersEndpoint(), data)
      .pipe(map(offer => this._castOffer(offer)));
  }

  getOffersEndpoint(): string {
    return this.getEntryUrl() + '/offers';
  }

  isReminderOverdue(): boolean {
    return this.reminder_time && new Date(this.reminder_time) <= new Date;
  }
}
