<ng-container *ngIf="docType && !hideDescription">
  <h4>{{ docType.label }}</h4>
  <p class="text-muted mb-4">{{ docType.help_text }}</p>
</ng-container>

<div class="wrapper" [class.wrapper--reverse]="swapButton">
  <div class="uploaded-label mb-3" *ngFor="let fileWithSig of getUploadedFiles(); index as i">
    <a [href]="fileWithSig.doc.doc" download class="label-text">{{ fileWithSig.doc.filename }}</a>
    <label *ngIf="!noSignature">
      <input #signatureInput type="file" class="d-none" (change)="selectSignature(fileWithSig)" [disabled]="fileWithSig.signature" />
      <button class="signature-btn btn btn-success" [disabled]="fileWithSig.signature" (click)="signatureInput.click()">{{ fileWithSig.signature ? 'Подписано' : 'Загрузить подпись' }}</button>
    </label>
    <button class="remove-btn" (click)="remove(i)" title="Удалить файл"></button>
  </div>

  <ngb-progressbar *ngIf="uploading" [type]="barType" [value]="progress" [striped]="barStriped" [animated]="barAnimated" height="1.7rem">{{ progress }}%</ngb-progressbar>

  <label [ngClass]="{'d-none': !canUpload(), 'd-block': canUpload()}">
    <input #fileInput type="file" class="d-none" (change)="select()" />
    <span class="btn" [class.btn-outline-primary]="!error" [class.btn-outline-danger]="error">{{ label }}</span>
  </label>
</div>

<span class="help-block text-danger" *ngIf="error">{{ error }}</span>
