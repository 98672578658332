import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../user/user.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AnalyticService } from '../../services/analytic.service';

@Component({
  selector: 'app-code-confirm-modal',
  templateUrl: './code-confirm-modal.component.html',
  styleUrls: ['./code-confirm-modal.component.scss']
})
export class CodeConfirmModalComponent {
  form = this._fb.group({
    sms_code: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]]
  });

  @Input() endpoint: string;
  @Input() data: {[k: string]: any};
  @Input() method: string;
  @Input() title: string;

  iconLoading = faCircleNotch;
  backendErrors: {[k: string]: string} = {};
  actionSub: Subscription;
  loading = false;
  step = 0;

  get phone() {
    const user = this._auth.authenticatedUser();
    const userPhone = user ? user.mobile_phone : this.data.mobile_phone;
    const part = userPhone.slice(5, -2);
    return userPhone.replace(part, '*'.repeat(part.length));
  }

  get code() {
    return this.form.get('sms_code');
  }

  constructor(
    public activeModal: NgbActiveModal,
    private _http: HttpClient,
    private _fb: FormBuilder,
    private _userService: UserService,
    private _analytics: AnalyticService,
    private _router: Router,
    private _auth: AuthService
  ) {
    this._analytics.eventYandex('codeConfirmModal_open');
  }

  send() {
    if (this.actionSub) {
      this.actionSub.unsubscribe();
    }

    this.backendErrors = {};
    this.loading = true;

    this._analytics.eventYandex('codeConfirmModal_submit');

    this.actionSub = this.sendRequest().subscribe(
      response => {
        this.loading = false;
        this.activeModal.close(response);
      },

      response => {
        this.loading = false;

        if (typeof response.error === 'string') {
          this.backendErrors = {non_field_errors: `${response.statusText} ${response.status} ${response.error}`};
        } else {
          this.backendErrors = response.error;
        }
      }
    );

    return false;
  }

  sendRequest() {
    this.data.sms_code = this.code.value;

    console.log(`[CodeConfirmModal] ${this.method.toUpperCase()}`);
    return this._http.request(this.method, this.endpoint, { body: this.data, observe: 'response' });
  }

  getPlaceholder() {
    const len = (this.code.value || '').length;
    return '*'.repeat(6 - len);
  }
}
