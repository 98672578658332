import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { AnyClientRequest, AnyOffer, IOffer } from '../../types';
import { ClosedRequestsService } from '../../services/closed-requests.service';

@Component({
  selector: 'app-request-close-modal',
  templateUrl: './request-close-modal.component.html',
  styleUrls: ['./request-close-modal.component.scss']
})
export class RequestCloseModalComponent {
  title = 'Финансирование заявки';
  message = 'Вы получили финансирование?';
  confirmText = 'Да';
  dismissText = 'Нет';

  isUser = true;
  request: AnyClientRequest;
  offer: AnyOffer;
  sum = 0;

  iconLoading = faCircleNotch;
  loading = false;
  errorMessage = '';

  constructor (private _activeModal: NgbActiveModal, private _closedRequests: ClosedRequestsService) {
  }

  yes() {
    this.loading = true;

    (this.offer as IOffer).done().subscribe(
      value => {
        this.loading = false;
        this._activeModal.close('yes');
        this._closedRequests.onClose.emit();
      },
      error => {
        this.loading = false;

        if (error.error.detail) {
          this.errorMessage = error.error.detail;
        } else {
          this.errorMessage = error.error;
        }
      }
    );
  }

  no() {
    this._activeModal.close('no');
  }
}
