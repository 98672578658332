<form class="sign-up-form" [formGroup]="form" (submit)="submit()">
  <div class="form-group" *ngFor="let name of fieldsOrder">
    <mat-form-field class="w-100">
      <mat-label *ngIf="labels">{{ fields[name].label }}</mat-label>
      <input matInput [type]="fields[name].type" [formControlName]="name" [placeholder]="label(name)" />
      <mat-error>
        <ng-container *ngIf="isInvalid(name)">
          <ng-container *ngIf="form.get(name).errors">
            <ng-container *ngFor="let error of fields[name].errors | keyvalue">
              <div *ngIf="form.get(name).errors[error.key]">{{ error.value }}</div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="form.errors">
            <ng-container *ngFor="let error of fields[name].formCheck | keyvalue">
              <div *ngIf="form.errors && form.errors[error.key]">{{ error.value }}</div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="backendErrors[name]">
          <div *ngFor="let backendError of backendErrors[name]">{{ backendError }}</div>
        </ng-container>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" formControlName="privacy_policy" id="privacy_policy" [ngClass]="{'is-invalid': isInvalid('privacy_policy') }" class="custom-control-input">
      <label class="custom-control-label" for="privacy_policy">Согласен с <a href="https://docs.myfi24.ru/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%B8%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_MYFI.pdf" target="_blank">политикой конфиденциальности и обработки персональных данных</a></label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" formControlName="terms_of_service" id="terms_of_service" [ngClass]="{'is-invalid': isInvalid('terms_of_service') }" class="custom-control-input">
      <label class="custom-control-label" for="terms_of_service">Согласен с <a href="https://docs.myfi24.ru/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf" target="_blank">условиями предоставления сервиса</a></label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" formControlName="client_personal" id="client_personal" [ngClass]="{'is-invalid': isInvalid('client_personal') }" class="custom-control-input">
      <label class="custom-control-label" for="client_personal">Согласен с <a href="https://docs.myfi24.ru/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%9B%D0%9A%20%D0%9A%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0.pdf" target="_blank">соглашением об использовании ЛК Клиента</a></label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" formControlName="partner_personal" id="partner_personal" [ngClass]="{'is-invalid': isInvalid('partner_personal') }" class="custom-control-input">
      <label class="custom-control-label" for="partner_personal">Согласен с <a href="https://docs.myfi24.ru/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%9B%D0%9A%20%D0%9F%D0%B0%D1%80%D1%82%D0%BD%D0%B5%D1%80%D0%B0.pdf" target="_blank">соглашением об использовании ЛК Партнёра</a></label>
    </div>
  </div>

  <p *ngIf="backendErrors['detail'] || backendErrors['__all__']" class="text-danger">
    {{ backendErrors['detail'] || backendErrors['__all__'] }}
  </p>
  <button type="submit" class="btn btn-success btn-block btn-lg" [disabled]="form.invalid || loading">
    Зарегистрироваться <fa-icon *ngIf="loading" [icon]="faCircleNotch" [spin]="true"></fa-icon>
  </button>
</form>
