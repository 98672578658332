<div class="modal-header">
  <h2 class="modal-title text-left">
    Войти <a href="javascript:void(0)" class="modal-title-link" (click)="signUpClick()">Зарегистрироваться</a>
  </h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close button click')"></button>
</div>
<div class="modal-body">
  <div class="alert alert-info" *ngIf="message">{{ message }}</div>

  <form class="mb-1" [formGroup]="form" (submit)="submit()">
    <div class="form-group mb-4">
      <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input matInput required formControlName="email" type="text" />
        <mat-error *ngIf="isEmailRequired()">Введите email</mat-error>
        <mat-error *ngIf="isEmailWrong()">Неверный email</mat-error>
      </mat-form-field>
    </div>

    <div class="form-group mb-4">
      <mat-form-field class="w-100">
        <mat-label>Пароль</mat-label>
        <input matInput required type="password" formControlName="password" />
        <mat-error *ngIf="isPasswordRequired()">Введите пароль</mat-error>
      </mat-form-field>
    </div>

    <p class="help-block text-danger text-center">{{ error }}</p>
    <div class="text-center">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="form.pristine || form.invalid || loading">Отправить <fa-icon *ngIf="loading" [icon]="faCircleNotch" [spin]="true"></fa-icon></button>
    </div>
  </form>
  <div class="text-center mt-5">
    <a href="javascript:void(0)" class="forgot-password" (click)="resetClick()">Забыли пароль?</a>
  </div>
</div>
