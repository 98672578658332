import {
  IFactoringDetailed,
  IScoringValue
} from '../../app/types';
import { Factoring } from './factoring';
import { IDoc, IDocType } from '../../app/services/files';

export class FactoringDetailed extends Factoring implements IFactoringDetailed {
  scoring: IScoringValue[];
  docs: IDoc[];
  contract_period_left: number;

  getDocsByType(typeId: IDocType['id']) {
    return this.docs.filter(d => d.doc_type.id === typeId);
  }

  getDocTypes(): IDocType[] {
    const knownId: number[] = [];

    return this.docs
      .filter((t => !knownId.includes(t.doc_type.id) && knownId.push(t.doc_type.id)))
      .map(d => d.doc_type);
  }
}
