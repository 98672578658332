<!-- FORM TEMPLATES -->
<!-- FACTORING FORM -->
<ng-template #factoringFormTemplate>
  <form (submit)="sendForm()" [formGroup]="form">
    <div class="row align-items-end flex-column flex-md-row">
      <div class="col">
        <div class="form-group">
          <label>Лимит финансирования, ₽</label>
          <p class="text-muted small">Введите сумму лимита финансирования дебетора</p>
          <input formControlName="finance_limit" type="text" appFormat formatType="float" [separate]="true" class="form-control" [class.is-invalid]="isFieldInvalid(form, 'finance_limit')">
          <div class="invalid-feedback" *ngIf="form.get('finance_limit').errors?.required">Поле необходимо заполнить</div>
          <div class="invalid-feedback" *ngIf="form.get('finance_limit').errors?.pattern">Допустимы только числа</div>
          <div class="invalid-feedback" *ngIf="form.get('finance_limit').errors?.min">Не может быть 0 или меньше</div>
          <div class="invalid-feedback" *ngIf="backendErrors['finance_limit']">{{  backendErrors['finance_limit'] }}</div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Процент финансирования</label>
          <p class="text-muted small">Введите процент беззалогового финансирования в размере до 100% от суммы поставки</p>
          <div class="input-group">
            <input formControlName="finance_percent" type="text" appFormat formatType="float" class="form-control" [class.is-invalid]="isFieldInvalid(form, 'finance_percent')">
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
            <div class="invalid-feedback" *ngIf="form.get('finance_percent').errors?.required">Поле необходимо заполнить</div>
            <div class="invalid-feedback" *ngIf="form.get('finance_percent').errors?.pattern">Допустимы только числа</div>
            <div class="invalid-feedback" *ngIf="form.get('finance_percent').errors?.min">Не может быть 0 или меньше</div>
            <div class="invalid-feedback" *ngIf="form.get('finance_percent').errors?.max">Не может быть больше 100</div>
            <div class="invalid-feedback" *ngIf="backendErrors['finance_percent']">{{ backendErrors['finance_percent'] }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-end flex-column flex-md-row">
      <div class="col">
        <div class="form-group">
          <label>Ставка</label>
          <p class="text-muted small">Предложите эффективную ставку по факторингу</p>
          <div class="input-group">
            <input formControlName="rate" type="text" appFormat formatType="float" class="form-control" [class.is-invalid]="isFieldInvalid(form, 'rate')">
            <div class="input-group-append">
              <span class="input-group-text">% годовых</span>
            </div>
            <div class="invalid-feedback" *ngIf="form.get('rate').errors?.required">Поле необходимо заполнить</div>
            <div class="invalid-feedback" *ngIf="form.get('rate').errors?.pattern">Допустимы только числа</div>
            <div class="invalid-feedback" *ngIf="form.get('rate').errors?.min">Не может быть 0 или меньше</div>
            <div class="invalid-feedback" *ngIf="form.get('rate').errors?.max">Не может быть больше 100</div>
            <div class="invalid-feedback" *ngIf="backendErrors['rate']">{{ backendErrors['rate'] }}</div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Срок действия оффера</label>
          <p class="text-muted small">Введите дату окончания срока действия Вашего предложения</p>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="dd.mm.yyyy"
              formControlName="validity"
              ngbDatepicker
              [minDate]="minDate"
              [class.is-invalid]="isFieldInvalid(form, 'validity')"
              #d="ngbDatepicker"
            />
            <div class="input-group-append">
              <button class="btn btn-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="iconCalendar"></fa-icon></button>
            </div>
          </div>
          <div class="text-danger help-block" *ngIf="isFieldInvalid(form, 'validity')">
            <div class="mt-1" *ngIf="form.get('validity').errors?.required">Выберите дату</div>
            <div class="mt-1" *ngIf="backendErrors['validity']">{{ backendErrors['validity'] }}</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<!-- // FACTORING FORM -->

<!-- LEASING FORM -->
<ng-template #leasingFormTemplate>
  <form (submit)="sendForm()" [formGroup]="form">
    <div class="form-row align-items-stretch flex-column flex-md-row">
      <div class="col d-flex">
        <div class="form-group d-flex flex-column">
          <label>Процент удорожания в год</label>
          <p class="text-muted small">Указывается в годовых процентах</p>
          <div class="form-row mt-auto">
            <div class="col">
              <div class="input-group">
                <input
                  formControlName="rate"
                  type="text"
                  appFormat
                  formatType="float"
                  class="form-control"
                  [class.is-invalid]="isFieldInvalid(form, 'rate')"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="col">
              <span class="form-control-plaintext">годовых</span>
            </div>
          </div>
          <ng-container *ngIf="isFieldInvalid(form, 'rate')">
            <div class="help-block text-danger mt-1" *ngIf="form.get('rate').errors?.required">Поле необходимо заполнить</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('rate').errors?.pattern">Допустимы только числа</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('rate').errors?.min">Не может быть 0 или меньше</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('rate').errors?.max">Не может быть больше 100</div>
            <div class="help-block text-danger mt-1" *ngIf="backendErrors['rate']">{{ backendErrors['rate'] }}</div>
          </ng-container>
        </div>
      </div>
      <div class="col d-flex">
        <div class="form-group d-flex flex-column">
          <label>Эффективная ставка</label>
          <p class="text-muted small">Эффективная ставка указывается в годовых процентах</p>
          <div class="form-row mt-auto">
            <div class="col">
              <div class="input-group">
                <input
                  formControlName="effective_rate"
                  type="text"
                  appFormat
                  formatType="float"
                  class="form-control"
                  [class.is-invalid]="isFieldInvalid(form, 'effective_rate')"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="col">
              <span class="form-control-plaintext">годовых</span>
            </div>
          </div>
          <ng-container *ngIf="isFieldInvalid(form, 'effective_rate')">
            <div class="help-block text-danger mt-1" *ngIf="form.get('effective_rate').errors?.required">Поле необходимо заполнить</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('effective_rate').errors?.pattern">Допустимы только числа</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('effective_rate').errors?.min">Не может быть 0 или меньше</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('effective_rate').errors?.max">Не может быть больше 100</div>
            <div class="help-block text-danger mt-1" *ngIf="backendErrors['effective_rate']">{{ backendErrors['effective_rate'] }}</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-row align-items-end flex-column flex-md-row">
      <div class="col col-md-6 offset-md-6">
        <div class="form-group">
          <label>Срок действия оффера</label>
          <p class="text-muted small">Введите дату окончания срока действия Вашего предложения</p>
          <div class="input-group" [class.is-invalid]="isFieldInvalid(form, 'validity')">
            <input
              readonly
              class="form-control"
              placeholder="yyyy-mm-dd"
              formControlName="validity"
              ngbDatepicker
              [minDate]="minDate"
              #d="ngbDatepicker" />
            <div class="input-group-append">
              <button class="btn btn-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="iconCalendar"></fa-icon></button>
            </div>
          </div>
          <div class="invalid-feedback" *ngIf="form.get('validity').errors?.required">Выберите дату</div>
          <div class="invalid-feedback" *ngIf="backendErrors['validity']">{{ backendErrors['validity'] }}</div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Прикрепить файл:</label>
      <p class="text-muted small">Прикрепить .pdf файл, содержащий информацию по Вашему предложению лизинга</p>
      <app-file-uploader
        formControlName="payment_schedule_file"
        [single]="true"
        [noSignature]="true"
        [optional]="false"
      ></app-file-uploader>
    </div>
  </form>
</ng-template>
<!-- // LEASING FORM -->

<!-- CREDIT FORM -->
<ng-template #creditFormTemplate>
  <form (submit)="sendForm()" [formGroup]="form">
    <div class="form-row align-items-end flex-column flex-md-row">
      <div class="col">
        <div class="form-group">
          <label>Ставка</label>
          <p class="text-muted small">Указывается в годовых процентах, дроби должны вводиться через точку</p>
          <div class="form-row">
            <div class="col">
              <div class="input-group">
                <input
                  formControlName="rate"
                  type="text"
                  appFormat
                  formatType="float"
                  class="form-control"
                  [class.is-invalid]="isFieldInvalid(form, 'rate')"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="col">
              <span class="form-control-plaintext">годовых</span>
            </div>
          </div>
          <ng-container *ngIf="isFieldInvalid(form, 'rate')">
            <div class="help-block text-danger mt-1" *ngIf="form.get('rate').errors?.required">Поле необходимо заполнить</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('rate').errors?.pattern">Допустимы только числа</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('rate').errors?.min">Не может быть 0 или меньше</div>
            <div class="help-block text-danger mt-1" *ngIf="backendErrors['rate']">{{ backendErrors['rate'] }}</div>
          </ng-container>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Эффективная ставка</label>
          <p class="text-muted small">Эффективная ставка указывается в годовых процентах, дроби должны вводиться через точку</p>
          <div class="form-row">
            <div class="col">
              <div class="input-group">
                <input
                  formControlName="effective_rate"
                  type="text"
                  appFormat
                  formatType="float"
                  [separate]="true"
                  class="form-control"
                  [class.is-invalid]="isFieldInvalid(form, 'effective_rate')"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="col">
              <span class="form-control-plaintext">годовых</span>
            </div>
          </div>
          <ng-container *ngIf="isFieldInvalid(form, 'effective_rate')">
            <div class="help-block text-danger mt-1" *ngIf="form.get('effective_rate').errors?.required">Поле необходимо заполнить</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('effective_rate').errors?.pattern">Допустимы только числа</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('effective_rate').errors?.min">Не может быть 0 или меньше</div>
            <div class="help-block text-danger mt-1" *ngIf="backendErrors['effective_rate']">{{ backendErrors['effective_rate'] }}</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-row align-items-end flex-column flex-md-row">
      <div class="col-6 offset-6">
        <div class="form-group">
          <label>Срок действия оффера</label>
          <p class="text-muted small">Введите дату окончания срока действия Вашего предложения</p>
          <div class="input-group" [class.is-invalid]="isFieldInvalid(form, 'validity')">
            <input
              readonly
              class="form-control"
              placeholder="yyyy-mm-dd"
              formControlName="validity"
              ngbDatepicker
              [minDate]="minDate"
              #d="ngbDatepicker" />
            <div class="input-group-append">
              <button class="btn btn-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="iconCalendar"></fa-icon></button>
            </div>
          </div>
          <div class="invalid-feedback" *ngIf="form.get('validity').errors?.required">Выберите дату</div>
          <div class="invalid-feedback" *ngIf="backendErrors['validity']">{{ backendErrors['validity'] }}</div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Прикрепить файл:</label>
      <p class="text-muted small">Прикрепить .pdf файл, содержащий информацию по Вашему предложению кердита</p>
      <app-file-uploader
        formControlName="payment_schedule_file"
        [single]="true"
        [noSignature]="true"
        [optional]="false"
      ></app-file-uploader>
    </div>
  </form>
</ng-template>
<!-- // CREDIT FORM -->

<!-- GUARANTEE FORM -->
<ng-template #guaranteeFormTemplate>
  <form (submit)="sendForm()" [formGroup]="form">
    <div class="form-row align-items-end flex-column flex-md-row">
      <div class="col">
        <div class="form-group">
          <label>Стоимость гарантии</label>
          <p class="text-muted small">Указывается в процентах, дроби должны вводиться через точку</p>
          <div class="input-group">
            <input
              formControlName="price_in_percent"
              type="text"
              appFormat
              formatType="float"
              [separate]="true"
              class="form-control"
              [class.is-invalid]="isFieldInvalid(form, 'price_in_percent')"
            />
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
          <ng-container *ngIf="isFieldInvalid(form, 'price_in_percent')">
            <div class="help-block text-danger mt-1" *ngIf="form.get('price_in_percent').errors?.required">Поле необходимо заполнить</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('price_in_percent').errors?.min">Не может быть 0 или меньше</div>
            <div class="help-block text-danger mt-1" *ngIf="backendErrors['price_in_percent']">{{ backendErrors['price_in_percent'] }}</div>
          </ng-container>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>
            Статус предложения
          </label>

          <select
            formControlName="bankguarantee_status"
            class="form-control"
            [class.is-invalid]="isFieldInvalid(form, 'bankguarantee_status')"
          >
            <option *ngFor="let status of guaranteeOfferStatusText | keyvalue" [value]="status.key">{{ status.value }}</option>
          </select>

          <ng-container *ngIf="isFieldInvalid(form, 'bankguarantee_status')">
            <div class="help-block text-danger mt-1" *ngIf="form.get('bankguarantee_status').errors?.required">Поле необходимо заполнить</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('bankguarantee_status').errors?.pattern">Допустимы только числа</div>
            <div class="help-block text-danger mt-1" *ngIf="form.get('bankguarantee_status').errors?.min">Не может быть 0 или меньше</div>
            <div class="help-block text-danger mt-1" *ngIf="backendErrors['bankguarantee_status']">{{ backendErrors['bankguarantee_status'] }}</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-row align-items-end flex-column flex-md-row">
      <div class="col-6 offset-6">
        <div class="form-group">
          <label>Срок действия оффера</label>
          <p class="text-muted small">Введите дату окончания срока действия Вашего предложения</p>
          <div class="input-group" [class.is-invalid]="isFieldInvalid(form, 'validity')">
            <input
              readonly
              class="form-control"
              placeholder="yyyy-mm-dd"
              formControlName="validity"
              ngbDatepicker
              [minDate]="minDate"
              #d="ngbDatepicker" />
            <div class="input-group-append">
              <button class="btn btn-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="iconCalendar"></fa-icon></button>
            </div>
          </div>
          <div class="invalid-feedback" *ngIf="form.get('validity').errors?.required">Выберите дату</div>
          <div class="invalid-feedback" *ngIf="backendErrors['validity']">{{ backendErrors['validity'] }}</div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
<!-- // GUARANTEE FORM -->
<!-- // FORM TEMPLATES -->

<!-- VIEW TEMPLATES -->
<ng-template #factoringViewTemplate>
  <div class="row flex-column flex-md-row">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Лимит финансирования</span>
        <span>{{ offer.finance_limit | septhousand:2 }}р.</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Эффективная ставка</span>
        <span>{{ offer.rate }}%</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span>{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Процент финансирования</span>
        <span>{{ offer.finance_percent }}%</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span>{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #leasingViewTemplate>
  <div class="row flex-column flex-md-row" *ngIf="clientRequest.service.type === ClientServiceType.LEASING">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Процент удорожания в год</span>
        <span>{{ offer.rate }}% годовых</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span>{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Эффективная ставка</span>
        <span>{{ offer.effective_rate }} %</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span>{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #creditViewTemplate>
  <div class="row flex-column flex-md-row" *ngIf="clientRequest.service.type === ClientServiceType.CREDIT">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Ставка</span>
        <span>{{ offer.rate }}% годовых</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span>{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Эффективная ставка</span>
        <span>{{ offer.effective_rate }}%</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span>{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #guaranteeViewTemplate>
  <div class="row flex-column flex-md-row" *ngIf="clientRequest.service.type === ClientServiceType.GUARANTEE">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Стоимость гарантии</span>
        <span>{{ offer.price_in_percent }}%</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Статус предложения</span>
        <span>{{ guaranteeOfferStatusText[offer.bankguarantee_status] }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span class="ws-pre-line">{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span class="ws-pre-line">{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>
<!-- // VIEW TEMPLATES -->

<!-- MAIN TEMPLATE -->

<h5 class="mb-4">Заявка:</h5>

<div class="response mb-5">
  <div class="response__header selected">
    <span>{{ clientRequest.company_declarer.name }}</span>
    <span class="float-right">{{ getSum() | septhousand }} р.</span>
    <span class="float-right" *ngIf="offer">{{ offer.status.value }}</span>
  </div>
  <div class="response__body">
    <!-- FORM -->
    <form class="pt-3" (submit)="sendForm()" [formGroup]="form" *ngIf="!offer">
      <ng-container [ngTemplateOutlet]="getFormTemplate()"></ng-container>

      <div class="form-group">
        <label>Дополнительная информация по ставке (коммисии)</label>
        <p class="text-muted small">Дополнительная информация о величине комиссии</p>
        <textarea formControlName="commission_text" class="form-control" rows="5" placeholder="Введите ваш текст"></textarea>
      </div>

      <div class="form-group">
        <label>Дополнительная информация</label>
        <p class="text-muted small">Здесь Вы можете разместить дополнительную информацию по кредиту в свободной форме</p>
        <textarea formControlName="additional_info" class="form-control" rows="5" placeholder="Введите ваш текст"></textarea>
      </div>

      <p class="text-right text-danger" *ngIf="error">{{ error }}</p>

      <div class="text-right">
        <button
          type="submit"
          class="btn btn-primary btn-lg px-5"
          [disabled]="loading || form.invalid"
          ngbPopover="Отправить информацию по Вашему предложению клиенту"
          triggers="mouseenter:mouseleave"
        >
          Отправить предложение
          <fa-icon [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon>
        </button>
      </div>
    </form>
    <!-- // FORM -->

    <!-- VIEW -->
    <div class="row" *ngIf="offer">
      <div class="col pt-4">
        <ng-container *ngTemplateOutlet="getViewTemplate()"></ng-container>

        <!-- DOC -->
        <p class="file-label" *ngIf="offer.payment_schedule_file">
          <app-download-file-link [doc]="offer.payment_schedule_file"></app-download-file-link>
        </p>
        <!-- // DOC -->

        <!-- CONTROLS -->
        <div class="form-row" *ngIf="!offer.isRejected() && !offer.isCancelled() && !offer.factor_done">
          <div class="col-12 mb-2" [ngClass]="{ 'col-xl-4': offer.isAccepted() || offer.client_done }" *ngIf="!offer.client_done">
            <button class="btn btn-primary btn-block" [disabled]="loading" *ngIf="offer.isActive()" (click)="sendCancel()">
              Отозвать <fa-icon [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon>
            </button>
          </div>
          <div class="col" *ngIf="offer.isAccepted() || offer.client_done">
            <button class="btn btn-primary btn-block" [disabled]="loading" (click)="sendComplete()">
              Профинансировано <fa-icon [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon>
            </button>
          </div>
        </div>

        <p class="splash mt-3 bg-danger" *ngIf="offer.isRejected()">ОТКЛОНЕНО</p>
        <p class="splash mt-3 bg-danger" *ngIf="offer.isCancelled()">ОТМЕНЕНО</p>
        <p class="splash mt-3 bg-success" *ngIf="offer.factor_done">ПРОФИНАНСИРОВАНО</p>
        <!-- // CONTROLS -->
      </div>

      <!-- CHAT -->
      <div class="col-12 col-xl-5 pt-4 chat-container">
        <h5 style="color: #6D757E">Чат</h5>
        <app-chat
          class="chat"
          [factorRequest]="clientRequest"
          [factorResponseId]="offer.id"
          [enabled]="offer.isAccepted() && !offer.isDone() && !offer.isRejected() && !offer.client_done && !offer.factor_done"
        ></app-chat>
      </div>
      <!-- // CHAT -->
    </div>
    <!-- // VIEW -->
  </div>
</div>

<!-- // MAIN TEMPLATE -->
