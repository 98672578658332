import { Component, OnDestroy } from '@angular/core';
import { Breadcrumb } from '../shared/components/breadcrumbs/breadcrumb.interface';
import { AuthService } from '../services/auth.service';
import { faPlusCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManualModalComponent } from '../modals/manual-modal/manual-modal.component';
import { BASE_TITLE, serviceTypeNames, serviceTypeRoutes } from '../constants';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageRequestCreateComponent } from './page-request-create/page-request-create.component';
import { PageRequestsComponent } from './page-requests/page-requests.component';

const DEFAULT_TITLE  = 'Личный кабинет';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnDestroy {
  title = DEFAULT_TITLE;

  serviceRoute: string = null;
  questionIcon = faQuestionCircle;
  isUser: boolean;

  constructor(
    private _auth: AuthService,
    private _modal: NgbModal,
    private _title: Title,
    private _router: Router
  ) {
    this.isUser = !_auth.authenticatedUser().isFactor();
  }

  ngOnDestroy(): void {
  }

  openManual() {
    this._modal.open(ManualModalComponent, { windowClass: 'modal-manual-container' });
  }

  onActivate($event) {
    if ($event instanceof PageRequestsComponent && $event.route.snapshot.data.resource.serviceType) {
      this.serviceRoute = serviceTypeRoutes[$event.route.snapshot.data.resource.serviceType];
    } else {
      this.serviceRoute = null;
    }

    if ($event.title) {
      this.title = $event.title;
    } else {
      this.title = DEFAULT_TITLE;
    }

    let title = `${BASE_TITLE} / ${this.title}`;

    if ($event.title) {
      title += ` / ${$event.title}`;
    }

    this._title.setTitle(title);
  }

  showCreate() {
    return this._auth.authenticatedUser().acl.can_start_ua && this.serviceRoute;
  }
}
