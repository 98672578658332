<div class="modal-header">
  <h2 class="modal-title text-left">Восстановить пароль</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="alert alert-info" *ngIf="message">{{ message }}</div>

  <form class="mb-1" [formGroup]="form" (submit)="submit()">
    <div class="form-group mb-3">
      <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input
          matInput
          required
          name="email"
          formControlName="email"
          type="email"
        />
        <mat-error *ngIf="form.get('email').touched && form.get('email').errors?.required">Необходимо заполнить</mat-error>
        <mat-error *ngIf="form.get('email').touched && form.get('email').errors?.email">Некорректный email</mat-error>
      </mat-form-field>
    </div>

    <p class="help-block text-danger text-center">{{ error }}</p>
    <div class="text-center mt-4">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="form.pristine || form.invalid || loading">Восстановить пароль <fa-icon *ngIf="loading" [icon]="faCircleNotch" [spin]="true"></fa-icon></button>
    </div>
  </form>
  <div class="text-center mt-5">
    <a href="javascript:void(0)" class="forgot-password" (click)="signInClick()">Вернуться к форме входа</a>
  </div>
</div>
