<form [formGroup]="filterForm" (submit)="submit()">
  <div class="form-group">
    <label class="mb-2">Услуга</label>
    <select formControlName="service" class="form-control">
      <option [ngValue]="null">Не выбрано</option>
      <option *ngFor="let service of services$ | async" [ngValue]="service.id">{{ service.name }}</option>
    </select>
  </div>

  <div class="form-group">
    <label class="mb-2">Сумма контракта, ₽</label>
    <div class="form-row">
      <div class="col-6">
        <input
          type="text"
          class="form-control"
          formControlName="contract_sum_min"
          appFormat
          [separate]="true"
          [fitFontSize]="true"
          [min]="0"
          [max]="MAX_CONTRACT_SUM"
        />
        <div class="help-block text-muted mt-1">Минимум</div>
      </div>
      <div class="col-6">
        <input
          type="text"
          class="form-control"
          formControlName="contract_sum_max"
          appFormat
          [separate]="true"
          [fitFontSize]="true"
          [min]="0"
          [max]="MAX_CONTRACT_SUM"
        />
        <div class="help-block text-muted mt-1">Максимум</div>
      </div>
    </div>
    <ngx-slider
      [value]="filterForm.get('contract_sum_min').value"
      (valueChange)="filterForm.get('contract_sum_min').setValue($event)"
      [highValue]="filterForm.get('contract_sum_max').value"
      (highValueChange)="filterForm.get('contract_sum_max').setValue($event)"
      [options]="sumRangeOptions">
    </ngx-slider>
  </div>

<!--  <div class="form-group">-->
<!--    <label class="mb-2">Отсрочка, дней</label>-->
<!--    <div class="form-row">-->
<!--      <div class="col-6">-->
<!--        <input-->
<!--          type="text"-->
<!--          class="form-control"-->
<!--          formControlName="term_sum_min"-->
<!--          appFormat-->
<!--          [separate]="true"-->
<!--          [min]="1"-->
<!--          [max]="daysIn3Years"-->
<!--        />-->
<!--        <div class="help-block text-muted mt-1">От</div>-->
<!--      </div>-->
<!--      <div class="col-6">-->
<!--        <input-->
<!--          type="text"-->
<!--          class="form-control"-->
<!--          formControlName="term_sum_max"-->
<!--          appFormat-->
<!--          [separate]="true"-->
<!--          [min]="1"-->
<!--          [max]="daysIn3Years"-->
<!--        />-->
<!--        <div class="help-block text-muted mt-1">До</div>-->
<!--      </div>-->
<!--      <ngx-slider-->
<!--        [value]="filterForm.get('termFrom').value"-->
<!--        (valueChange)="filterForm.get('termFrom').setValue($event)"-->
<!--        [highValue]="filterForm.get('termTo').value"-->
<!--        (highValueChange)="filterForm.get('termTo').setValue($event)"-->
<!--        [options]="termRangeOptions">-->
<!--      </ngx-slider>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="form-group">-->
<!--    <label class="mb-2">ИНН заявителя</label>-->
<!--    <input type="text" class="form-control" />-->
<!--  </div>-->

  <div class="form-row">
    <div class="col-6">
      <button type="submit" class="btn btn-primary btn-block">Применить</button>
    </div>
    <div class="col-6">
      <button type="button" class="btn btn-outline-primary btn-block" (click)="reset()">Сбросить</button>
    </div>
  </div>
</form>
