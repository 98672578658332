<form [formGroup]="form">
  <div class="form-group mb-5">
    <label>Сумма контракта под обеспечение, ₽</label>
    <p class="text-muted small">Введите сумму, указанную в контракте</p>
    <input
      formControlName="contract_sum"
      type="text"
      class="form-control w-auto"
      appFormat
      formatType="float"
      [separate]="true"
      (input)="sumSlider.value = form.get('contract_sum').value"
      [class.is-invalid]="form.get('contract_sum').touched && form.get('contract_sum').invalid"
    />
    <div class="invalid-feedback" *ngIf="form.get('contract_sum').errors?.required">Введите корректную сумму</div>
    <div class="invalid-feedback" *ngIf="form.get('contract_sum').errors?.min">Не может быть меньше 100 тыс.</div>
    <div class="invalid-feedback" *ngIf="form.get('contract_sum').errors?.max">Не может быть больше 10 млрд</div>

    <div class="row mt-3">
      <div class="col-12 col-md-10 col-lg-8 col-xl-6">
        <input type="range" class="custom-range" min="0" max="100000000" #sumSlider (input)="form.get('contract_sum').setValue(sumSlider.value)" />
      </div>
    </div>
  </div>

  <div class="form-group mb-5">
    <label>Оставшийся срок контракта под обеспечение, дней</label>
    <p class="text-muted small">Введите количество дней, оставшихся по сроку исполнения контракта</p>

    <div class="row">
      <app-changers-input
        formControlName="contract_period"
        class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2"
        [separate]="true"
        min="1"
        [max]="daysLimit"
        [invalid]="form.get('contract_period').touched && form.get('contract_period').invalid"
      ></app-changers-input>
    </div>

    <ng-container *ngIf="form.get('contract_period').touched">
      <div class="help-block text-danger mt-1" *ngIf="form.get('contract_period').errors?.required">Введите количество дней</div>
      <div class="help-block text-danger mt-1" *ngIf="form.get('contract_period').errors?.min">Не может быть меньше 1</div>
      <div class="help-block text-danger mt-1" *ngIf="form.get('contract_period').errors?.max">Не может быть больше 3 лет</div>
    </ng-container>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
      <div class="form-group">
        <label>ИНН заявителя</label>
        <p class="text-muted small">Укажите ваш ИНН</p>
        <div class="separated-input-group">
          <input formControlName="inn" class="form-control" type="text" [class.is-invalid]="form.get('inn').touched && (form.get('inn').invalid || inn1Error)" [readOnly]="lockInn" />
          <button class="btn btn-primary btn-hollow btn-lg" (click)="loadInn1()" [disabled]="loading || enrichOngoing || !validScoring1()">Скоринг</button>
        </div>
        <div class="text-danger" *ngIf="form.get('inn').touched || inn1Error">
          <div class="help-block mt-1" *ngIf="form.get('inn').invalid && form.get('inn').errors.required">Введите ИНН</div>
          <div class="help-block mt-1" *ngIf="form.get('inn').invalid && form.get('inn').errors.pattern">Недопустимый ИНН</div>
          <div class="help-block mt-1" *ngIf="inn1Error">{{ inn1Error }}</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
      <div class="form-group" *ngIf="!service.hide_second_inn">
        <label>ИНН второй стороны</label>
        <p class="text-muted small">Укажите ИНН поставщика / контрагента</p>

        <div class="separated-input-group">
          <input formControlName="inn_second" class="form-control" type="text" [class.is-invalid]="form.get('inn_second').touched && (form.get('inn_second').invalid || inn2Error)" />
          <button class="btn btn-primary btn-hollow btn-lg" (click)="loadInn2()" [disabled]="loading || enrichOngoing || !validScoring2()">Скоринг</button>
        </div>
        <div class="text-danger" *ngIf="form.get('inn_second').touched || inn2Error">
          <div class="help-block mt-1" *ngIf="form.get('inn_second').invalid && form.get('inn_second').errors.required">Введите ИНН</div>
          <div class="help-block mt-1" *ngIf="form.get('inn_second').invalid && form.get('inn_second').errors.pattern">Недопустимый ИНН</div>
          <div class="help-block mt-1" *ngIf="inn2Error">{{ inn2Error }}</div>
        </div>
      </div>
    </div>
  </div>
</form>
