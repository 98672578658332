import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ModalsModule } from '../modals/modals.module';
import { LandingComponent } from './landing.component';
import { PageLandingIndexComponent } from './page-landing-index/page-landing-index.component';
import { LandingRoutingModule } from './landing-routing.module';
import { PageLandingRequestComponent } from './page-landing-request/page-landing-request.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPopoverModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [LandingComponent, PageLandingIndexComponent, PageLandingRequestComponent],
  imports: [
    CommonModule,
    SharedModule,
    ModalsModule,
    LandingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbPopoverModule,
    NgbProgressbarModule
  ]
})
export class LandingModule { }
