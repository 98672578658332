import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { faCircleNotch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { UserLegalDetail } from '../../user/user-legal-detail';
import { User } from '../../user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneConfirmModalComponent } from '../../modals/phone-confirm-modal/phone-confirm-modal.component';
@Component({
  selector: 'app-page-profile',
  templateUrl: './page-profile.component.html',
  styleUrls: ['./page-profile.component.scss']
})
export class PageProfileComponent {

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _http: HttpClient,
    private _modals: NgbModal
  ) {
    this.updateUserForm();

    _auth.onUpdate().subscribe(() => {
      this.updateUserForm();
    });
  }
  title = 'Профиль';

  form: FormGroup;
  loading = false;
  message = '';
  error = '';
  backendErrors: {[k: string]: string} = {};
  loadingIcon = faCircleNotch;
  detail: UserLegalDetail;
  user: User;
  iconExclamation = faExclamationTriangle;

  private static checkPasswords(group: FormGroup) {
    const errors: {[k: string]: boolean} = {};

    if (group.get('new_password').value && !group.get('password').value) {
      errors.oldPasswordNeed = true;
    }

    if (group.get('new_password').value !== group.get('new_password_repeat').value) {
      errors.passwordsNotSame = true;
    }

    return errors;
  }

  submit() {
    if (this.form.invalid) return;

    this.message = '';
    this.error = '';

    if (this.form.pristine) {
      this.error = 'Не сделано никаких изменений';
    }

    this.loading = true;
    this.backendErrors = {};

    const data = this.form.value;

    if (data.new_password !== undefined && !data.new_password) {
      delete data.new_password;
      delete data.password;
      delete data.new_password_repeat;
    }

    this._http.patch<{[k: string]: string}>(environment.apiEndpoint + `/users/${this._auth.authenticatedUser().id}`, this.form.value)
      .subscribe(
        result => {
          this.loading = false;
          this.message = result.detail;
        },
        response => {
          this.loading = false;
          this.error = 'Возникла ошибка. Проверьте все поля формы и попробуйте ещё раз.';
          console.log(this.backendErrors = response.error);
        }
      );

    return false;
  }

  changePhone() {
    this._modals.open(PhoneConfirmModalComponent, { size: 'lg' });
  }

  private updateUserForm() {
    const user = this.user = this._auth.authenticatedUser();
    this.form = this._fb.group({
      first_name: [user.first_name, Validators.required],
      second_name: [user.second_name],
      last_name: [user.last_name, Validators.required],
      username: [user.username, Validators.required],
      email: [user.email, [Validators.email, Validators.required]],
      mobile_phone: [user.mobile_phone || ''],
      password: [''],
      new_password: [''],
      new_password_repeat: [''],
      notifications: [true]
    }, { validators: PageProfileComponent.checkPasswords });

    this.detail = user.legal_detail;
  }
}
