import { Component, Input } from '@angular/core';
import { IDoc } from '../../../services/files';
import { faFile } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-download-file-link',
  templateUrl: './download-file-link.component.html',
  styleUrls: ['./download-file-link.component.scss']
})
export class DownloadFileLinkComponent {
  @Input() doc: IDoc;
  fileIcon = faFile;
}
