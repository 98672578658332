import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { IDoc, IDocType, IDocTypeDetailed, IFileUploadEvent } from './interfaces';
import { FileUploadEventBuilder } from './file-upload-event-builder';
import { IDataPage } from '../../types';

type DocTypeId = IDocType['id'] | IDocTypeDetailed['id'];

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  constructor(private readonly _http: HttpClient) { }

  findAll(): Observable<IDoc[]> {
    return this._http.get<IDoc[]>(environment.apiEndpoint + '/docs');
  }

  findByType(type: DocTypeId, page = 1, pageSize = 10): Observable<IDataPage<IDoc>> {
    const params = {
      page: page.toString(),
      page_size: pageSize.toString()
    };

    return this._http.get<IDoc[]>(environment.apiEndpoint + '/docs/type/' + type, { params, observe: 'response' }).pipe(
      map(response => ({
        data: response.body,
        page: parseInt(response.headers.get('x-page-current'), 10),
        count: parseInt(response.headers.get('x-page-paginator-count'), 10)
      } as IDataPage<IDoc>)
    ));
  }

  findTypes(): Observable<IDocTypeDetailed[]> {
    return this._http.get<IDocTypeDetailed[]>(environment.apiEndpoint + '/doctypes');
  }

  public upload(file: File, type: DocTypeId = null): Observable<IFileUploadEvent> {
    const eventBuilder = new FileUploadEventBuilder(type, file.name);
    const data = new FormData();
    data.append('doc', file);

    if (type) {
      data.append('doc_type', type.toString());
    }

    return this._http.post<IDoc>(environment.apiEndpoint + '/docs', data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      filter(event => {
        return event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response;
      }),
      map<HttpEvent<IDoc>, IFileUploadEvent>(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return eventBuilder.buildProgress(progress);

          case HttpEventType.Response:
            return eventBuilder.buildComplete(event.body);
        }
      })
    );
  }
}
