<ng-template #btnAuthenticated>
  <div ngbDropdown>
    <button class="btn btn-dark user-btn" type="button" id="dropdownMenuButton" ngbDropdownToggle>
      <img src="/assets/svg/redesign/user.svg" height="17" class="mr-2 user-icon" />{{ getName() }}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
      <a ngbDropdownItem routerLink="/account/factoring" routerLinkActive="active" class="d-lg-none">Факторинг</a>
      <a ngbDropdownItem routerLink="/account/leasing" routerLinkActive="active" class="d-lg-none">Лизинг</a>
      <a ngbDropdownItem routerLink="/account/credit" routerLinkActive="active" class="d-lg-none">Кредит</a>
      <a ngbDropdownItem routerLink="/account/bank-guarantee" routerLinkActive="active" class="d-lg-none">Банковская гарантия</a>
      <a ngbDropdownItem routerLink="/account/documents" routerLinkActive="active" *ngIf="user.acl.can_start_ua">Документы</a>
      <a ngbDropdownItem routerLink="/account" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Профиль</a>
      <a href="javascript:void(0)" (click)="logout()" ngbDropdownItem>Выйти <img class="float-right mt-1" src="/assets/svg/redesign/logout.svg"></a>
    </div>
  </div>
</ng-template>

<ng-template #btnGuest>
  <a routerLink="/account/factoring" target="_self" class="btn btn-dark">Войти</a>
</ng-template>

<div class="container">
  <header>
    <div class="header-bg" [class.shadow]="shadowed" [class]="bgClass"></div>
    <div class="header-inset">
      <app-logo class="mx-3 mr-md-5"></app-logo>

      <ng-container *ngIf="user && user.id">
        <a routerLinkActive="active" routerLink="/account/factoring" class="header-button d-none d-lg-flex">Факторинг</a>
        <a routerLinkActive="active" routerLink="/account/leasing" class="header-button d-none d-lg-flex">Лизинг</a>
        <a routerLinkActive="active" routerLink="/account/credit" class="header-button d-none d-lg-flex">Кредит</a>
        <a routerLinkActive="active" routerLink="/account/bank-guarantee" class="header-button d-none d-lg-flex">Банковская гарантия</a>
      </ng-container>

      <div class="ml-auto">
        <ng-container *ngIf="user && user.id; then btnAuthenticated else btnGuest"></ng-container>
      </div>
    </div>
    <div class="header-menu">
      <div class="row" style="height: 100%">
        <div class="col d-flex flex-column align-items-start">
          <p class="large font-weight-bold"><a [routerLink]="digitalServicesLink" class="text-decoration-none">Услуги</a></p>
          <p class="large font-weight-bold"><a [routerLink]="additionalServicesLink" class="text-decoration-none">Доп. услуги</a></p>
          <p class="large font-weight-bold"><a routerLink="/about" class="text-decoration-none">О нас</a></p>
          <p class="large font-weight-bold"><a routerLink="/partners" class="text-decoration-none">Партнёры</a></p>
          <p class="large font-weight-bold"><a routerLink="/faq" class="text-decoration-none">Вопросы и ответы</a></p>
          <p class="large font-weight-bold"><a routerLink="/contacts" class="text-decoration-none">Контакты</a></p>

          <p class="large">
            <a [href]="'tel:' + supportPhone" class="text-decoration-none">{{ supportPhoneFormat }}</a>
          </p>
          <p class="large"><a [href]="'mailto:' + supportEmail" class="text-decoration-none">{{ supportEmail }}</a></p>

          <button type="button" class="btn btn-success consult-button" appOpenConsult>Получить консультацию</button>
          <button *ngIf="user && user.id" type="button" (click)="logout()" class="d-md-none btn btn-block mt-2 btn-dark">Выйти</button>
        </div>
        <div class="col d-none d-md-flex flex-column align-items-start">
          <ng-container *ngIf="articles$ | async as articles">
            <p class="large" *ngFor="let article of articles">
              <a [routerLink]="[digitalServicesLink, article.url]" class="text-decoration-none">{{ article.name }}</a>
            </p>
          </ng-container>

          <p class="small mt-auto mb-5">
            © 2020 Все права защищены и охраняются законом. Использование материалов сайта
            разрешено только с письменного разрешения ООО «МайФи». Данный интернет-сайт
            носит исключительно информационный характер и не является публичной офертой.
          </p>
          <a [href]="personalDataAgreementFile">Политика обработки персональных данных</a>
        </div>
      </div>
    </div>
  </header>
</div>
