import { UserRank } from './user-rank.interface';
import { UserAcl } from './user-acl.interface';
import { UserApiUrls } from './user-api-urls.interface';
import { UserAvatar } from './user-avatar.interface';
import { UserLegalDetail } from './user-legal-detail';

export class User {
  id: number;
  username: string;
  slug: string;
  email: string;
  mobile_phone: string;
  joined_on: string;
  rank: UserRank;
  title: string | null;
  avatars: UserAvatar[];
  acl: UserAcl;
  legal_detail: UserLegalDetail;
  last_name: string | null;
  first_name: string | null;
  second_name: string | null;
  api: UserApiUrls;
  url: string;
  has_usable_password: boolean;
  is_hiding_presence: boolean;
  is_authenticated: boolean;
  is_anonymous: boolean;

  static from(obj) {
    const user = new this();
    Object.assign(user, obj);

    return user;
  }

  availableAvatarSizes(): number[] {
    return this.avatars.map(value => value.size);
  }

  getAvatar(size: number): string {
    const avatar = this.avatars.find(value => value.size === size);
    return avatar ? avatar.url : null;
  }

  isUser(): boolean {
    return this.rank.slug === 'clients';
  }

  isAgent(): boolean {
    return this.rank.slug === 'agent';
  }

  isFactor(): boolean {
    return this.rank.slug === 'factor';
  }
}
