import { ChangeDetectorRef, Component } from '@angular/core';
import { CalcResult, CalcRule, ClientServiceType, IClientService } from '../../../types';
import { ServicesTypesService } from '../../../services/services-types.service';
import { calcRules } from './calc-rules';
import { SepthousandPipe } from '../../pipes/septhousand.pipe';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent {
  calcFields: {[field: string]: any} = {};
  calcResults: {[result: string]: any} = {};

  selectedService: IClientService = null;
  rule: CalcRule = null;

  services: IClientService[] = [];
  servicesLoading = true;
  isCalcDisabled = true;

  static findRule(service): CalcRule {
    let rule;

    for (rule of calcRules) {
      try {
        if (rule.cond(service)) {
          return rule;
        }
      } catch (e) {}
    }

    return rule;
  }

  constructor(
    public auth: AuthService,
    private _servicesTypes: ServicesTypesService,
    private _changeDetector: ChangeDetectorRef,
    private _sepThousandPipe: SepthousandPipe
  ) {
    this.loadServices();
    this.serviceChanged();
  }

  calc() {
    for (const resultRule of this.rule.results) {
      let result;

      try {
        for (const name in this.calcFields) {
          if (!this.calcFields.hasOwnProperty(name)) {
            continue;
          }

          const value = this.calcFields[name];
          const field = this.rule.fields.find(f => f.name === name);

          if (['', undefined, null].includes(value)) {
            throw new Error('No calc field input');
          }

          if (field.min && value < field.min) {
            throw new Error('Value out of bound');
          }

          if (field.max && value > field.max) {
            throw new Error('Value out of bound');
          }
        }

        result = resultRule.result(this.calcFields, this.calcResults);

        if (isNaN(result) || !Number.isSafeInteger(result)) {
          result = 0;
        }
      } catch (e) {
        result = 0;
      }

      this.calcResults[resultRule.name] = result;
    }
  }

  setRule(rule: CalcRule) {
    this.rule = rule;
    this.calcFields = {};
    this.calcResults = {};

    for (const field of rule.fields) {
      if (field.min && field.max) {
        this.calcFields[field.name] = field.min + Math.round((field.max - field.min) * 0.1);
      } else {
        this.calcFields[field.name] = 0;
      }
    }

    for (const result of rule.results) {
      this.calcResults[result.name] = 0;
    }

    this.calc();
  }

  loadServices() {
    this.servicesLoading = true;
    this._servicesTypes.loadAll()
      .subscribe(services => {
        this.services = services.filter(s => s.type !== ClientServiceType.GUARANTEE);
        this.servicesLoading = false;
        this.selectedService = services.find(s => s.name.includes('с регрессом')) || null;

        this.serviceChanged();
      });
  }

  serviceChanged() {
    this.setRule(CalculatorComponent.findRule(this.selectedService));
    this.isCalcDisabled = !this.selectedService;
    this._changeDetector.markForCheck();
  }

  sumFontSize(result: CalcResult): number {
    const text = (result.prefix || '')
      + (this._sepThousandPipe.transform(this.calcResults[result.name]))
      + ' ' + result.unit;

    return 36 - (text.length - 15) * 1.5;
  }
}
