import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { Article, ArticleType } from '../types';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  private _cachePages: Map<ArticleType, Observable<Article[]>> = new Map<ArticleType, Observable<Article[]>>();
  private _cachePagesAll: Map<ArticleType, Observable<Article[]>> = new Map<ArticleType, Observable<Article[]>>();

  private _cacheSlug: Map<string, Observable<Article>> = new Map<string, Observable<Article>>();

  constructor(private _http: HttpClient) { }

  tree(type: ArticleType): Observable<Article[]> {
    const request = this._http.get<Article[]>(`${environment.apiEndpoint}/pages?type=${type}`);

    if (!this._cachePages.has(type)) {
      this._cachePages.set(type, request);
    }

    return this._cachePages.get(type);
  }

  treeAll(type: ArticleType): Observable<Article[]> {
    const params = new URLSearchParams();
    params.append('all', 'true');
    params.append('type', type);

    const request = this._http.get<Article[]>(`${environment.apiEndpoint}/pages?${params}`);

    if (!this._cachePagesAll.has(type)) {
      this._cachePagesAll.set(type, request);
    }

    return this._cachePagesAll.get(type);
  }

  getBySlug(slug: string): Observable<Article> {
    if (!this._cacheSlug.has(slug)) {
      this._cacheSlug.set(slug, this._http.get<Article>(environment.apiEndpoint + '/pages/' + slug));
    }

    return this._cacheSlug.get(slug).pipe(
      catchError(err => {
        console.log('Page slug search error:', err);
        return throwError(err);
      }));
  }
}
