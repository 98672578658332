<ng-template #factoringView let-offer>
  <div class="row flex-column flex-md-row">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Лимит финансирования</span>
        <span>{{ offer.finance_limit | septhousand:2 }}р.</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Эффективная ставка</span>
        <span>{{ offer.rate }}%</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span>{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Процент финансирования</span>
        <span>{{ offer.finance_percent }}%</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span>{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #leasingView let-offer>
  <div class="row">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Процент удорожания в год</span>
        <span>{{ offer.rate }}% годовых</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span>{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Эффективная ставка</span>
        <span>{{ offer.effective_rate }} %</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span>{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #creditView let-offer>
  <div class="row">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Ставка</span>
        <span>{{ offer.rate }}% годовых</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span>{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Эффективная ставка</span>
        <span>{{ offer.effective_rate }} %</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span>{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #guaranteeView let-offer>
  <div class="row" *ngIf="clientRequest.service.type === ClientServiceType.GUARANTEE">
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Стоимость гарантии</span>
        <span>{{ offer.price_in_percent }}%</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Статус предложения</span>
        <span>{{ guaranteeOfferStatusText[offer.bankguarantee_status] }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация по ставке (коммисии)</span>
        <span>{{ offer.commission_text || 'Нет' }}</span>
      </p>
    </div>
    <div class="col">
      <p>
        <span class="d-block mb-1 text-muted">Срок действия оффера</span>
        <span>до {{ offer.validity | date:'dd.MM.yyyy' }}</span>
      </p>
      <p>
        <span class="d-block mb-1 text-muted">Доп. информация</span>
        <span>{{ offer.additional_info || 'Нет' }}</span>
      </p>
    </div>
  </div>
</ng-template>

<h5 class="mb-4 d-none d-lg-block">Предложения на заявки:</h5>

<div class="alert alert-primary" *ngIf="!offers || offers.length === 0">
  <div class="d-flex">
    <div>
      <fa-icon [icon]="iconLightbulb" class="mr-3"></fa-icon>
    </div>
    <div>
      <p class="h4">Здесь появятся предложения по вашей заявке</p>
      <span class="small">Так же вы получите уведомление на email</span>
    </div>
  </div>
</div>

<div class="col-grid heading d-none d-md-flex">
<!--  <div class="first">Компания</div>-->
  <div class="col1" *ngIf="!isGuarantee">Предложение</div>
  <div class="col2">Ставка за услуги</div>
  <div class="col3">Дата</div>
  <div class="col4">Статус</div>
  <div class="col5"></div>
</div>

<div class="offers" *ngIf="offers.length">
  <ng-container *ngFor="let offer of offers; let i = index">
    <div class="offer" [class.active]="selectedOfferId == offer.id">
      <div class="col-grid offer-heading" [ngClass]="'offer-heading--' + offer.getStyle()" (click)="select(offer.id)">
        <div class="col1 font-weight-bold" *ngIf="!isGuarantee"><span>{{ offer.getOfferValue() | septhousand:2 }}{{ offer.valueUnit }}</span></div>
        <div class="col2">{{ offer.getRate() | septhousand:2 }}%</div>
        <div class="col3">{{ offer.created_at | date:'d MMMM, hh:mm':undefined:'ru-RU' }}</div>
        <div class="col4" [ngClass]="'text-' + offer.getStyle()">{{ offer.status.label }}</div>
        <div class="col5 chevron"><fa-icon class="ml-2" [icon]="selectedOfferId == offer.id ? iconUp : iconDown"></fa-icon></div>
      </div>
      <div class="offer-body" [ngClass]="{'d-none': selectedOfferId != offer.id}">
        <div class="row">
          <div class="col pt-4">
            <ng-container *ngIf="view" [ngTemplateOutlet]="view" [ngTemplateOutletContext]="{$implicit: offer}"></ng-container>

            <!-- DOC -->
            <p class="file-label" *ngIf="offer.payment_schedule_file">
              <app-download-file-link [doc]="offer.payment_schedule_file"></app-download-file-link>
            </p>
            <!-- //DOC -->

            <!-- CONTROLS -->
            <div class="form-row" *ngIf="isShowControls(offer)">
              <div class="col-12 mb-2 col-xl-4" *ngIf="!offer.isDone() && !offer.factor_done">
                <button class="btn btn-outline-primary btn-block" [disabled]="loading" (click)="cancel(offer)">
                  Отклонить <fa-icon [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon>
                </button>
              </div>
              <div class="col">
                <button class="btn btn-primary btn-block" [disabled]="loading" *ngIf="offer.isPristine()" (click)="accept(offer)">
                  Принять предложение <fa-icon [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon>
                </button>
                <button class="btn btn-primary btn-block" [disabled]="loading" *ngIf="offer.canDone()" (click)="done(offer)">
                  Профинансировано <fa-icon [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon>
                </button>
              </div>
            </div>

            <p class="splash mt-3 bg-danger" *ngIf="offer.isRejected()">ОТКЛОНЕНО</p>
            <p class="splash mt-3 bg-danger" *ngIf="offer.isCancelled()">ОТОЗВАНО</p>
            <p class="splash mt-3 bg-success" *ngIf="offer.client_done">ПРОФИНАНСИРОВАНО</p>
            <!-- //CONTROLS -->
          </div>

          <div class="col-12 col-xl-5 pt-4 chat-container">
            <h5 style="color: #6D757E">Чат</h5>
            <app-chat
              class="chat"
              [factorRequest]="clientRequest"
              [factorResponseId]="offer.id"
              [enabled]="offer.isAccepted() && !offer.isDone() && !offer.isRejected() && !clientRequest.archived && !offer.client_done && !offer.factor_done"
            ></app-chat>
          </div>
        </div>
      </div>
    </div>
    <tr class="d-none"></tr>
  </ng-container>
</div>
