import { Component } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FilesService } from '../../services/files';

@Component({
  selector: 'app-page-documents',
  templateUrl: './page-documents.component.html',
  styleUrls: ['./page-documents.component.scss']
})
export class PageDocumentsComponent {
  readonly iconCircleNotch = faCircleNotch;
  readonly title = 'Документы';
  readonly types$ = this._files.findTypes();

  private readonly _expanded: Record<number, boolean> = {};

  constructor(private readonly _files: FilesService) { }

  toggle(id: number): boolean {
    return this.expanded(id, !this.expanded(id));
  }

  expanded(id: number, value?: boolean): boolean {
    if (value !== undefined) {
      this._expanded[id] = value;
    }

    return this._expanded[id];
  }
}
