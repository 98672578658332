import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageRequestsComponent } from './page-requests.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { RequestsCardsComponent } from './requests-cards/requests-cards.component';
import { RequestsFilterComponent } from './requests-filter/requests-filter.component';
import { RequestCardComponent } from './request-card/request-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [
    PageRequestsComponent,
    RequestsCardsComponent,
    RequestsFilterComponent,
    RequestCardComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    NgxSliderModule
  ]
})
export class PageRequestsModule { }
