<div class="container pb-5">
  <div #tabsBlock class="line-tabs line-tabs--small mb-4 font-weight-bold">
    <div class="tab">
      <span class="nav-link" [class.active]="step === 1">Шаг 1</span>
    </div>
    <div class="tab">
      <span class="nav-link" [class.active]="step === 2">Шаг 2</span>
    </div>
    <div class="tab">
      <span class="nav-link" [class.active]="step === 3">Шаг 3</span>
    </div>
  </div>

  <form [formGroup]="form">
    <h2 class="my-4">{{ step < 3 ? 'Услуга' : 'Загрузите документы' }}</h2>

    <div class="row">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="form-group mb-5">
          <p *ngIf="servicesLoading"><fa-icon [icon]="iconLoading" [spin]="true"></fa-icon></p>
          <select formControlName="service" class="form-control no-disable" [attr.disabled]="selectedService ? '' : null" *ngIf="!servicesLoading">
            <option value="">Выберите</option>
            <option *ngFor="let service of services" [value]="service.id">{{ service.name }}</option>
          </select>
          <p class="help-block text-danger mt-1" *ngIf="form.get('service').touched && form.get('service').invalid && form.get('service').errors.required">Необходимо выбрать</p>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="selectedService && !selectedServiceLoading && step === 2"
      [ngSwitch]="selectedService.type"
    >
      <app-factoring
        *ngSwitchCase="ClientServiceType.FACTORING"
        (loadInn1Clicked)="loadInn($event, 0)"
        (loadInn2Clicked)="loadInn($event, 1)"
        (submitted)="submit()"
        [enrichOngoing]="enrichOngoing"
        [service]="selectedService"
        [inn1Error]="scoring[0].error"
        [inn2Error]="scoring[1].error"
        [loading]="loading"
        [data]="clientRequest"
        #subComponent
      ></app-factoring>
      <app-leasing
        *ngSwitchCase="ClientServiceType.LEASING"
        (loadInn1Clicked)="loadInn($event, 0)"
        (loadInn2Clicked)="loadInn($event, 1)"
        (submitted)="submit()"
        [enrichOngoing]="enrichOngoing"
        [service]="selectedService"
        [inn1Error]="scoring[0].error"
        [inn2Error]="scoring[1].error"
        [loading]="loading"
        [data]="clientRequest"
        #subComponent
      ></app-leasing>
      <app-credit
        *ngSwitchCase="ClientServiceType.CREDIT"
        (loadInn1Clicked)="loadInn($event, 0)"
        (submitted)="submit()"
        [enrichOngoing]="enrichOngoing"
        [service]="selectedService"
        [inn1Error]="scoring[0].error"
        [loading]="loading"
        [data]="clientRequest"
        #subComponent
      ></app-credit>
      <app-guarantee
        *ngSwitchCase="ClientServiceType.GUARANTEE"
        (loadInn1Clicked)="loadInn($event, 0)"
        (submitted)="submit()"
        [enrichOngoing]="enrichOngoing"
        [service]="selectedService"
        [inn1Error]="scoring[0].error"
        [loading]="loading"
        [data]="clientRequest"
        #subComponent
      ></app-guarantee>
    </ng-container>

    <div class="row">
      <div class="col-12 col-lg-10 col-xl-8" *ngIf="step === 3">
        <div class="file-container" *ngFor="let file of selectedService.doc_types; index as i" [formGroup]="form">
          <ng-container formArrayName="docs">
            <app-file-uploader
              class="mb-5"
              [formControlName]="i"
              [optional]="!file.required"
              [docType]="file"
            ></app-file-uploader>
          </ng-container>
          <p class="text-danger help-block mt-0" *ngIf="form.get('docs').errors?.required">Необходимо загрузить файл</p>
        </div>
      </div>
    </div>

    <div class="notification notification--danger my-5" [class.d-none]="!creditLeanFailed" #loanFalse>
      <img src="/assets/svg/redesign/exclamation.svg" alt="notice" />
      <div>
        <h4 class="mb-0">К сожалению, вам отказано в кредите.</h4>
        <p class="mb-4">Хотите подать заявку на обычный кредит?</p>
        <button type="button" class="btn btn-success mb-4" (click)="submit()">Да, хочу обычный кредит</button>
        <div><a class="text-muted" routerLink="/account/factoring">Отменить заявку</a></div>
      </div>
    </div>

    <div class="d-flex align-items-center" *ngIf="!enrichOngoing && !loading">
      <div [ngbTooltip]="tooltipText()" placement="right" tooltipClass="tooltip-danger" [disableTooltip]="!nextStepDisabled()">
        <button
          class="btn btn-lg btn-success"
          (click)="nextStep()"
          [disabled]="selectedServiceLoading || servicesLoading || nextStepDisabled()"
        >
          {{ step < 3 ? 'Далее' : 'Подать заявку' }}
          <fa-icon *ngIf="loading" [spin]="true" [icon]="iconLoading"></fa-icon>
        </button>
      </div>

      <div class="ml-5" *ngIf="clientRequest?.draft">
        <a href="javascript:void(0)" class="text-danger" (click)="deleteDraft()">Удалить</a>
      </div>
    </div>

    <p *ngIf="error" class="help-block text-danger mb-2">{{ error }}</p>

    <p *ngIf="enrichOngoing && loading" class="mb-3">
      <b>Обогащение анкеты для {{ enrichOngoingInn }}:</b>
      <span class="d-block mb-1">{{ enrichCurrentMethod }}</span>
      <span class="d-block mb-1 text-danger" *ngIf="enrichError">{{ enrichError }}</span>
      <ngb-progressbar [value]="enrichProgress" [type]="enrichError ? 'danger' : 'success'" [striped]="true" [animated]="true" [showValue]="false"></ngb-progressbar>
    </p>
  </form>

  <div class="notification my-5" *ngIf="step === 2 && !selectedServiceLoading">
    <img src="/assets/svg/redesign/exclamation.svg" alt="notice" />
    <div>Заявка сохраняется в черновик пока вы заполняете форму. Вы можете вернуться и продолжить.</div>
  </div>

  <div class="mt-5" #scoringBlock>
    <div ngbDropdown class="d-block mb-3" *ngIf="scoring[0].data().length && scoring[1].data().length">
      <button class="btn btn-primary" id="dropdownScoring" ngbDropdownToggle>Результат скоринга {{ showScoring ? 'второй стороны' : 'заявителя' }}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownScoring">
        <button ngbDropdownItem (click)="showScoring = 0">Результат скоринга заявителя</button>
        <button ngbDropdownItem (click)="showScoring = 1">Результат скоринга второй стороны</button>
      </div>
    </div>

    <div>
      <p class="text-center mt-3" *ngIf="scoring[showScoring].isLoading && !enrichOngoing"><fa-icon [icon]="iconLoading" [spin]="true"></fa-icon></p>
      <p *ngIf="enrichOngoing && scoring[showScoring].isLoading && !loading" class="my-3 px-2">
        <b>Обогащение анкеты:</b>
        <span class="d-block mb-1">{{ enrichCurrentMethod }}</span>
        <span class="d-block mb-1 text-danger" *ngIf="enrichError">{{ enrichError }}</span>
        <ngb-progressbar [value]="enrichProgress" [type]="enrichError ? 'danger' : 'success'" [striped]="true" [animated]="true" [showValue]="false"></ngb-progressbar>
      </p>
      <app-scoring-table [data]="scoring[showScoring].data()" *ngIf="scoring[showScoring].data().length && !scoring[showScoring].isLoading"></app-scoring-table>
    </div>
  </div>
</div>

<app-consult-block></app-consult-block>
