<div class="bg-light py-5">
  <div class="container py-3">
    <div class="disclaimer">
      <p class="mb-4">© {{ year }} Все права защищены и охраняются законом. Использование материалов сайта разрешено только с письменного разрешения ООО «МайФи». Данный интернет-сайт носит исключительно информационный характер и не является публичной офертой.</p>
      <a [href]="personalDataAgreementFile">Политика обработки персональных данных</a>
    </div>
    <div class="sign-in">
      <div class="mb-4 mr-5" *ngIf="auth.onUpdate() | async as user">
        <a routerLink="/account/factoring" class="btn btn-dark">Войти</a>
      </div>
      <div class="flex-grow-1">
        <p class="mb-1"><a [href]="'tel:' + supportPhone">{{ supportPhoneFormat }}</a></p>
        <p><a [href]="'mailto:' + supportEmail">{{ supportEmail }}</a></p>
      </div>
    </div>
    <div class="logo">
      <app-logo class="mr-5 mr-md-0"></app-logo>
      <p class="font-weight-bold flex-grow-1">Платформа быстрых<br />финансов для бизнеса</p>
    </div>
  </div>
</div>
