export function arrayUnique<T>(array: T[]): T[] {
  return array.filter((val, i, arr) => arr.indexOf(val) === i);
}

export function pluralize(n: number, form1: string, form2: string, form3: string): string {
  const m100 = n % 100;
  const m10 = n % 10;

  let str: string;

  if (m100 < 5 || m100 > 20) {
    if (m10 === 1)
      str = form1;

    if (m10 > 1 && m10 < 5) {
      str = form2;
    } else {
      str = form3;
    }
  } else {
    str = form3;
  }

  return str
    .replace(/#/, n.toString());
}

export function isEmpty(value: any): boolean {
  return value === undefined || value === null || value === '';
}
