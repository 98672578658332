import { ClientRequest } from './client-request';
import { ICredit, IOfferCredit } from '../../app/types';
import { OfferCredit } from '../offer/offer-credit';

export class Credit extends ClientRequest<IOfferCredit> implements ICredit {
  credit_target: number;
  credit_term: number;
  credit_type: number;
  material_losses: boolean;
  sum: number;

  protected _uaEndpoint = 'credit';

  getTerm() {
    return this.credit_term;
  }

  getSum() {
    return this.sum;
  }

  protected _castOffer(data: IOfferCredit): OfferCredit {
    return new OfferCredit(this._http, data);
  }
}
