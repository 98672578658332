import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocTypeListComponent } from './doc-type-list/doc-type-list.component';
import { PageDocumentsComponent } from './page-documents.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    PageDocumentsComponent,
    DocTypeListComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule
  ],
  exports: [
    PageDocumentsComponent
  ]
})
export class PageDocumentsModule { }
