import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class JwtInterceptor  implements HttpInterceptor {

  constructor(private _auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._auth.getToken();
    let handler$;

    if (token) {
      req = req.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      });

      handler$ = next.handle(req).pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status == 401) {
            this._auth.logout();
          }

          return throwError(errorResponse);
        })
      );
    } else {
      handler$ = next.handle(req);
    }

    return handler$;
  }
}
