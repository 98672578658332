<div class="modal-header">
  <h2 class="modal-title">Введите новый пароль</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="alert alert-info" *ngIf="message">{{ message }}</div>

  <form class="mb-1" [formGroup]="form" (submit)="submit()">
    <div class="form-group mb-3">
      <mat-form-field class="w-100">
        <mat-label>Новый пароль</mat-label>
        <input
          required
          matInput
          formControlName="password"
          type="password"
        />
        <mat-error *ngIf="form.get('password').touched && form.get('password').errors?.required">Необходимо заполнить</mat-error>
        <mat-error *ngIf="form.get('password').touched && form.get('password').errors?.minLength">Минимум 6 символов</mat-error>
        <mat-error *ngIf="form.get('password').touched && form.get('password').errors?.pattern">Допустимы только латинские буквы, цифры и спец символы: !@#$%^&*_=</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group mb-4">
      <mat-form-field class="w-100">
        <mat-label>Повторите новый пароль</mat-label>
        <input
          required
          matInput
          [errorStateMatcher]="errorMatcher"
          formControlName="password_repeat"
          type="password"
        />
        <mat-error *ngIf="form.get('password_repeat').touched && form.get('password_repeat').errors?.required">Необходимо заполнить</mat-error>
        <mat-error *ngIf="form.get('password_repeat').touched && form.errors?.match">Пароли не совпадают</mat-error>
      </mat-form-field>
    </div>
    <p class="help-block text-danger text-center">{{ error }}</p>
    <div class="text-center mt-4">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="form.pristine || form.invalid || loading">Сохранить новый пароль <fa-icon *ngIf="loading" [icon]="faCircleNotch" [spin]="true"></fa-icon></button>
    </div>
  </form>
</div>
