import { Offer } from './offer';
import { GuaranteeOfferStatus, ILeasing, IOfferGuarantee } from '../../app/types';

export class OfferGuarantee extends Offer<ILeasing> implements IOfferGuarantee {
  bankguarantee_status: GuaranteeOfferStatus;
  price_in_percent: number;
  valueUnit = null;

  protected _uaEndpoint = 'bank-guarantee';

  getOfferValue(): number {
    return null;
  }

  getRate(): number {
    return this.price_in_percent;
  }
}
