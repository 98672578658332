import { CalcRule } from '../../../types';

export const calcRules: CalcRule[] = [
  {
    cond: service => /^Финансирование под выручку от контракта$/.test(service.name),
    fields: [
      {
        name: 'contractSum',
        label: 'Сумма контракта, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round(fields.contractSum * 0.5),
        unit: 'тыс. ₽/мес'
      }
    ]
  },

  {
    cond: service => service.name.toLowerCase() === 'закупочный факторинг',
    fields: [
      {
        name: 'procurement',
        label: 'Среднемесячный объем закупок с отсрочкой, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round(fields.procurement * 800),
        unit: '₽/мес'
      }
    ]
  },

  {
    cond: service => /^Финансирование под выручку от (?:плат[её]ж|кредит)ных карт$/.test(service.name),
    fields: [
      {
        name: 'revenue',
        label: 'Ваша средняя выручка за последние 6 мес, тыс. руб',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round(fields.revenue * 0.8),
        unit: 'тыс. ₽/мес'
      },
      {
        name: 'sum',
        label: 'Сумма финансирования в месяц',
        result: fields => Math.round(fields.revenue * 0.8),
        unit: 'тыс. ₽/мес'
      }
    ]
  },

  {
    cond: service => service.name.toLowerCase() === 'микрофакторинг',
    fields: [
      {
        name: 'deb',
        label: 'Сумма дебиторской задолженности, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      },
      {
        name: 'period',
        label: 'Отсрочка платежа, дней',
        min: 1,
        max: 1095,
        step: 1,
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round((fields.deb * 1000 * (12 / 365 / fields.period)) * 1000),
        unit: '₽/мес'
      }
    ]
  },

  {
    cond: service => service.name.toLowerCase() === 'факторинг без регресса',
    fields: [
      {
        name: 'deb',
        label: 'Сумма дебиторской задолженности, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      },
      {
        name: 'period',
        label: 'Отсрочка платежа, дней',
        min: 1,
        max: 1095,
        step: 1,
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round((fields.deb * 1000 * (12 / 365 / fields.period)) * 1000),
        unit: '₽/мес'
      }
    ]
  },

  {
    cond: service => service.name.toLowerCase() === 'факторинг с регрессом',
    fields: [
      {
        name: 'deb',
        label: 'Сумма дебиторской задолженности, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      },
      {
        name: 'period',
        label: 'Отсрочка платежа, дней',
        min: 1,
        max: 1095,
        step: 1,
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round(fields.deb * 1000000 * (12 / 365 / fields.period)),
        unit: '₽/мес'
      }
    ]
  },

  {
    cond: service => service.name.toLowerCase().indexOf('госфакторинг') === 0,
    fields: [
      {
        name: 'deb',
        label: 'Сумма госконтракта, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      },
      {
        name: 'period',
        label: 'Отсрочка платежа, дней',
        min: 1,
        max: 1095,
        step: 1,
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round((fields.deb * 1000 * (12 / 365 / fields.period)) * 1000),
        unit: '₽/мес'
      }
    ]
  },

  {
    cond: service => service.name.toLowerCase().includes('лизинг'),
    fields: [
      {
        name: 'value',
        label: 'Стоимость оборудования, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      },
      {
        name: 'period',
        label: 'Срок службы оборудования, мес',
        min: 1,
        max: 5400,
        step: 1,
      }
    ],
    results: [
      {
        name: 'sum',
        label: 'Сумма финансирования',
        result: fields => Math.round(fields.value * 850),
        unit: '₽/мес',
        prefix: 'от '
      },
      {
        name: 'payments',
        label: 'Лизинговый платеж',
        result: (fields, results) => Math.round((results.sum / fields.period) * 1.12),
        unit: '₽/мес',
        prefix: 'от ',
        disclaimer: 'Расчет является предварительным и не является конечным предложением'
      }
    ]
  },

  {
    cond: service => service.name.toLowerCase() === 'льготный кредит',
    fields: [
      {
        name: 'sum',
        label: 'Сумма кредита, ₽',
        min: 100000,
        max: 1000000,
        step: 10000
      },
      {
        name: 'period',
        label: 'Срок в месяцах',
        min: 1,
        max: 36,
        step: 1,
      }
    ],
    results: [
      {
        name: 'payments',
        label: 'Ежемесячный платеж',
        result: fields => Math.round(fields.sum * (0.08 + 0.08 / (Math.pow(1 + 0.08, fields.period) - 1))),
        unit: '₽'
      }
    ]
  },

  {
    cond: () => true, // DEFAULT
    fields: [
      {
        name: 'deb',
        label: 'Сумма дебиторской задолженности, тыс. рублей',
        min: 100,
        max: 10000000,
        step: 50,
        multiplier: 1000
      },
      {
        name: 'period',
        label: 'Отсрочка платежа, дней',
        min: 1,
        max: 1095,
        step: 1,
      }
    ],
    results: [
      {
        name: 'limit',
        label: 'Ваш лимит финансирования',
        result: fields => Math.round(fields.deb * 1000000 * (12 / 365 / fields.period)),
        unit: '₽/мес'
      }
    ]
  },
];
