import { ClientRequest } from './client-request';
import { IFactoring, IOfferFactoring } from '../../app/types';
import { OfferFactoring } from '../offer/offer-factoring';

export class Factoring extends ClientRequest<IOfferFactoring> implements IFactoring {
  contract_period: number;
  contract_sum: number;

  protected _uaEndpoint = 'factoring';

  getTerm() {
    return this.contract_period;
  }

  getSum() {
    return this.contract_sum;
  }

  protected _castOffer(data: IOfferFactoring): OfferFactoring {
    return new OfferFactoring(this._http, data);
  }
}
