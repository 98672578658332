<div class="container">
  <form [formGroup]="form" (submit)="submit()">
    <h4>Личные данные</h4>

    <div class="row">
      <div class="col-6 offset-3">
        <div class="form-group">
          <label>Фамилия</label>
          <input type="text" class="form-control" [ngClass]="{'is-invalid': form.get('last_name').invalid || backendErrors?.last_name }" formControlName="last_name" />
          <div class="invalid-feedback" *ngIf="form.get('last_name').errors && form.get('last_name').errors.required">Обязательное поле</div>
          <ng-container *ngIf="backendErrors?.last_name">
            <div class="invalid-feedback" *ngFor="let fieldError of backendErrors?.last_name">{{ fieldError }}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label>Имя</label>
          <input type="text" formControlName="first_name" class="form-control" [ngClass]="{'is-invalid': form.get('first_name').invalid || backendErrors?.first_name }" />
          <div class="invalid-feedback" *ngIf="form.get('first_name').errors && form.get('first_name').errors.required">Обязательное поле</div>
          <ng-container *ngIf="backendErrors?.first_name">
            <div class="invalid-feedback" *ngFor="let fieldError of backendErrors?.first_name">{{ fieldError }}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label>Отчество</label>
          <input type="text" formControlName="second_name" class="form-control" [ngClass]="{'is-invalid': form.get('second_name').invalid || backendErrors?.second_name }" />
          <ng-container *ngIf="backendErrors?.second_name">
            <div class="invalid-feedback" *ngFor="let fieldError of backendErrors?.second_name">{{ fieldError }}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label>Имя пользователя</label>
          <input type="text" readonly formControlName="username" class="form-control" [ngClass]="{'is-invalid': form.get('username').invalid || backendErrors?.username }" />
          <div class="invalid-feedback" *ngIf="form.get('username').errors && form.get('username').errors.required">Обязательное поле</div>
          <ng-container *ngIf="backendErrors?.username">
            <div class="invalid-feedback" *ngFor="let fieldError of backendErrors?.username">{{ fieldError }}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label>Мобильный телефон<ng-container *ngIf="!user.mobile_phone"> (<a href="javascript:void(0)" (click)="changePhone()">изменить</a>)</ng-container></label>
          <input type="text" class="form-control-plaintext" [value]="user.mobile_phone || '-- не указан --'" readonly />
          <p class="d-flex text-danger small" *ngIf="!user.mobile_phone">
            <fa-icon [icon]="iconExclamation" class="flex-shrink-1 mr-2"></fa-icon> Мобильныый телефон не указан! Для создания заявок и предложений необходимо указать и подтвердить номер
            мобильного телефона.
          </p>
        </div>
        <div class="form-group">
          <label>Электронный адрес</label>
          <input type="text" formControlName="email" class="form-control-plaintext" readonly/>
        </div>
      </div>
    </div>

    <hr />

    <ng-container *ngIf="user.isUser()">
      <h4>Реквизиты</h4>

      <div class="row">
        <div class="col-6 offset-3">
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>ОГРН</label>
                <input type="text" readonly class="form-control" [value]="detail.ogrn" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>От</label>
                <input type="text" readonly class="form-control" [value]="detail.ogrn_date | date:'dd.MM.yyyy'" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>ИНН</label>
                <input type="text" readonly class="form-control" [value]="detail.inn" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>КПП</label>
                <input type="text" readonly class="form-control" [value]="detail.kpp" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Дата регистрации</label>
            <input type="text" readonly class="form-control" [value]="detail.registration_date | date:'dd.MM.yyyy'" />
          </div>

          <div class="form-group">
            <label>ОКПО</label>
            <input type="text" readonly class="form-control" [value]="detail.okpo" />
          </div>

          <div class="form-group">
            <label>Юридический адрес</label>
            <input type="text" readonly class="form-control" [value]="detail.legal_address" />
          </div>

          <div class="form-group">
            <label>Вид деятельности</label>
            <input type="text" readonly class="form-control" [value]="detail.principal_activity" />
          </div>

          <div class="form-group">
            <label>Уставной капитал</label>
            <input type="text" readonly class="form-control" [value]="detail.stated_capital" />
          </div>
        </div>
      </div>

      <hr />
    </ng-container>

    <h4>Смена пароля</h4>

    <div class="row">
      <div class="col-6 offset-3">
        <div class="form-group">
          <label>Старый пароль</label>
          <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': form.errors && form.errors.oldPasswordNeed || backendErrors?.password }" />
          <div class="invalid-feedback" *ngIf="form.errors && form.errors.oldPasswordNeed">Необходимо ввести текущий пароль</div>
          <ng-container *ngIf="backendErrors?.password">
            <div class="invalid-feedback" *ngFor="let fieldError of backendErrors?.password">{{ fieldError }}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label>Новый пароль</label>
          <input type="password" formControlName="new_password" class="form-control" [ngClass]="{ 'is-invalid': form.errors && form.errors.passwordsNotSame || backendErrors?.new_password }" />
          <div class="invalid-feedback" *ngIf="form.errors && form.errors.passwordsNotSame">Пароли на совпадают</div>
          <ng-container *ngIf="backendErrors?.new_password">
            <div class="invalid-feedback" *ngFor="let fieldError of backendErrors?.new_password">{{ fieldError }}</div>
          </ng-container>
        </div>
        <div class="form-group">
          <label>Повторить пароль</label>
          <input type="password" formControlName="new_password_repeat" class="form-control" [ngClass]="{ 'is-invalid': form.errors && form.errors.passwordsNotSame }" />
          <div class="invalid-feedback" *ngIf="form.errors && form.errors.passwordsNotSame">Пароли на совпадают</div>
        </div>
      </div>
    </div>

    <hr />

    <h4>Уведомления</h4>


    <div class="row">
      <div class="col-6 offset-3 mb-5">
        <div class="form-check mb-4">
          <input class="form-check-input" type="checkbox" formControlName="notifications">
          <label class="form-check-label">Присылать уведомление на почту о новостях и акциях</label>
        </div>

        <p class="text-success" *ngIf="message">{{ message }}</p>
        <p class="text-danger" *ngIf="error">{{ error }}</p>
        <ng-container *ngIf="backendErrors?.non_field_errors">
          <p class="text-danger" *ngFor="let error of backendErrors?.non_field_errors">{{ error }}</p>
        </ng-container>

        <button type="submit" class="btn btn-primary btn-block btn-lg" [disabled]="loading">Сохранить <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="loading"></fa-icon></button>
      </div>
    </div>
  </form>
</div>
