<form [formGroup]="form">
  <div class="form-group mb-5">
    <label>Наименование (Согласно спецификации)</label>
    <p class="text-muted small">Укажите наименование технического/транспортного средства под лизинг в точности как указано в Спецификации</p>

    <div class="row">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <input
          formControlName="object"
          type="text"
          class="form-control"
          [class.is-invalid]="form.get('object').touched && form.get('object').invalid"
          placeholder="Укажите наименование"
        />
        <p class="invalid-feedback" *ngIf="form.get('object').errors?.required">Обязательное поле</p>
        <p class="invalid-feedback" *ngIf="form.get('object').errors?.pattern">Введите название</p>
      </div>
    </div>
  </div>

  <div class="form-group mb-5">
    <label>Год</label>
    <p class="text-muted small">Год указывается целым числом без месяца</p>
    <input
      formControlName="year"
      type="text"
      class="form-control w-auto"
      [class.is-invalid]="form.get('year').touched && form.get('year').invalid"
      appFormat
      [min]="1970"
      [max]="maxYear"
      (input)="yearSlider.value = form.get('year').value"
    />
    <p class="invalid-feedback" *ngIf="form.get('year').errors?.required">Обязательное поле</p>
    <p class="invalid-feedback" *ngIf="form.get('year').errors?.max">Не может быть позже {{ maxYear }}</p>
    <p class="invalid-feedback" *ngIf="form.get('year').errors?.min">Не может быть раньше 1970</p>
    <p class="invalid-feedback" *ngIf="form.get('year').errors?.pattern">Только целые числа</p>

    <div class="row mt-3">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <input
          #yearSlider
          type="range"
          class="custom-range"
          [min]="1970"
          [max]="maxYear"
          [value]="form.get('year').value"
          (input)="form.get('year').setValue($event.target.value)" />
      </div>
    </div>
  </div>

  <div class="form-group mb-5">
    <label>Степень износа, %/км/моточасы</label>
    <p class="text-muted small">Укажите степень износа оборудования/транспорта в процентах, пробеге или моточасах, при этом дроби указываются через точку</p>
    <input
      formControlName="wear_degree"
      type="text"
      class="form-control w-auto"
      [class.is-invalid]="form.get('wear_degree').touched && form.get('wear_degree').invalid"
      appFormat
      formatType="float"
    />
    <p class="invalid-feedback" *ngIf="form.get('wear_degree').errors?.required">Обязательное поле</p>
    <p class="invalid-feedback" *ngIf="form.get('wear_degree').errors?.min">Не может быть меньше 0</p>
  </div>

  <div class="row mb-5">
    <div class="col-12 col-md-10 col-lg-9 col-xl-8">
      <div class="form-row align-items-end">
        <div class="col-9 col-md-5">
          <div class="form-group mb-0">
            <label>Стоимость за ед. с НДС, р.</label>
            <p class="text-muted small">Укажите стоимость одной единицы оборудования/транспорта в рублях с учетом НДС без пробелов, дроби должны вводиться через точку</p>
            <input
              formControlName="price"
              type="text"
              class="form-control no-appearance"
              [class.is-invalid]="form.get('price').touched && form.get('price').invalid"
              appFormat
              formatType="float"
              [separate]="true"
            />
          </div>
        </div>

        <div class="col-3 col-md-2">
          <div class="form-group mb-0 overflow-hidden text-overflow-ellipsis">
            <label class="d-inline">Количество</label>
            <input
              formControlName="count"
              type="text"
              class="form-control no-appearance"
              [class.is-invalid]="form.get('count').touched && form.get('count').invalid"
              appFormat
              formatType="integer"
            />
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div class="form-group mb-0 mt-2">
            <label>Итого стоимость с НДС, руб</label>
            <input
              readonly
              formControlName="total"
              type="text"
              class="form-control"
              [value]="separate.transform(form.get('price').value * form.get('count').value)"
              appFormat
              formatType="float"
              [separate]="true"
            />
          </div>
        </div>
      </div>

      <div class="text-danger" *ngIf="form.get('count').touched && form.get('count').invalid || form.get('price').touched && form.get('price').invalid">
        <div class="help-block mt-1" *ngIf="form.get('price').errors?.required || form.get('count').errors?.required">Обязательные поля</div>
        <div class="help-block mt-1" *ngIf="form.get('count').errors?.pattern">Кол-во: только целые числа</div>
        <div class="help-block mt-1" *ngIf="form.get('count').errors?.min">Кол-во: не может быть меньше 1</div>
        <div class="help-block mt-1" *ngIf="form.get('count').errors?.max">Кол-во: не больше 1000</div>
        <div class="help-block mt-1" *ngIf="form.get('price').errors?.min">Стоимость: не может быть меньше 100000</div>
        <div class="help-block mt-1" *ngIf="form.get('price').errors?.max">Стоимость: не больше 10 млрд</div>
      </div>
    </div>
  </div>

  <div class="form-group mb-5">
    <label>Тип лизинговых платежей</label>
    <p class="text-muted small">Выберете нужный тип платежей</p>
    <select formControlName="pay_type" class="form-control w-auto">
      <option *ngFor="let type of leasingPayTypes" [value]="type.id">{{ type.label }}</option>
    </select>
  </div>

  <div class="form-group mb-5">
    <label class="mb-3">Срок лизинга, мес.</label>

    <div class="row">
      <app-changers-input
        class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2"
        formControlName="limitation"
        min="1"
        max="120"
        [invalid]="form.get('limitation').touched && form.get('limitation').invalid"
      ></app-changers-input>
    </div>

    <div class="text-danger" *ngIf="form.get('limitation').touched">
      <p class="help-block mt-1" *ngIf="form.get('limitation').errors?.required">Обязательное поле</p>
      <p class="help-block mt-1" *ngIf="form.get('limitation').errors?.min">Не может быть меньше 1</p>
      <p class="help-block mt-1" *ngIf="form.get('limitation').errors?.max">Не может быть больше 120</p>
    </div>
  </div>

  <div class="form-group mb-5">
    <label>Размер аванса, руб</label>
    <p class="text-muted small">Размер аванса вводится в рублях без пробелов, дроби должны вводиться через точку</p>
    <input
      formControlName="prepaid"
      type="text"
      class="form-control w-auto"
      [class.is-invalid]="form.get('prepaid').touched && form.get('prepaid').invalid"
      appFormat
      formatType="float"
      [separate]="true"
    />
    <p class="invalid-feedback" *ngIf="form.get('prepaid').errors?.required">Обязательное поле</p>
    <p class="invalid-feedback" *ngIf="form.get('prepaid').errors?.min">Не может быть меньше 100 тыс</p>
    <p class="invalid-feedback" *ngIf="form.get('prepaid').errors?.max">Не может быть больше 10 млрд</p>
  </div>

  <div class="custom-control custom-checkbox mb-5">
    <input formControlName="is_change_contract_of_sale" class="custom-control-input" type="checkbox" value="" id="leasing_is_change_contract_of_sale">
    <label class="custom-control-label" for="leasing_is_change_contract_of_sale">
      Владелец лота готов менять форму ДКП
    </label>
  </div>

  <div class="row">
    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
      <div class="form-group mb-5" formArrayName="pay_schema">
        <label>Схема расчётов по договору поставки, %</label>
        <p class="text-muted small">Укажите в каком процентном соотношении будет производиться расчёт по договору поставки, что в целом должно составлять 100%</p>
        <div class="form-row">
          <div class="col">
            <input
              formControlName="0"
              type="text"
              class="form-control no-appearance"
              [class.is-invalid]="getArrayControl('pay_schema', 0).touched && getArrayControl('pay_schema', 0).invalid"
              appFormat
              formatType="float"
            />
          </div>
          <div class="col">
            <input
              formControlName="1"
              type="text"
              class="form-control no-appearance"
              [class.is-invalid]="getArrayControl('pay_schema', 1).touched && getArrayControl('pay_schema', 1).invalid"
              appFormat
              formatType="float"
            />
          </div>
          <div class="col">
            <input
              formControlName="2"
              type="text"
              class="form-control no-appearance"
              [class.is-invalid]="getArrayControl('pay_schema', 2).touched && getArrayControl('pay_schema', 2).invalid"
              appFormat
              formatType="float"
            />
          </div>
        </div>
        <p class="text-danger help-block" *ngIf="isArrayInvalid('pay_schema')">Необходимо заполнить все поля от 0 до 100</p>
        <p class="text-danger help-block" *ngIf="form.get('pay_schema').touched && form.errors?.pay_schema">Сумма должна быть равна 100</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
      <div class="form-group seasonality mb-5" formArrayName="season_chart">
        <label class="mb-3">График сезонности, %</label>
        <ng-container *ngIf="!showChart">
          <div class="form-row">
            <div class="col d-flex flex-column text-right">
              <div class="mb-1"><span class="form-control-plaintext">Январь</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Февраль</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Март</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Апрель</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Май</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Июнь</span></div>
            </div>
            <div class="col d-flex flex-column">
              <ng-container *ngFor="let control of form.get('season_chart').controls; index as i">
                <div class="mb-1" *ngIf="i < 6">
                  <input
                    type="text"
                    class="form-control no-appearance"
                    [class.is-invalid]="getArrayControl('season_chart', i).touched && getArrayControl('season_chart', i).invalid"
                    [formControlName]="i"
                    appFormat
                    formatType="float"
                  />
                </div>
              </ng-container>
            </div>
            <div class="col d-flex flex-column text-right">
              <div class="mb-1"><span class="form-control-plaintext">Июль</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Август</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Сентябрь</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Октябрь</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Ноябрь</span></div>
              <div class="mb-1"><span class="form-control-plaintext">Октябрь</span></div>
            </div>
            <div class="col d-flex flex-column">
              <ng-container *ngFor="let control of form.get('season_chart').controls; index as i">
                <div class="mb-1" *ngIf="i > 5">
                  <input
                    type="text"
                    class="form-control no-appearance"
                    [class.is-invalid]="getArrayControl('season_chart', i).touched && getArrayControl('season_chart', i).invalid"
                    [formControlName]="i"
                    appFormat
                    formatType="float"
                  />
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <app-vertical-column-chart *ngIf="showChart" [values]="chartValues()"></app-vertical-column-chart>

        <p class="text-danger help-block" *ngIf="isArrayInvalid('season_chart')">Необходимо заполнить все поля от 0 до 100</p>
        <p class="text-danger help-block" *ngIf="form.get('season_chart').touched && form.errors?.season_chart">Сумма должна быть равна 100 (сейчас {{ arraySum('season_chart') }})</p>
        <p class="text-center mt-3">
          <a href="javascript:void(0)" (click)="showChart = !showChart">{{ showChart ? 'Скрыть' : 'Показать' }} график на диаграмме</a>
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-10 col-xl-8">
      <div class="form-group mb-5">
        <app-file-uploader
          class="mb-3"
          label="Загрузить спецификацию техники"
          formControlName="specification"
          [single]="true"
          [noSignature]="true"
          [optional]="true"
        ></app-file-uploader>
        <app-file-uploader
          class="mb-3"
          label="Загрузить контракт на поставку техники"
          formControlName="contract"
          [single]="true"
          [noSignature]="true"
          [optional]="true"
        ></app-file-uploader>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
      <div class="form-group">
        <label>ИНН заявителя</label>
        <p class="text-muted small">Укажите ваш ИНН</p>
        <div class="separated-input-group">
          <input formControlName="inn" class="form-control" type="text" [class.is-invalid]="form.get('inn').touched && (form.get('inn').invalid || inn1Error)" [readOnly]="lockInn" />
          <button class="btn btn-primary btn-hollow btn-lg" (click)="loadInn1()" [disabled]="loading || enrichOngoing || !validScoring1()">Скоринг</button>
        </div>
        <div class="text-danger" *ngIf="form.get('inn').touched || inn1Error">
          <div class="help-block mt-1" *ngIf="form.get('inn').invalid && form.get('inn').errors.required">Введите ИНН</div>
          <div class="help-block mt-1" *ngIf="form.get('inn').invalid && form.get('inn').errors.pattern">Недопустимый ИНН</div>
          <div class="help-block mt-1" *ngIf="inn1Error">{{ inn1Error }}</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
      <div class="form-group" *ngIf="!service.hide_second_inn">
        <label>ИНН второй стороны</label>
        <p class="text-muted small">Укажите ИНН поставщика / контрагента</p>

        <div class="separated-input-group">
          <input formControlName="inn_second" class="form-control" type="text" [class.is-invalid]="form.get('inn_second').touched && (form.get('inn_second').invalid || inn2Error)" />
          <button class="btn btn-primary btn-hollow btn-lg" (click)="loadInn2()" [disabled]="loading || enrichOngoing || !validScoring2()">Скоринг</button>
        </div>
        <div class="text-danger" *ngIf="form.get('inn_second').touched || inn2Error">
          <div class="help-block mt-1" *ngIf="form.get('inn_second').invalid && form.get('inn_second').errors.required">Введите ИНН</div>
          <div class="help-block mt-1" *ngIf="form.get('inn_second').invalid && form.get('inn_second').errors.pattern">Недопустимый ИНН</div>
          <div class="help-block mt-1" *ngIf="inn2Error">{{ inn2Error }}</div>
        </div>
      </div>
    </div>
  </div>
</form>
