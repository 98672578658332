import { Offer } from './offer';
import { IFactoring, IOfferFactoring } from '../../app/types';

export class OfferFactoring extends Offer<IFactoring> implements IOfferFactoring {
  finance_limit: number;
  finance_percent: number;
  rate: number;
  valueUnit = '₽';

  protected _uaEndpoint = 'factoring';

  getOfferValue(): number {
    return this.finance_limit;
  }

  getRate(): number {
    return this.rate;
  }
}
