import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map, switchAll } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient, private _auth: AuthService) {
  }

  register(
    first_name: string,
    last_name: string,
    email: string,
    password: string
  ): Observable<any> {
    return this._http.post<{ token: string }>(
      environment.apiEndpoint + '/users',
      {
        first_name,
        last_name,
        email,
        password
      }
    ).pipe(
      map(user => this._auth.authenticateByToken(user.token)),
      switchAll()
    );
  }

  updatePhone(userId: number|string, oldPhone: string, newPhone: string, code = null) {
    const body: {[k: string]: any} = {new_mobile_phone: newPhone};
    if (oldPhone) body.mobile_phone = oldPhone;
    if (code) body.sms_code = code;

    return this._http.post(`${environment.apiEndpoint}/users/${userId}/change-mobile-phone`, body);
  }
}
