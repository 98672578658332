import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticService } from '../../services/analytic.service';

@Component({
  selector: 'app-manual-modal',
  templateUrl: './manual-modal.component.html',
  styleUrls: ['./manual-modal.component.scss']
})
export class ManualModalComponent {
  manualUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._getPdfUrl());

  constructor(
    public activeModal: NgbActiveModal,
    private _analytics: AnalyticService,
    private _router: Router,
    private _auth: AuthService,
    private _sanitizer: DomSanitizer
  ) {
    _analytics.eventYandex('userManualModal_open');
  }

  loaded() {
    this._analytics.eventYandex('userManualModal_pdfLoaded');
  }

  private _getPdfUrl() {
    return '/static/' + (!this._auth.authenticatedUser().isFactor() ? 'manual-client.pdf' : 'manual-factor.pdf');
  }
}
