import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesTypesService } from '../../../services/services-types.service';
import { IClientService } from '../../../types';
import { FactoringDetailed } from '../../../../models/client-request/factoring-detailed';
import { AuthService } from '../../../services/auth.service';
import { Observable, timer } from 'rxjs';
import {
  map,
  switchAll,
  takeUntil,
  tap
} from 'rxjs/operators';
import { IRequestSubComponent } from '../interfaces';
import { VAT_FORMAT_REGEX } from '../../../constants';

@Component({
  selector: 'app-factoring',
  templateUrl: './factoring.component.html',
  styleUrls: ['./factoring.component.scss']
})
export class FactoringComponent implements OnInit, IRequestSubComponent {
  @Output() submitted = new EventEmitter<FormGroup>();
  @Output() loadInn1Clicked = new EventEmitter<{inn: string, sum: string, period: string}>();
  @Output() loadInn2Clicked = new EventEmitter<{inn: string, sum: string, period: string}>();
  @Input() enrichOngoing = false;
  @Input() service: IClientService;
  @Input() inn1Error: string;
  @Input() inn2Error: string;
  @Input() loading: boolean;
  @Input() data: FactoringDetailed;

  form: FormGroup;

  lockInn = false;
  daysLimit: number;

  constructor(private _servicesTypes: ServicesTypesService, private _fb: FormBuilder, private _auth: AuthService) {
    const date = new Date();

    // get days between current date and this date 3 years later
    this.daysLimit = Math.floor((new Date().setFullYear(date.getFullYear() + 3) - +date) / (1000 * 60 * 60 * 24));
  }

  ngOnInit(): void {
    const user = this._auth.authenticatedUser();

    let inn = '', inn_second = '';

    if (user.rank.slug === 'clients' && user.legal_detail && user.legal_detail.inn) {
      inn = user.legal_detail.inn;
      this.lockInn = true;
    }

    if (this.data) {
      if (this.data.company_declarer && !inn) {
        inn = this.data.company_declarer.inn;
        this.lockInn = false;
      }
      if (this.data.company_contractor) inn_second = this.data.company_contractor.inn;
    }

    this.form = this._fb.group({
      inn: [inn, [Validators.required, Validators.pattern(VAT_FORMAT_REGEX)]],
      inn_second: [inn_second, this.service.hide_second_inn
        ? Validators.pattern(VAT_FORMAT_REGEX)
        : [Validators.required, Validators.pattern(VAT_FORMAT_REGEX)]
      ],
      contract_sum: [
        parseFloat(this.data.contract_sum + '') || undefined,
        [Validators.required, Validators.min(100000), Validators.max(10000000000)]
      ],
      contract_period: [
        parseFloat(this.data.contract_period + '') || 0,
        [Validators.required, Validators.min(1), Validators.max(this.daysLimit)]
      ]
    });
  }

  exportData(): object {
    return this.form.value;
  }

  getTimerObservable(timeout: number, tapFn: (...a: any) => any, takeUntilFn: Observable<any>) {
    return timer(0, timeout).pipe(
      takeUntil(takeUntilFn),
      tap(tapFn),
      map(() => this.getTimerObservable(timeout * 0.9, tapFn, takeUntilFn)),
      switchAll()
    );
  }

  loadInn1() {
    this.form.updateValueAndValidity({ emitEvent: true });

    if (
      this.form.get('inn').invalid
      || this.form.get('contract_sum').invalid
      || this.form.get('contract_period').invalid
    ) return;

    this.loadInn1Clicked.emit({
      inn: this.form.get('inn').value,
      sum: this.form.get('contract_sum').value,
      period: this.form.get('contract_period').value
    });
  }

  loadInn2() {
    this.form.updateValueAndValidity({ emitEvent: true });

    if (
      this.form.get('inn_second').invalid
      || this.form.get('contract_sum').invalid
      || this.form.get('contract_period').invalid
    ) return;

    this.loadInn2Clicked.emit({
      inn: this.form.get('inn_second').value,
      sum: this.form.get('contract_sum').value,
      period: this.form.get('contract_period').value
    });
  }

  validScoring1(): boolean {
    return this.form.get('contract_sum').valid
      && this.form.get('contract_period').valid
      && this.form.get('inn').valid;
  }

  validScoring2(): boolean {
    return this.form.get('inn_second').value
      && this.form.get('contract_sum').valid
      && this.form.get('contract_period').valid
      && this.form.get('inn_second').valid;
  }

  submit() {
    this.submitted.emit(this.form);
  }
}
