<a [routerLink]="request.getPageUrl()" class="request-card flex-column flex-md-row" [ngClass]="'request-card--' + request.getStyle()">
  <h4 class="service-name mb-4">{{ request.service.name }}</h4>

  <div class="declarer small">
    <div class="font-weight-bold" *ngIf="request.company_declarer.name">{{ request.company_declarer.name }}</div>
    <div class="text-muted" *ngIf="request.company_declarer.inn">ИНН {{ request.company_declarer.inn }}</div>
    <div>Отсутствие убытков: <span class="text-danger font-weight-bold">НЕТ</span></div>
    <hr class="m-0 mt-3 mb-2 d-md-none" *ngIf="request.company_contractor?.inn" />
  </div>

  <div class="badge-block">
    <span class="msg-badge" *ngIf="request.offers_count">{{ request.offers_count }}</span>
  </div>

  <div class="term-block small font-weight-bold">
    {{ getTerm() }}
  </div>

  <div class="h4 sum">{{ (request.getSum() | septhousand) || '--' }} <span class="rouble">₽</span></div>

  <div class="contractor small" *ngIf="request.company_contractor">
    <div class="font-weight-bold" *ngIf="request.company_contractor.name">{{ request.company_contractor.name }}</div>
    <div class="text-muted" *ngIf="request.company_contractor.inn">ИНН {{ request.company_contractor.inn }}</div>
    <div class="d-none d-md-block">&nbsp;</div>
  </div>

  <div class="status text-right d-flex flex-column">
    <span [ngClass]="'text-' + request.getStyle()" [class.font-italic]="request.draft">
      {{ request.getStatusText() }}
    </span>
  </div>
</a>
