import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesTypesService } from '../../../services/services-types.service';
import { guaranteeFzName, MAX_CONTRACT_SUM, MIN_CONTRACT_SUM } from '../../../constants';
import { IClientService } from '../../../types';
import { AuthService } from '../../../services/auth.service';
import { GuaranteeDetailed } from '../../../../models/client-request/guarantee-detailed';
import { IRequestSubComponent } from '../interfaces';

const VAT_FORMAT: RegExp = /^\d{10,12}$/;

@Component({
  selector: 'app-guarantee',
  templateUrl: './guarantee.component.html',
  styleUrls: ['./guarantee.component.scss']
})
export class GuaranteeComponent implements OnInit, IRequestSubComponent {
  @Output() submitted = new EventEmitter<FormGroup>();
  @Output() loadInn1Clicked = new EventEmitter<{ inn: string }>();
  @Output() loadInn2Clicked = new EventEmitter<{ inn: string }>();
  @Input() service: IClientService;
  @Input() enrichOngoing = false;
  @Input() inn1Error: string;
  @Input() inn2Error: string;
  @Input() loading: boolean;
  @Input() data: GuaranteeDetailed;

  readonly MIN_CONTRACT_SUM = MIN_CONTRACT_SUM;
  readonly MAX_CONTRACT_SUM = MAX_CONTRACT_SUM;

  maxYear = new Date().getFullYear();
  lockInn = false;

  form: FormGroup;

  guaranteeFzName = guaranteeFzName;
  showChart = false;

  constructor(private _servicesTypes: ServicesTypesService, private _fb: FormBuilder, private _auth: AuthService) {
  }

  ngOnInit(): void {
    const user = this._auth.authenticatedUser();

    const {
      sum = '',
      limitation = '',
      fz,
      competition_url = '',
    } = this.data;

    let inn = '', inn_second = '';

    if (user.rank.slug === 'clients' && user.legal_detail && user.legal_detail.inn) {
      inn = user.legal_detail.inn;
      this.lockInn = true;
    }

    if (this.data) {
      if (this.data.company_declarer && !inn) {
        inn = this.data.company_declarer.inn;
        this.lockInn = false;
      }
      if (this.data.company_contractor) inn_second = this.data.company_contractor.inn;
    }

    this.form = this._fb.group({
      sum: [sum, [Validators.required, Validators.min(MIN_CONTRACT_SUM), Validators.max(MAX_CONTRACT_SUM)]],
      limitation: [limitation, [Validators.required, Validators.min(1)]],
      fz: [fz, [Validators.required]],
      competition_url: [competition_url, [Validators.required, Validators.pattern(/^(https?):\/\/(-\.)?([^\s/?.#-]+\.?)+(\/[^\s]*)?$/i)]],
      inn: [inn, [Validators.required, Validators.pattern(VAT_FORMAT)]],
      inn_second: [inn_second, Validators.pattern(VAT_FORMAT)],
    });
  }

  exportData(): object {
    return this.form.value;
  }

  loadInn1() {
    this.form.updateValueAndValidity({ emitEvent: true });

    if (this.form.get('inn').invalid) {
      return;
    }

    this.loadInn1Clicked.emit({inn: this.form.get('inn').value});
  }

  validScoring1(): boolean {
    return this.form.get('inn').valid && !this.inn1Error;
  }

  submit() {
    this.submitted.emit(this.form);
  }
}
