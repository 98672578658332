import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../../user/user.service';
import { Router } from '@angular/router';
import { AnalyticService } from '../../../services/analytic.service';
import { HTMLInputElementType } from '../../../types';

interface FormFieldScaffold {
  [name: string]: {
    label: string,
    type: HTMLInputElementType,
    control: { [key: string]: any },
    errors: { [key: string]: string }
    formCheck?: { [key: string]: string }
  };
}

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent {
  @Input() labels = true;
  @Input() placeholders = false;
  @Output() feedback: EventEmitter<boolean> = new EventEmitter<boolean>();

  fieldsOrder = ['last_name', 'first_name', 'email', 'password', 'passwordRepeat'];
  fields: FormFieldScaffold = {
    first_name: {
      label: 'Имя',
      type: 'text',
      control: ['', Validators.required],
      errors: { required: 'Введите имя' }
    },
    last_name: {
      label: 'Фамилия',
      type: 'text',
      control: ['', Validators.required],
      errors: { required: 'Введите фамилию' }
    },
    email: {
      label: 'Электронная почта',
      type: 'email',
      control: ['', [
        Validators.required,
        Validators.email
      ]],
      errors: {
        required: 'Введите email',
        email: 'Некорректный email'
      }
    },
    password: {
      label: 'Пароль',
      type: 'password',
      control: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^[A-Za-z0-9!@#$%^&*_=]*$/)
      ]],
      errors: {
        required: 'Введите пароль',
        minlength: 'Минимум 6 символов',
        pattern: 'Допустимы только латинские буквы, цифры и спец символы: !@#$%^&*_='
      }
    },
    passwordRepeat: {
      label: 'Повторите пароль',
      type: 'password',
      control: ['', Validators.required],
      errors: { required: 'Повторите ввод пароля' },
      formCheck: {
        passwordsNotSame: 'Пароли не совпадают'
      }
    }
  };

  form = this._fb.group({
    first_name: this.fields.first_name.control,
    last_name: this.fields.last_name.control,
    email: this.fields.email.control,
    password: this.fields.password.control,
    passwordRepeat: this.fields.passwordRepeat.control,
    privacy_policy: [false, Validators.requiredTrue],
    terms_of_service: [false, Validators.requiredTrue],
    client_personal: [false, Validators.requiredTrue],
    partner_personal: [false, Validators.requiredTrue],
  }, { validator: SignUpFormComponent.checkPasswords });

  faCircleNotch = faCircleNotch;
  loading = false;

  backendErrors: {[key: string]: string} = {};

  private static checkPasswords(group: FormGroup) {
    return group.get('password').value === group.get('passwordRepeat').value
      ? null
      : { passwordsNotSame: true };
  }

  constructor(
    private _fb: FormBuilder,
    private _users: UserService,
    private _router: Router,
    private _analytics: AnalyticService
  ) { }

  submit() {
    if (this.form.invalid)
      return;

    this.backendErrors = {};
    this.loading = true;

    const data = this.form.value;

    this._analytics.eventYandex('formSignUp_submit', {
      params: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email
      }
    });

    this._users.register(data.first_name, data.last_name, data.email, data.password)
      .subscribe(
        response => {
          this.loading = false;
          this.feedback.emit(true);
          this._analytics.eventGoogle('Submit', 'Forms', 'Registration');
          this._router.navigate(['account', 'factoring']);
        },
        errorResponse => {
          this.loading = false;
          this.feedback.emit(false);
          console.log(this.backendErrors = errorResponse.error);
        }
      );

    return false;
  }

  label(name: string) {
    return (this.placeholders && this.fields[name])
      ? this.fields[name].label
      : '';
  }

  isInvalid(fieldName: string): boolean {
    if (!this.form.get(fieldName) || !this.form.get(fieldName).touched)
      return false;

    if (this.backendErrors[fieldName]) {
      return true;
    }

    if (this.fields[fieldName] && this.fields[fieldName].formCheck && this.form.errors) {
      for (const error in this.fields[fieldName].formCheck) {
        if (!this.fields[fieldName].formCheck.hasOwnProperty(error))
          continue;

        if (this.form.errors[error])
          return this.form.get(fieldName).touched;
      }
    }

    return this.form.get(fieldName).invalid;
  }
}
