import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesTypesService } from '../../../services/services-types.service';
import { MONTHS } from '../../../constants';
import { IClientService } from '../../../types';
import { LeasingDetailed } from '../../../../models/client-request/leasing-detailed';
import { AuthService } from '../../../services/auth.service';
import { SepthousandPipe } from '../../../shared/pipes/septhousand.pipe';
import { IRequestSubComponent } from '../interfaces';

const VAT_FORMAT: RegExp = /^\d{10,12}$/;
const NUMERIC_VALIDATOR = [Validators.required, Validators.min(1)];
const PRICE_VALIDATOR = [Validators.required, Validators.min(100000), Validators.max(10000000000)];
const PERCENT_VALIDATOR = [Validators.required, Validators.min(0), Validators.max(100)];

function genArray(count: number, validator: any, data = []) {
  if (!data) data = [];

  return new Array(count)
    .fill(null)
    .map((_, i) => {
      const value = parseFloat(data[i]);
      return [isNaN(value) ? '' : value, validator];
    });
}

@Component({
  selector: 'app-leasing',
  templateUrl: './leasing.component.html',
  styleUrls: ['./leasing.component.scss']
})
export class LeasingComponent implements OnInit, IRequestSubComponent {
  @Output() submitted = new EventEmitter<FormGroup>();
  @Output() loadInn1Clicked = new EventEmitter<{ inn: string }>();
  @Output() loadInn2Clicked = new EventEmitter<{ inn: string }>();
  @Input() service: IClientService;
  @Input() enrichOngoing = false;
  @Input() inn1Error: string;
  @Input() inn2Error: string;
  @Input() loading: boolean;
  @Input() data: LeasingDetailed;

  maxYear = new Date().getFullYear();
  lockInn = false;

  form: FormGroup;

  leasingPayTypes = [
    {id: 1, label: 'Равный'},
    {id: 2, label: 'Убывающий'},
    {id: 3, label: 'Сезонный'}
  ];

  showChart = false;

  get season_chart() {
    return this.form.get('season_chart') as FormArray;
  }

  get pay_schema() {
    return this.form.get('pay_schema') as FormArray;
  }

  static validateSums(group: FormGroup) {
    const errors: { [k: string]: boolean } = {};
    const reduceArrow = (acc, el) => acc + el.value;
    const paySchemaSum = (group.get('pay_schema') as FormArray).controls.reduce(reduceArrow, 0);

    if (paySchemaSum > 100 || paySchemaSum < 100) {
      errors.pay_schema = true;
    }

    return errors;
  }

  constructor(
    public separate: SepthousandPipe,
    private _servicesTypes: ServicesTypesService,
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const user = this._auth.authenticatedUser();

    const {
      pay_type = 1,
      year = '',
      wear_degree = '',
      price = '',
      count = '',
      object = '',
      limitation = '',
      prepaid = '',
      is_change_contract_of_sale = false,
      specification = null,
      contract = null
    } = this.data;

    let total = '';

    if (price || count) {
      total = (parseFloat(price.toString() || '0') * parseFloat(count.toString() || '0')).toString();
    }

    let inn;

    if (user.rank.slug === 'clients' && user.legal_detail && user.legal_detail.inn) {
      inn = user.legal_detail.inn;
      this.lockInn = true;
    } else if (this.data.company_declarer) {
      inn = this.data.company_declarer.inn;
    }

    const inn_second = this.data.company_contractor ? this.data.company_contractor.inn : '';

    if (!inn && user.legal_detail && user.legal_detail.inn) {
      inn = user.legal_detail.inn;
      this.lockInn = true;
    }

    this.form = this._fb.group({
      pay_type: [pay_type],
      year: [year, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(1970), Validators.max(this.maxYear)]],
      wear_degree: [wear_degree, [Validators.required, Validators.min(0)]],
      price: [price, PRICE_VALIDATOR],
      count: [count, [...NUMERIC_VALIDATOR, Validators.max(1000), Validators.pattern(/^\d*$/)]],
      object: [object, [Validators.required, Validators.pattern(/[а-яА-Яa-zA-Z0-9\-_,."'()]{2,}/)]],
      total: [total],
      limitation: [limitation, [Validators.max(120), ...NUMERIC_VALIDATOR]],
      prepaid: [prepaid, PRICE_VALIDATOR],
      is_change_contract_of_sale: [is_change_contract_of_sale],
      season_chart: this._fb.array(genArray(12, PERCENT_VALIDATOR, this.data ? this.data.season_chart : [])),
      pay_schema: this._fb.array(genArray(3, PERCENT_VALIDATOR, this.data ? this.data.pay_schema : [])),
      contract: [contract],
      specification: [specification],

      inn: [inn, [Validators.required, Validators.pattern(VAT_FORMAT)]],
      inn_second: [inn_second, Validators.pattern(VAT_FORMAT)],
      contract_period: ['90']
    }, {validators: [LeasingComponent.validateSums]});
  }

  exportData(): object {
    const data = this.form.value;
    data.contract = data.contract ? data.contract.id : null;
    data.specification = data.specification ? data.specification.id : null;

    return data;
  }

  chartValues() {
    return this.season_chart.controls.map((control, i) => ({label: MONTHS[i], value: control.value}));
  }

  isArrayInvalid(name: string) {
    const controls = (this.form.get(name) as FormArray).controls;

    for (const control of controls) {
      if (control.touched && control.invalid) {
        return true;
      }
    }

    return false;
  }

  getArrayControl(name: string, i: number) {
    return (this.form.get(name) as FormArray).controls[i];
  }

  arraySum(name: string) {
    return (this.form.get(name) as FormArray)
      .controls.reduce((acc, control) => acc + control.value, 0);
  }

  loadInn1() {
    this.form.updateValueAndValidity({emitEvent: true});

    if (
      this.form.get('inn').invalid
      || this.form.get('limitation').invalid
      || this.form.get('prepaid').invalid
    ) { return; }

    this.loadInn1Clicked.emit({inn: this.form.get('inn').value});
  }

  loadInn2() {
    this.form.updateValueAndValidity({emitEvent: true});

    if (
      this.form.get('inn_second').invalid
      || this.form.get('limitation').invalid
      || this.form.get('prepaid').invalid
    ) { return; }

    this.loadInn2Clicked.emit({inn: this.form.get('inn_second').value});
  }

  validScoring1(): boolean {
    return this.form.get('prepaid').valid
      && this.form.get('limitation').valid
      && this.form.get('inn').valid
      && !this.inn1Error;
  }

  validScoring2(): boolean {
    return this.form.get('inn_second').value
      && this.form.get('prepaid').valid
      && this.form.get('limitation').valid
      && this.form.get('inn_second').valid
      && !this.inn2Error;
  }

  submit() {
    this.submitted.emit(this.form);
  }
}
