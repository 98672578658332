import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FilesService, IDoc, IDocTypeDetailed } from '../../../services/files';
import { Observable } from 'rxjs';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { IDataPage } from '../../../types';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-doc-type-list',
  templateUrl: './doc-type-list.component.html',
  styleUrls: ['./doc-type-list.component.scss']
})
export class DocTypeListComponent implements OnInit {
  @Input() type: IDocTypeDetailed;
  @ViewChild('heading') headingChild: ElementRef<HTMLDivElement>;

  readonly pageSize = 8;
  readonly iconCircleNotch = faCircleNotch;

  pagesCount = 1;
  page = 1;
  docs$: Observable<IDataPage<IDoc>>;

  constructor(private _files: FilesService) { }

  ngOnInit(): void {
    this.docs$ = this.getPage$(1);
  }

  getPage$(n: number): Observable<IDataPage<IDoc>> {
    this.page = n;

    if (this.headingChild) {
      this.headingChild.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return this._files.findByType(this.type.id, n, this.pageSize).pipe(
      tap(page => {
        this.pagesCount = Math.ceil(page.count / this.pageSize);
      })
    );
  }
}
