import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalsModule } from './modals/modals.module';
import localeRu from '@angular/common/locales/ru';
import extraLocaleRu from '@angular/common/locales/extra/ru';
import {
  ActivatedRoute,
  Router,
  RouterModule, Scroll,
} from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AccountModule } from './account/account.module';
import { LandingModule } from './landing/landing.module';
import { filter, tap } from 'rxjs/operators';
import { ConfirmCodeInterceptor } from './interceptors/confirm-code-interceptor';
import { JwtInterceptor } from './interceptors/jwt-interceptor';
import { DefaultHeadersInterceptor } from './interceptors/default-headers-interceptor';
import { CommonModule, registerLocaleData, ViewportScroller } from '@angular/common';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeRu, 'ru-RU', extraLocaleRu);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'myfi' }),
    AppRoutingModule,
    RouterModule,
    FontAwesomeModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
    SharedModule,
    ModalsModule,
    AccountModule,
    LandingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConfirmCodeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService, ActivatedRoute],
      useFactory: (auth: AuthService) => {
        const queryParams = new URLSearchParams(location.search);
        const authToken = queryParams.get('authToken');

        let authenticate$;

        if (authToken) {
          authenticate$ = auth.authenticateByToken(authToken).pipe(tap(() => {
            queryParams.delete('authToken');
            location.search = queryParams.toString();
          }));
        } else {
          authenticate$ = auth.checkAuthentication();
        }

        return () => authenticate$.toPromise();
      },
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private _auth: AuthService,
    private _viewportScroller: ViewportScroller,
    private _router: Router,
    private _modalConfig: NgbModalConfig
  ) {
    _modalConfig.centered = true;
    _modalConfig.size = 'lg';
    _modalConfig.beforeDismiss = () => false;

    _router.events.pipe(filter(event => event instanceof Scroll))
      .subscribe((event: Scroll) => {
        if (event.position) {
          _viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          _viewportScroller.scrollToAnchor(event.anchor);
        } else {
          _viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
