import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {
  supportEmail,
  supportPhone,
  supportPhoneFormat,
  personalDataAgreementFile
} from '../../../constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  supportPhone = supportPhone;
  supportPhoneFormat = supportPhoneFormat;
  supportEmail = supportEmail;
  personalDataAgreementFile = personalDataAgreementFile;
  year = new Date().getFullYear();

  constructor(public auth: AuthService) {
  }
}
