import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AnyClientRequestDetailed, AnyOffer, ClientServiceType, IOffer, IOfferLeasingData, } from '../../../types';
import { ClientRequest } from '../../../../models/client-request/client-request';
import { FactoringDetailed } from '../../../../models/client-request/factoring-detailed';
import { CreditDetailed } from '../../../../models/client-request/credit-detailed';
import { AnalyticService } from '../../../services/analytic.service';
import { guaranteeOfferStatusText } from '../../../constants';

const numericValidation = [Validators.required, Validators.min(0.0000000001), Validators.pattern(/^\d+(\.\d+)?$/)];
const percentValidation = [Validators.required, Validators.min(0.0000000001), Validators.max(100), Validators.pattern(/^\d+(\.\d+)?$/)];

const defaultDate = new Date(+new Date() + 1000 * 60 * 60 * 24 * 30); // now + 30 days

@Component({
  selector: 'app-request-content-factor',
  templateUrl: './request-content-factor.component.html',
  styleUrls: ['./request-content-factor.component.scss']
})
export class RequestContentFactorComponent implements OnInit {
  @Input() clientRequest: AnyClientRequestDetailed;
  @Input() offer: AnyOffer;

  @ViewChild('factoringFormTemplate')
  set factoringFormTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.FACTORING) {
      this.setFormTemplate(tmpl);
    }
  }

  @ViewChild('leasingFormTemplate')
  set leasingFormTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.LEASING) {
      this.setFormTemplate(tmpl);
    }
  }

  @ViewChild('creditFormTemplate')
  set creditFormTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.CREDIT) {
      this.setFormTemplate(tmpl);
    }
  }

  @ViewChild('guaranteeFormTemplate')
  set guaranteeFormTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.GUARANTEE) {
      this.setFormTemplate(tmpl);
    }
  }

  @ViewChild('factoringViewTemplate')
  set factoringViewTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.FACTORING) {
      this.setViewTemplate(tmpl);
    }
  }

  @ViewChild('leasingViewTemplate')
  set leasingViewTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.LEASING) {
      this.setViewTemplate(tmpl);
    }
  }

  @ViewChild('creditViewTemplate')
  set creditViewTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.CREDIT) {
      this.setViewTemplate(tmpl);
    }
  }

  @ViewChild('guaranteeViewTemplate')
  set guaranteeViewTemplate(tmpl: TemplateRef<any>) {
    if (this.clientRequest.service.type === ClientServiceType.GUARANTEE) {
      this.setViewTemplate(tmpl);
    }
  }

  iconCalendar = faCalendar;
  iconLoading = faCircleNotch;
  ClientServiceType = ClientServiceType;
  guaranteeOfferStatusText = guaranteeOfferStatusText;

  form: FormGroup;

  error = '';
  backendErrors = {};
  minDate = this._dateAdapter.fromModel(new Date(+new Date() + 1000 * 60 * 60 * 24));
  loading = false;
  inited = false;

  private _formTemplate: TemplateRef<any>;
  private _viewTemplate: TemplateRef<any>;

  constructor(
    private _auth: AuthService,
    private _fb: FormBuilder,
    private _router: Router,
    private _analytics: AnalyticService,
    private _dateAdapter: NgbDateAdapter<Date>,
    private _changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    switch (this.clientRequest.service.type) {
      case ClientServiceType.FACTORING:
        this.form = this._fb.group({
          finance_limit: ['', numericValidation],
          finance_percent: ['', percentValidation],
          rate: ['', percentValidation],
          validity: [defaultDate, [Validators.required]],
          commission_text: [''],
          additional_info: ['']
        });
        break;

      case ClientServiceType.LEASING:
      case ClientServiceType.CREDIT:
        this.form = this._fb.group({
          rate: ['', percentValidation],
          effective_rate: ['', percentValidation],
          validity: [defaultDate, [Validators.required]],
          commission_text: [''],
          additional_info: [''],
          payment_schedule_file: ['', [Validators.required]]
        });
        break;

      case ClientServiceType.GUARANTEE:
        this.form = this._fb.group({
          price_in_percent: ['', percentValidation],
          bankguarantee_status: ['', [Validators.required]],
          validity: [defaultDate, [Validators.required]],
          commission_text: [''],
          additional_info: ['']
        });
        break;
    }

    this.inited = true;
  }

  setFormTemplate(tmpl: TemplateRef<any>) {
    this._formTemplate = tmpl;
    this._changeDetector.detectChanges();
  }

  getFormTemplate() {
    return this._formTemplate;
  }

  setViewTemplate(tmpl: TemplateRef<any>) {
    this._viewTemplate = tmpl;
    this._changeDetector.detectChanges();
  }

  getViewTemplate() {
    return this._viewTemplate;
  }

  isFieldInvalid(form, name) {
    const field = form.get(name);
    return (field.touched && field.invalid) || this.backendErrors[name];
  }

  sendForm() {
    this.backendErrors = {};
    this.error = '';

    if (this.offer || this.form.invalid) {
      for (const controlsKey in this.form.controls) {
        if (this.form.controls.hasOwnProperty(controlsKey)) {
          this.form.controls[controlsKey].markAsTouched();
        }
      }
      return;
    }

    this._analytics.eventYandex( `requestFactor_submitResponse`, { params: { requestId: this.clientRequest.id } });

    const data: any = {};
    Object.assign(data, this.form.value);
    if (data.payment_schedule_file) {
      data.payment_schedule_file = (data as IOfferLeasingData).payment_schedule_file.id;
    }

    if (this.form.value.validity) {
      if (this.form.value.validity instanceof Date) {
        const date = this._dateAdapter.fromModel(this.form.value.validity);
        data.validity = `${date.year}-${date.month}-${date.day}`;
      } else if (typeof this.form.value.validity === 'object') {
        data.validity = `${this.form.value.validity.year}-${this.form.value.validity.month}-${this.form.value.validity.day}`;
      }
    }

    this.loading = true;
    this.error = '';

    (this.clientRequest as ClientRequest<IOffer>).newOffer(data)
      .subscribe(
        (offer: any) => {
          this.loading = false;
          this.offer = offer;
          this._analytics.eventGoogle('Sended', 'Offers', 'Bank', this.offer.id);
        },

        error => {
          this.loading = false;
          if (typeof this.backendErrors === 'object') {
            this.backendErrors = error.error;
            this.error = this.backendErrors['detail'] || 'Произошла ошибка. Проверьте форму и попробуйте ещё раз.';
          }
        }
      );
  }

  sendComplete() {
    this._analytics.eventYandex( `requestFactor_completeResponse`, { params: { requestId: this.clientRequest.id } });

    this.loading = true;
    (this.offer as IOffer).done().subscribe(
      () => {
        this.loading = false;
        this._analytics.eventGoogle('Funded', 'Offers', 'Bank', this.offer.id);
      },
      err => {
        console.log('[RequestContentUser] Error completing response: ', err);
        this.loading = false;
      }
    );
  }

  sendCancel() {
    this._analytics.eventYandex( `requestFactor_cancelResponse`, { params: { requestId: this.clientRequest.id } });

    this.loading = true;
    (this.offer as IOffer).cancel('Заявка отозвана').subscribe(
      () => {
        this.loading = false;
        this._analytics.eventGoogle('Canceled', 'Offers', 'Bank', this.offer.id);
      },
      err => {
        console.log('[RequestContentUser] Error canceling response: ', err);
        this.loading = false;
      }
    );
  }

  public getSum() {
    return (this.clientRequest as CreditDetailed).sum
      || (this.clientRequest as FactoringDetailed).contract_sum
      || 0;
  }
}
