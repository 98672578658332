import { Component, Input } from '@angular/core';
import { supportEmail, supportPhone, supportPhoneFormat } from '../../../constants';

@Component({
  selector: 'app-bg-text-contacts',
  templateUrl: './bg-text-contacts.component.html',
  styleUrls: ['./bg-text-contacts.component.scss']
})
export class BgTextContactsComponent {
  @Input() bg = '#FFFFFF';
  @Input() color = 'inherit';

  supportPhone = supportPhone;
  supportPhoneFormat = supportPhoneFormat;
  supportEmail = supportEmail;
}
