import { IClientRequest, IOffer, IOfferStatus, OfferStatus } from '../../app/types';
import { Observable } from 'rxjs';
import { AbstractUaHttpModel } from '../abstract-ua-http-model';
import { HttpClient } from '@angular/common/http';

export abstract class Offer<R extends IClientRequest> extends AbstractUaHttpModel implements IOffer {
  id: number;
  created_at: string;
  status: IOfferStatus;
  factor_done: boolean;
  client_done: boolean;
  commission_text: string;
  additional_info: string;
  validity: string;
  updated: boolean;

  abstract valueUnit: string;

  private static makeStatusObject(status: OfferStatus, cancelMessage?: string) {
    return {
      is_accepted: status === OfferStatus.ACCEPTED,
      is_rejected: status === OfferStatus.REJECTED,
      is_canceled: status === OfferStatus.CANCELED,
      is_financed: status === OfferStatus.FINANCED,
      cancel_message: cancelMessage || undefined
    };
  }

  constructor(http: HttpClient, data: IOffer) {
    super(http);
    Object.assign(this, data);
  }

  abstract getOfferValue(): number;
  abstract getRate(): number;

  getQueryUrl(): string {
    return `${super.getQueryUrl()}/offers`;
  }

  accept(): Observable<this> {
    return this._updateStatus(OfferStatus.ACCEPTED);
  }

  reject(): Observable<this> {
    return this._updateStatus(OfferStatus.REJECTED);
  }

  cancel(message?: string): Observable<this> {
    return this._updateStatus(OfferStatus.CANCELED, message);
  }

  done(): Observable<this> {
    return this._updateStatus(OfferStatus.FINANCED);
  }

  getStyle(): string {
    switch (this.status.code) {
      case OfferStatus.REJECTED:
        return 'danger';

      case OfferStatus.CANCELED:
        return 'dark';

      case OfferStatus.ACCEPTED:
        return 'primary';

      case OfferStatus.FINANCED:
        return 'success';
    }

    return 'no-style';
  }

  isPristine() {
    return this.status.code === OfferStatus.DEFAULT;
  }

  isAccepted() {
    return this.status.code === OfferStatus.ACCEPTED;
  }

  isCancelled() {
    return this.status.code === OfferStatus.CANCELED;
  }

  isRejected() {
    return this.status.code === OfferStatus.REJECTED;
  }

  isActive() {
    return !this.isCancelled() && !this.isRejected() && !this.isDone();
  }

  isDone() {
    return this.status.code === OfferStatus.FINANCED;
  }

  canAccept() {
    return this.isPristine();
  }

  canDone() {
    return !this.client_done && this.isAccepted() || this.isDone();
  }

  private _updateStatus(status: OfferStatus, cancelMessage?: string) {
    return this._handleUpdate(this._http.patch<this>(this.getEntryUrl(), Offer.makeStatusObject(status, cancelMessage)));
  }
}
