import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageProfileComponent } from './page-profile/page-profile.component';
import { PageRequestCreateComponent } from './page-request-create/page-request-create.component';
import { PageRequestComponent } from './page-request/page-request.component';
import { AccountComponent } from './account.component';
import { SharedModule } from '../shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxFilesizeModule as FileSizeModule } from 'ngx-filesize';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { SepthousandPipe } from '../shared/pipes/septhousand.pipe';
import { FactoringComponent } from './page-request-create/factoring/factoring.component';
import { LeasingComponent } from './page-request-create/leasing/leasing.component';
import { CreditComponent } from './page-request-create/credit/credit.component';
import { GuaranteeComponent } from './page-request-create/guarantee/guarantee.component';
import { PageRequestsModule } from './page-requests/page-requests.module';
import { CRManagerBuilderService } from '../services/cr-manager-builder.service';
import { ClientServiceType, FactoringResource } from '../types';
import { PageDocumentsModule } from './page-documents/page-documents.module';

const getResourceFactory = (type: ClientServiceType) => {
  return (crManagerBuilder: CRManagerBuilderService) => {
    return () => crManagerBuilder.getByServiceType(type);
  };
};

@NgModule({
  declarations: [
    PageProfileComponent,
    PageRequestCreateComponent,
    PageRequestComponent,
    AccountComponent,
    FactoringComponent,
    LeasingComponent,
    CreditComponent,
    GuaranteeComponent
  ],
  imports: [
    SharedModule,
    AccountRoutingModule,
    PageDocumentsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    FileSizeModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    PageRequestsModule,
    NgbDropdownModule,
    NgbTooltipModule
  ],
  providers: [
    SepthousandPipe,
    DatePipe,
    {
      provide: 'FactoringResource',
      useFactory: getResourceFactory(ClientServiceType.FACTORING),
      deps: [ CRManagerBuilderService ]
    },
    {
      provide: 'LeasingResource',
      useFactory: getResourceFactory(ClientServiceType.LEASING),
      deps: [ CRManagerBuilderService ]
    },
    {
      provide: 'CreditResource',
      useFactory: getResourceFactory(ClientServiceType.CREDIT),
      deps: [ CRManagerBuilderService ]
    },
    {
      provide: 'GuaranteeResource',
      useFactory: getResourceFactory(ClientServiceType.GUARANTEE),
      deps: [ CRManagerBuilderService ]
    }
  ]
})
export class AccountModule { }
