import { EventEmitter, Injectable } from '@angular/core';
import { CRManagerBuilderService } from './cr-manager-builder.service';
import { catchError, concatMap, filter, map } from 'rxjs/operators';
import { AnyClientRequest, AnyOffer, IDataPage } from '../types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './auth.service';
import { RequestCloseModalComponent } from '../modals/request-close-modal/request-close-modal.component';
import { Factoring } from '../../models/client-request/factoring';
import { FactoringDetailed } from '../../models/client-request/factoring-detailed';
import { LeasingDetailed } from '../../models/client-request/leasing-detailed';
import { concat, Observable, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class ClosedRequestsService {
  onClose = new EventEmitter<void>(true);
  private _queue = new Subject<[AnyClientRequest, AnyOffer]>();

  constructor(
    private _builder: CRManagerBuilderService,
    private _modal: NgbModal,
    private _auth: AuthService
  ) {
    this._queue.pipe(concatMap(result => this._open(...result))).subscribe();
  }

  performCheck() {
    const isUser = !this._auth.authenticatedUser().isFactor();
    const pageOptions = { draft: false, archive: false, page_size: 100 };

    const factoring$ = this._builder.getFactoring().page(pageOptions);
    const leasing$ = this._builder.getLeasing().page(pageOptions);
    const credit$ = this._builder.getCredit().page(pageOptions);

    // Load all user's requests and check it for reminders. Add popup to queue if overdue found.
    concat<IDataPage<AnyClientRequest>>(factoring$, leasing$, credit$).pipe(
      concatMap(({data}) => data.filter(request => request.isReminderOverdue())), // filter overdue
      concatMap(request => request.loadOffers().pipe( // get offers for every one
        concatMap(offers => offers), // flatten array of offers
        filter(offer => { // find offers that needs to remind
          const showToUser = isUser && offer.factor_done && !offer.client_done; // if user, check for factor is done
          const showToFactor = !isUser && !offer.factor_done && offer.client_done; // if factor, check for user is done
          return showToUser || showToFactor;
        }),
        map(offer => [request, offer])) // return result for each passed offer
      )
    ).subscribe((result: [AnyClientRequest, AnyOffer]) => {
      this._queue.next(result);
    });
  }

  private _open(request, offer): Observable<[AnyClientRequest, AnyOffer]> {
    const isUser = !this._auth.authenticatedUser().isFactor();
    const modal = this._modal.open(RequestCloseModalComponent, { size: 'lg', beforeDismiss: () => false });

    modal.componentInstance.request = request;
    modal.componentInstance.offer = offer;
    modal.componentInstance.isUser = isUser;

    if (isUser) {
      modal.componentInstance.message = 'Вы получили финансирование по заявке?';
    } else {
      modal.componentInstance.message = 'Вы выдали финансирование?';
    }

    if (request instanceof Factoring) {
      modal.componentInstance.sum = (request as FactoringDetailed).contract_sum;
    } else {
      modal.componentInstance.sum = (request as LeasingDetailed).sum; // same for Credit type
    }

    return fromPromise(modal.result).pipe(
      catchError(err => err), // catch but ignore popup dismiss
    );
  }
}
