<div class="header-menu">
  <div class="container">
    <ul role="menubar">
      <li><a role="menuitem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/"><img src="/assets/svg/home.svg"></a></li>
      <li>
        <a role="menuitem" routerLinkActive="active" routerLink="/services" (click)="$event.preventDefault()">Услуги</a>
        <ul>
          <li>
            <a routerLink="/services/factoring">Факторинг</a>
            <ul>
              <li><a routerLink="/services/factoring/recourse">Факторинг с регрессом</a></li>
              <li><a routerLink="/services/factoring/export">Экспортный факторинг</a></li>
              <li><a routerLink="/services/factoring/no-recourse">Факторинг без регресса</a></li>
            </ul>
          </li>
          <li>
            <a routerLink="/services/revenue-financing">Финансирование выручки</a>
            <ul>
              <li><a routerLink="/services/revenue-financing/contract">Финансирование под выручку от контрактов</a></li>
              <li><a routerLink="/services/revenue-financing/credit-card">Финансирование под выручку от платёжных карт</a></li>
            </ul>
          </li>
          <li>
            <a routerLink="/services/purchase">Финансирование закупок</a>
            <ul>
              <li><a routerLink="/services/purchase/purchasing">Закупочный факторинг</a></li>
              <li><a routerLink="/services/purchase/import">Импортный факторинг</a></li>
            </ul>
          </li>
          <li>
            <a routerLink="/services/micro">MicroMyfi</a>
            <ul>
              <li><a routerLink="/services/micro/factoring">Микрофакторинг</a></li>
            </ul>
          </li>
          <li>
            <a routerLink="/services/leasing">Лизинг</a>
            <ul>
              <li><a routerLink="/services/leasing/facility">Лизинг оборудования</a></li>
              <li><a routerLink="/services/leasing/vehicle">Лизинг автомобилей/техники</a></li>
            </ul>
          </li>
          <li>
            <a routerLink="/services/state">Факторинг госзакупок</a>
            <ul>
              <li><a routerLink="/services/state/fz44">В рамках 44 ФЗ</a></li>
              <li><a routerLink="/services/state/fz223">В рамках 223 ФЗ</a></li>
            </ul>
          </li>
          <li>
            <a routerLink="/services/credit">Кредитование</a>
          </li>
        </ul>
      </li>
      <li><a role="menuitem" routerLinkActive="active" routerLink="/about">О нас</a></li>
      <li><a role="menuitem" routerLinkActive="active" routerLink="/faq">FAQ</a></li>
      <li><a role="menuitem" routerLinkActive="active" routerLink="/partners">Партнёры</a></li>
      <li><a role="menuitem" routerLinkActive="active" routerLink="/contacts">Контакты</a></li>
    </ul>
  </div>
</div>
