import { IScoringValue } from '../../types';
import { HttpClient } from '@angular/common/http';
import { ScoringService } from '../../services/scoring.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

export class ScoringTableData {
  collapsed = true;
  isLoading = false;
  error = null;

  private _data: IScoringValue[] = [];

  constructor(
    private _http: HttpClient,
    private _scoring: ScoringService
  ) {}

  reset() {
    this._data = [];
    this.isLoading = false;
    this.error = null;
  }

  data() {
    return this._data;
  }

  load({inn, sum, period}) {
    this.isLoading = true;
    this.error = null;
    this.collapsed = false;

    return this._scoring
      .get(inn, sum, period)
      .pipe(
        tap(value => {
          this._data = value;
          this.isLoading = false;
        }),
        catchError(error => {
          console.log('Inn scoring error: ', error);
          this.error = error;
          this.isLoading = false;
          return throwError(error);
        })
      );
  }
}
