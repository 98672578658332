import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../../../user';

@Component({
  selector: 'app-sign-up-widget-form',
  templateUrl: './sign-up-widget-form.component.html',
  styleUrls: ['./sign-up-widget-form.component.scss']
})
export class SignUpWidgetFormComponent implements OnDestroy {
  @Input() type: 'factoring' | 'leasing' | 'credit' = 'factoring';

  authenticated = false;
  isFactor = false;
  updateSub: Subscription;

  constructor(private _auth: AuthService, private _detector: ChangeDetectorRef, private _router: Router) {
    this._checkAuth();
    this.updateSub = this._auth.onUpdate().subscribe(() => this._checkAuth());
  }

  ngOnDestroy(): void {
    if (this.updateSub) {
      this.updateSub.unsubscribe();
    }
  }

  private _checkAuth() {
    if (this._auth.isAuthenticated()) {
      const user = this._auth.authenticatedUser();
      this.authenticated = user && !!user.id;
      this.isFactor = user.isFactor();
    } else {
      this.authenticated = false;
      this.isFactor = false;
    }
  }
}
