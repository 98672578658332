<ng-template #spinner>
  <fa-icon [icon]="iconLoading" [spin]="true" size="2x"></fa-icon>
</ng-template>

<ng-template #noRequests>
  <p>Нет ни одной заявки в категории "{{ serviceTypeName }}"</p>
  <a class="btn btn-secondary btn-lg" [routerLink]="['create']" *ngIf="isUser && !archive">Создать!</a>
</ng-template>

<div class="container pb-4">
  <app-requests-cards [resource]="clientRequestResource" [archive]="archive"></app-requests-cards>
</div>

<app-consult-block></app-consult-block>
