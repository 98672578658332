import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultModalComponent } from './consult-modal/consult-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SignInModalComponent } from './sign-in-modal/sign-in-modal.component';
import { SignUpModalComponent } from './sign-up-modal/sign-up-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ManualModalComponent } from './manual-modal/manual-modal.component';
import { PhoneConfirmModalComponent } from './phone-confirm-modal/phone-confirm-modal.component';
import { CodeConfirmModalComponent } from './code-confirm-modal/code-confirm-modal.component';
import { PasswordResetModalComponent } from './password-reset-modal/password-reset-modal.component';
import { PasswordRequestModalComponent } from './password-request-modal/password-request-modal.component';
import { RequestCloseModalComponent } from './request-close-modal/request-close-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ConsultModalComponent,
    SignInModalComponent,
    SignUpModalComponent,
    ConfirmModalComponent,
    ManualModalComponent,
    PhoneConfirmModalComponent,
    CodeConfirmModalComponent,
    PasswordResetModalComponent,
    PasswordRequestModalComponent,
    RequestCloseModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    AuthService
  ]
})
export class ModalsModule { }
