export class FakeSsrStorage implements Storage {
  private _storage: Map<string, string> = new Map();

  get length(): number {
    return this._storage.size;
  }

  clear(): void {
    this._storage.clear();
  }

  getItem(key: string): string | null {
    return this._storage.get(key);
  }

  key(index: number): string | null {
    let idx = 0;
    for (const key in this._storage) {
      if (index === idx++) {
        return key;
      }
    }
  }

  removeItem(key: string): void {
    this._storage.get(key);
  }

  setItem(key: string, value: string): void {
    this._storage.set(key, value);
  }
}
