import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AnalyticService } from '../../services/analytic.service';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-password-reset-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: ['./password-reset-modal.component.scss']
})
export class PasswordResetModalComponent implements OnInit {
  faCircleNotch = faCircleNotch;
  form: FormGroup = null;

  token: string;
  id: string;

  error: string;
  message: string;
  loading = false;

  errorMatcher: ErrorStateMatcher = {
    isErrorState(control: FormControl, form: FormGroup | NgForm | FormGroupDirective): boolean {
      return control.touched && (form.errors?.match || control.errors?.required);
    }
  };

  constructor(
    public activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _modal: NgbModal,
    private _http: HttpClient,
    private _router: Router,
    private _route: ActivatedRoute,
    private _analytics: AnalyticService
  ) {
    this._analytics.eventYandex('passwordResetModal_open');
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/^[A-Za-z0-9!@#$%^&*_=]*$/)]],
      password_repeat: ['', [Validators.required]]
    }, {
      validators: [
        group => {
          if (group.get('password').value !== group.get('password_repeat').value) {
            return { match: true };
          }

          return null;
        }
      ]
    });
  }

  submit() {
    this._analytics.eventYandex('signInModal_submit');

    this.loading = true;
    this.error = null;

    this._http.post(`${environment.apiEndpoint}/auth/change-password/${this.id}/${this.token}`, {
      password: this.form.get('password').value
    }).subscribe({
      next: value => {
        this.loading = false;
        this.activeModal.close('Reset done');
        this._router.navigate(['account', 'factoring'], { queryParams: {} });
      },
      error: err => {
        this.loading = false;
        this.error = err.error.detail;
      }
    });
  }

  close(): void {
    this.activeModal.close('Close button click');
  }
}
