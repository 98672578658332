import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { from, Observable } from 'rxjs';
import { ConfirmResults } from '../confirm-results.enum';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  constructor(private _modal: NgbModal) { }

  open({
    message,
    title = 'Вы уверены?',
    confirmText = 'Да',
    dismissText = 'Нет',
    modalOptions = {} as NgbModalOptions
  }): Observable<ConfirmResults> {
    modalOptions = Object.assign({ beforeDismiss: null }, modalOptions);

    if (this._modal.hasOpenModals()) {
      this._modal.dismissAll();
    }

    const modal = this._modal.open(ConfirmModalComponent, modalOptions);
    Object.assign(modal.componentInstance, {message, title, confirmText, dismissText});

    return from<Promise<ConfirmResults>>(modal.result);
  }
}
