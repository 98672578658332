import { ClientRequest } from './client-request';
import { GuaranteeFZ, IGuarantee, IOfferGuarantee } from '../../app/types';
import { OfferGuarantee } from '../offer/offer-guarantee';

export class Guarantee extends ClientRequest<IOfferGuarantee> implements IGuarantee {
  fz: GuaranteeFZ;
  limitation: number;
  sum: number;
  competition_url: string;

  protected _uaEndpoint = 'bank-guarantee';

  getTerm() {
    return this.limitation;
  }

  getSum() {
    return this.sum;
  }

  protected _castOffer(data: IOfferGuarantee): IOfferGuarantee {
    return new OfferGuarantee(this._http, data);
  }
}
