import {
  ArticleType, GuaranteeOfferStatus,
  ClientServiceType, GuaranteeFZ, IClientRequest,
  IClientRequestDetailed
} from './types';
import { Factoring } from '../models/client-request/factoring';
import { FactoringDetailed } from '../models/client-request/factoring-detailed';
import { LeasingDetailed } from '../models/client-request/leasing-detailed';
import { Leasing } from '../models/client-request/leasing';
import { Credit } from '../models/client-request/credit';
import { CreditDetailed } from '../models/client-request/credit-detailed';
import { Guarantee } from '../models/client-request/guarantee';
import { GuaranteeDetailed } from '../models/client-request/guarantee-detailed';

export const API_VERSION = '2.4.0';

export const MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
export const PHONE_REGEX = /^\+?([87](?!95[5-79]|99[08]|907|94[^0]|336)([348]\d|9[0-6789]|7[01247])\d{8}|[1246]\d{9,13}|68\d{7}|5[1-46-9]\d{8,12}|55[1-9]\d{9}|55[138]\d{10}|55[1256][14679]9\d{8}|554399\d{7}|500[56]\d{4}|5016\d{6}|5068\d{7}|502[45]\d{7}|5037\d{7}|50[4567]\d{8}|50855\d{4}|509[34]\d{7}|376\d{6}|855\d{8,9}|856\d{10}|85[0-4789]\d{8,10}|8[68]\d{10,11}|8[14]\d{10}|82\d{9,10}|852\d{8}|90\d{10}|96(0[79]|17[0189]|181|13)\d{6}|96[23]\d{9}|964\d{10}|96(5[569]|89)\d{7}|96(65|77)\d{8}|92[023]\d{9}|91[1879]\d{9}|9[34]7\d{8}|959\d{7,9}|989\d{9}|971\d{8,9}|97[02-9]\d{7,11}|99[^4568]\d{7, 11}|994\d{9}|9955\d{8}|996[2579]\d{8}|9989\d{8}|380[345679]\d{8}|381\d{9}|38[57]\d{8,9}|375[234]\d{8}|372\d{7,8}|37[0-4]\d{8}|37[6-9]\d{7,11}|30[69]\d{9}|34[67]\d{8}|3459\d{11}|3[12359]\d{8,12}|36\d{9}|38[169]\d{8}|382\d{8,9}|46719\d{10})$/;
export const SMS_CODE_REGEX = /^\d{6}$/;
export const VAT_FORMAT_REGEX = /^\d{10,12}$/;
export const SMS_TIMEOUT_SEC = 30;
export const BASE_TITLE = 'MyFi — Доступные финансы бизнесу';

export const supportPhone = '+74951447717';
export const supportPhoneFormat = '+7 (495) 144-77-17';

export const supportEmail = 'info@myfi24.ru';
export const personalDataAgreementFile = 'https://docs.myfi24.ru/personal-data-agreement.pdf';

export const enrichmentMethodList = {
  req: 'Актуализация реквизитов',
  contacts: 'Контакты из Контур.Справочника',
  analytics: 'Расширенная аналитика',
  egrDetails: 'Расширенные сведения на основе ЕГРЮЛ/ЕГРИП',
  licences: 'Информация о лицензиях',
  buh: 'Показатели бухгалтерской отчетности',
  taxes: 'Уплаченные налоги и сборы',
  fssp: 'Исполнительные производства',
  fsa: 'Сертификаты и декларации соответствия',
  govPurchasesOfCustomer: 'Госзакупки заказчика',
  govPurchasesOfParticipant : 'Госзакупки участника',
  beneficialOwners: 'Конечные бенефициары',
  bankGuarantees: 'Банковские гарантии',
  bankAccounts: 'Банки, в которых ранее были найдены счета организации',
  finanValues: 'Автоматический финансовый анализ',
  // foreignRepresentatives: 'Иностранные представительства и филиалы',
  fnsBlockedBankAccounts: 'Решения налоговых органов о приостановлении операций по счетам',
  inspections: 'Плановые и внеплановые проверки',
  lessee: 'Договоры лизинга',
  petitionersOfArbitration: 'Статистика по истцам в арбитраже',
  pledger: 'Залоги движимого имущества со стороны залогодателя',
  sites: 'Сайты компаний',
  trademarks: 'Товарные знаки'
};

interface ServiceTypeClassStructure {
  basic: new (...args: any[]) => IClientRequest;
  detailed: new (...args: any[]) => IClientRequestDetailed;
}

export const serviceTypeClass: {[k: number]: ServiceTypeClassStructure} = {
  [ClientServiceType.FACTORING]: { basic: Factoring, detailed: FactoringDetailed },
  [ClientServiceType.LEASING]: { basic: Leasing, detailed: LeasingDetailed },
  [ClientServiceType.CREDIT]: { basic: Credit, detailed: CreditDetailed },
  [ClientServiceType.GUARANTEE]: { basic: Guarantee, detailed: GuaranteeDetailed },
};

export const serviceTypeNames = {
  [ClientServiceType.FACTORING]: 'факторинг',
  [ClientServiceType.LEASING]: 'лизинг',
  [ClientServiceType.CREDIT]: 'кредит',
  [ClientServiceType.GUARANTEE]: 'банковская гарантия',
};

export const serviceTypeRoutes = {
  [ClientServiceType.FACTORING]: 'factoring',
  [ClientServiceType.LEASING]: 'leasing',
  [ClientServiceType.CREDIT]: 'credit',
  [ClientServiceType.GUARANTEE]: 'bank-guarantee',
};

export const guaranteeOfferStatusText = {
  [GuaranteeOfferStatus.PRE_APPROVED]: 'Предварительно одобрено',
  [GuaranteeOfferStatus.APPROVED]: 'Одобрено',
};

export const creditTargets = {
  1: {
    label: 'Инвестиции на приобретение недвижимости',
    term_limit: 120
  },
  2: {
    label: 'Пополнение оборотных средств',
    term_limit: 36
  },
  3: {
    label: 'Иные инвестиционные цели',
    term_limit: 84
  }
};

export const creditTypes = {
  1: 'Мерчинг',
  2: 'Льготный кредит',
  3: 'Кредит'
};

export const leasingPayTypes = {
  1: 'Равный',
  2: 'Убывающий',
  3: 'Сезонный'
};

export const guaranteeFzName = {
  [GuaranteeFZ.FZ44]: '44-ФЗ',
  [GuaranteeFZ.FZ223]: '223-ФЗ',
  [GuaranteeFZ.OTHER]: 'Другое'
};

export const articleTypeUrls = {
  [ArticleType.digital]: '/digital-services',
  [ArticleType.additional]: '/additional-services'
};

export const httpDefaultHeaders = {
  'Accept-Language': 'ru',
  'Accept': `application/json; version=${API_VERSION}`
};

export const MIN_CONTRACT_SUM = 100_000;
export const MAX_CONTRACT_SUM = 10_000_000_000;
