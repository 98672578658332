import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { CommonOptions, NgxMetrikaService } from '@kolkov/ngx-metrika';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { User } from '../user';

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {
  private _user: User;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _yandex: NgxMetrikaService,
    private _google: GoogleAnalyticsService
  ) {
    this._setUser(_auth.authenticatedUser());
    _auth.onUpdate().subscribe(user => this._setUser(user));
  }
  eventYandex(target: string, yaOptions: CommonOptions = {}) {
    const yaParams = {
      user_id: this._getUserId(),
      url: this._router.url
    };

    const options = Object.assign({}, yaOptions, {
      params: Object.assign({}, yaOptions.params || {}, yaParams)
    });

    this._yandex.reachGoal.next({ target, options });
  }

  eventGoogle(action, category?, label?, value?) {
    this._google.event(action, category, label, value);
  }

  private _setUser(user: User) {
    this._user = user;
    this._google.set({ user_id: this._getUserId() });
  }

  private _getUserId() {
    return (this._user && this._user.id) ? this._user.id : null;
  }
}
