import { Component, Input, OnInit } from '@angular/core';
import { ClientServiceType, IClientRequest } from '../../../types';
import { PluralizeRuPipe } from '../../../shared/pipes/pluralize-ru.pipe';
import { isEmpty } from '../../../helpers';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss']
})
export class RequestCardComponent implements OnInit {
  @Input() request: IClientRequest;

  constructor(private _pluralize: PluralizeRuPipe) { }

  ngOnInit(): void {
  }

  getTerm(): string {
    const term = this.request.getTerm();

    if (isEmpty(term)) {
      return '--';
    }

    switch (this.request.service.type) {
      case ClientServiceType.GUARANTEE:
      case ClientServiceType.FACTORING:
        return this._pluralize.transform(term, '# день', '# дня', '# дней');

      case ClientServiceType.LEASING:
      case ClientServiceType.CREDIT:
        return term + ' мес.';
    }
  }
}
