import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageRequestsComponent } from './page-requests/page-requests.component';
import { PageProfileComponent } from './page-profile/page-profile.component';
import { PageDocumentsComponent } from './page-documents/page-documents.component';
import { PageRequestComponent } from './page-request/page-request.component';
import { AccountComponent } from './account.component';
import { AuthGuard } from '../auth.guard';
import { PageRequestCreateComponent } from './page-request-create/page-request-create.component';
import { IsUserGuard } from './is-user.guard';
import { ClientRequestResolver } from './client-request-resolver.service';
import { ClientServiceType } from '../types';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    canActivateChild: [AuthGuard],
    resolve: {user: AuthGuard},
    children: [
      {
        path: '',
        component: PageProfileComponent
      },

      // FACTORING
      {
        path: 'factoring',
        component: PageRequestsComponent,
        resolve: { resource: 'FactoringResource' }
      },
      {
        path: 'factoring/archive',
        component: PageRequestsComponent,
        resolve: { resource: 'FactoringResource' },
        data: { archive: true }
      },
      {
        path: 'factoring/create',
        component: PageRequestCreateComponent,
        data: { serviceType: ClientServiceType.FACTORING },
        canActivate: [IsUserGuard]
      },
      {
        path: 'factoring/:requestId',
        component: PageRequestComponent,
        resolve: { factorRequest: ClientRequestResolver },
        data: { serviceType: ClientServiceType.FACTORING }
      },
      {
        path: 'factoring/:requestId/edit',
        component: PageRequestCreateComponent,
        resolve: { factorRequest: ClientRequestResolver },
        data: { serviceType: ClientServiceType.FACTORING },
        canActivate: [ IsUserGuard ]
      },

      // LEASING
      {
        path: 'leasing',
        component: PageRequestsComponent,
        resolve: { resource: 'LeasingResource' }
      },
      {
        path: 'leasing/archive',
        component: PageRequestsComponent,
        resolve: { resource: 'LeasingResource' },
        data: { archive: true }
      },
      {
        path: 'leasing/create',
        component: PageRequestCreateComponent,
        data: { serviceType: ClientServiceType.LEASING },
        canActivate: [IsUserGuard]
      },
      {
        path: 'leasing/:requestId',
        component: PageRequestComponent,
        data: { serviceType: ClientServiceType.LEASING },
        resolve: { factorRequest: ClientRequestResolver }
      },
      {
        path: 'leasing/:requestId/edit',
        component: PageRequestCreateComponent,
        data: { serviceType: ClientServiceType.LEASING },
        resolve: { factorRequest: ClientRequestResolver },
        canActivate: [IsUserGuard]
      },

      // CREDIT
      {
        path: 'credit',
        component: PageRequestsComponent,
        resolve: { resource: 'CreditResource' }
      },
      {
        path: 'credit/archive',
        component: PageRequestsComponent,
        resolve: { resource: 'CreditResource' },
        data: { archive: true }
      },
      {
        path: 'credit/create',
        component: PageRequestCreateComponent,
        data: { serviceType: ClientServiceType.CREDIT },
        canActivate: [IsUserGuard]
      },
      {
        path: 'credit/:requestId',
        component: PageRequestComponent,
        data: { serviceType: ClientServiceType.CREDIT },
        resolve: { factorRequest: ClientRequestResolver }
      },
      {
        path: 'credit/:requestId/edit',
        component: PageRequestCreateComponent,
        data: { serviceType: ClientServiceType.CREDIT },
        resolve: { factorRequest: ClientRequestResolver },
        canActivate: [IsUserGuard]
      },

      // BANK GUARANTEE
      {
        path: 'bank-guarantee',
        component: PageRequestsComponent,
        resolve: { resource: 'GuaranteeResource' }
      },
      {
        path: 'bank-guarantee/archive',
        component: PageRequestsComponent,
        resolve: { resource: 'GuaranteeResource' },
        data: { archive: true }
      },
      {
        path: 'bank-guarantee/create',
        component: PageRequestCreateComponent,
        data: { serviceType: ClientServiceType.GUARANTEE },
        canActivate: [IsUserGuard]
      },
      {
        path: 'bank-guarantee/:requestId',
        component: PageRequestComponent,
        data: { serviceType: ClientServiceType.GUARANTEE },
        resolve: { factorRequest: ClientRequestResolver }
      },
      {
        path: 'bank-guarantee/:requestId/edit',
        component: PageRequestCreateComponent,
        data: { serviceType: ClientServiceType.GUARANTEE },
        resolve: { factorRequest: ClientRequestResolver },
        canActivate: [IsUserGuard]
      },

      // DOCUMENTS
      {
        path: 'documents',
        component: PageDocumentsComponent,
        canActivate: [IsUserGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    ClientRequestResolver
  ]
})
export class AccountRoutingModule {}
