<div class="wrapper wrapper--top" *ngIf="!this.loading">
  <div class="container top-bar">
    <div class="top-bar-section">
      <div class="top-bar-section__link">
        <a routerLink="/landing" class="d-flex align-items-center">
          <fa-icon [icon]="iconBack" size="2x"></fa-icon>
          <span class="ml-3">Вернуться<br />назад</span>
        </a>
      </div>
    </div>
    <div class="top-bar-section flex-grow-1">
      <div class="top-bar-section__label">
        <h3 class="mb-0">{{ requestFormService.selected.label }}</h3>
        <a href="javascript:" class="text-muted" [ngbPopover]="">Что это?</a>
      </div>
    </div>
    <div class="top-bar-section">
      <div class="top-bar-section__rate text-muted">
        От <span class="mx-2">{{ requestFormService.selected.rate }}%</span> годовых
      </div>
    </div>
    <div class="top-bar-section top-bar-section__email">
      <div class="top-bar-section__email">
        <div class="text-muted">Почта для предложений</div>
        <div>{{ calcForm.get('email').value }}</div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper pb-5">
  <h4 class="pt-5 text-center" *ngIf="!service">Loading...</h4>
  <div class="container pt-5" *ngIf="!phoneCheckStage && service">
    <h2 class="mb-5">Оформить заявку</h2>

    <form [formGroup]="form" (submit)="submit()">
      <div class="row form-group">
        <label class="col-5 pt-3">ИНН вашей компании</label>
        <div class="col-5">
          <input class="form-control" formControlName="inn" [class.is-invalid]="form.get('inn').touched && form.get('inn').invalid" />
          <div class="invalid-feedback" *ngIf="form.get('inn').invalid && form.get('inn').errors?.required">Введите ИНН</div>
          <div class="invalid-feedback" *ngIf="form.get('inn').invalid && form.get('inn').errors?.pattern">Недопустимый ИНН</div>
        </div>
      </div>
      <div class="row form-group" *ngIf="!service.hide_second_inn">
        <label class="col-5 pt-3">ИНН компании контагента</label>
        <div class="col-5">
          <input class="form-control" formControlName="inn_second" [class.is-invalid]="form.get('inn_second').touched && form.get('inn_second').invalid" />
          <div class="invalid-feedback" *ngIf="form.get('inn_second').invalid && form.get('inn_second').errors?.required">Введите ИНН</div>
          <div class="invalid-feedback" *ngIf="form.get('inn_second').invalid && form.get('inn_second').errors?.pattern">Недопустимый ИНН</div>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-5 pt-3">Сумма контракта, рублей</label>
        <div class="col-2">
          <input appFormat formatType="float" [separate]="true" class="form-control" formControlName="contract_sum" readonly />
        </div>
        <div class="col back-link pt-2">
          <a routerLink="/landing">
            <img src="/assets/svg/edit-icon.svg" />Изменить
          </a>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-5 pt-3">Оставшйся срок контракта, дней</label>
        <div class="col-2">
          <input appFormat formatType="integer" class="form-control" formControlName="contract_period" readonly />
        </div>
        <div class="col back-link pt-2">
          <a routerLink="/landing">
            <img src="/assets/svg/edit-icon.svg" />Изменить
          </a>
        </div>
      </div>

      <div class="row mb-5">
        <div class="offset-5 col">
          <button type="submit" class="btn btn-primary btn-lg px-5" [disabled]="form.invalid || loading">Подать заявку</button>
        </div>
      </div>
    </form>
  </div>


  <div class="container pt-5 text-center" *ngIf="phoneCheckStage">
    <h2 class="mb-5 text-left">Оформить заявку</h2>

    <div class="row pt-5">
      <div class="col-4 offset-4">
        <img class="mb-4" src="/assets/svg/landing/validate-phone.svg">

        <h3 class="mb-4">Подтвердите ваш номер телефона</h3>
        <p class="mb-5">Для продолжения работы с Myfi вам нужно указать и подтвердить ваш номер телефона</p>

        <p class="text-left">Номер телефона</p>
        <form (submit)="submitPhone()">
          <input
            class="form-control mb-3 w-100"
            [formControl]="phoneControl"
            [class.is-invalid]="phoneControl.touched && phoneControl.invalid"
          />
          <div class="invalid-feedback" *ngIf="phoneControl.errors?.required">Это обязательное поле</div>
          <div class="invalid-feedback" *ngIf="phoneControl.errors?.pattern">Неверный формат номера</div>
          <ng-container *ngIf="phoneControl.errors?.backend">
            <div class="invalid-feedback" *ngFor="let error of phoneControl.errors.backend">{{ error }}</div>
          </ng-container>
          <button
            type="submit"
            class="btn btn-primary btn-lg btn-block mt-3"
            [disabled]="!phoneControl.valid || loading"
          >Получить SMS-код подтверждения</button>

          <p class="text-danger mt-2" *ngIf="errors.detail || errors.phone">
            {{ errors.detail || errors.phone }}
          </p>
        </form>
      </div>
    </div>
  </div>
</div>
