import { Component, Input, OnInit } from '@angular/core';
import { SepthousandPipe } from '../../pipes/septhousand.pipe';
import { IScoringValue } from '../../../types';

@Component({
  selector: 'app-scoring-table',
  templateUrl: './scoring-table.component.html',
  styleUrls: ['./scoring-table.component.scss']
})
export class ScoringTableComponent implements OnInit {
  @Input() data: IScoringValue[];

  constructor(private _sep: SepthousandPipe) { }

  ngOnInit() {
  }

  readableValue(value: any) {
    switch (typeof value) {
      case 'boolean': return value ? 'Да' : 'Нет';
      case 'object': return '-';
      case 'undefined': return '-';
      case 'number': return this._sep.transform(value, 0);
      default: return value;
    }
  }
}
