<div class="howitworks-container">
  <div class="howitworks-card">
    <img src="/assets/svg/index-cards/howitworks1.svg" class="howitworks-card__image"/>
    <p class="howitworks-card__text">Нужны деньги?</p>
  </div>
  <div class="howitworks-arrow"><span>Посчитать</span></div>
  <div class="howitworks-card">
    <img src="/assets/svg/index-cards/howitworks2.svg" class="howitworks-card__image"/>
    <p class="howitworks-card__text">Калькулятор</p>
  </div>
  <div class="howitworks-arrow">
    <span>Заполнить</span>
  </div>
  <div class="howitworks-card">
    <img src="/assets/svg/index-cards/howitworks3.svg" class="howitworks-card__image"/>
    <p class="howitworks-card__text">Анкета</p>
  </div>
  <div class="howitworks-arrow">
    <span>Отправить на MyFi</span>
  </div>
  <div class="howitworks-card">
    <img src="/assets/svg/index-cards/howitworks4.svg" class="howitworks-card__image"/>
    <p class="howitworks-card__text">Заявка</p>
  </div>
</div>

<div class="howitworks-arrow-down">
  <div class="howitworks-arrow-down__line howitworks-arrow-down__line--right-bottom">
    <span class="label">Посмотреть</span>
  </div>
  <div class="howitworks-arrow-down__line howitworks-arrow-down__line--left"></div>
</div>

<div class="howitworks-container">
  <table class="howitworks-card" border="1">
    <tr>
      <td>
        <img src="/assets/svg/index-cards/money-bag.svg"/>
        Фактор 1
      </td>
      <td>
        <img src="/assets/svg/index-cards/bank-building.svg"/>
        Банк 1
      </td>
    </tr>
    <tr>
      <td>
        <img src="/assets/svg/index-cards/money-bag.svg"/>
        Фактор N
      </td>
      <td>
        <img src="/assets/svg/index-cards/bank-building.svg"/>
        Банк N
      </td>
    </tr>
  </table>
  <div class="howitworks-arrow">
    <span>Получили</span>
  </div>
  <ul class="howitworks-card">
    <li>Предложение 1</li>
    <li>Предложение 2</li>
    <li>Предложение 3</li>
    <li>Предложение 4</li>
  </ul>
  <div class="howitworks-arrow">
    <span>Выбрали</span>
  </div>
  <div class="howitworks-card">
    <img src="/assets/svg/index-cards/howitworks7.svg" class="howitworks-card__image"/>
    <p class="howitworks-card__text">Предложение n</p>
  </div>
  <div class="howitworks-arrow">
    <span>Получили</span>
  </div>
  <div class="howitworks-card">
    <img src="/assets/svg/index-cards/howitworks8.svg" class="howitworks-card__image"/>
    <p class="howitworks-card__text">Прибыль</p>
  </div>
</div>
