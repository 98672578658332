import { Offer } from './offer';
import { ICredit, IOfferCredit } from '../../app/types';
import { IDoc } from '../../app/services/files';

export class OfferCredit extends Offer<ICredit> implements IOfferCredit {
  effective_rate: number;
  payment_schedule_file: IDoc;
  rate: number;
  valueUnit = '%';

  protected _uaEndpoint = 'credit';

  getOfferValue(): number {
    return this.effective_rate;
  }

  getRate(): number {
    return this.rate;
  }
}
