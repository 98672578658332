<div class="modal-header">
  <h2 class="modal-title">Получить консультацию</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form" [class.mb-3]="error" (submit)="submit()">
    <div class="form-group mb-3">
      <mat-form-field class="w-100">
        <mat-label>Ф.И.О.</mat-label>
        <input matInput required type="text" formControlName="name" />
        <mat-error *ngIf="form.get('name').touched && form.get('name').errors?.required">Обязательное поле</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group mb-3">
      <mat-form-field class="w-100">
        <mat-label>Телефон</mat-label>
        <input matInput placeholder="+71234567890" type="text" formControlName="phone" />
        <mat-error *ngIf="form.get('phone').touched && form.get('phone').errors?.pattern">Недопустимый формат телефона</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group mb-3">
      <mat-form-field class="w-100">
        <mat-label>E-mail</mat-label>
        <input matInput required type="email" formControlName="email" />
        <mat-error *ngIf="form.get('email').touched && form.get('email').errors?.required">Обязательное поле</mat-error>
        <mat-error *ngIf="form.get('email').touched && form.get('email').errors?.email">Введите корректный email</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group mb-4">
      <mat-form-field class="w-100">
        <mat-label>Вопрос специалисту</mat-label>
        <textarea matInput formControlName="message"></textarea>
        <mat-error *ngIf="form.get('message').touched && form.get('message').errors?.required">Обязательное поле</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group mb-3">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" formControlName="privacy_policy" id="privacy_policy" [class.is-invalid]="form.get('privacy_policy').touched && form.get('privacy_policy').invalid" class="custom-control-input">
        <label class="custom-control-label" for="privacy_policy">Согласен с <a [href]="personalDataAgreementFile" target="_blank">политикой конфиденциальности и обработки персональных данных</a></label>
      </div>
    </div>

    <div class="text-danger text-center mb-3" *ngIf="error">{{ error }}</div>

    <div class="text-center">
      <button type="submit" class="btn btn-success" [disabled]="loading || form.invalid || success">
        {{ success ? 'Сообщение отправлено' : 'Отправить' }}
        <fa-icon [icon]="(!loading && success) ? checkicon : loadingIcon" [spin]="loading" *ngIf="loading || success"></fa-icon>
      </button>
    </div>
  </form>
</div>
