import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CRManagerBuilderService } from '../services/cr-manager-builder.service';
import { AnyClientRequestDetailed, IClientRequestDetailed } from '../types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientRequestResolver implements Resolve<IClientRequestDetailed | false> {
  constructor(private _builder: CRManagerBuilderService, private _router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IClientRequestDetailed | false> {
    const clientService = this._builder.getByServiceType(route.data.serviceType);

    return clientService.get(route.params.requestId)
      .pipe(map((clientRequest: AnyClientRequestDetailed) => {
        if (route.url[route.url.length - 1].path === 'edit' && !clientRequest.draft) {
          this._router.navigate(route.url.slice(0, -1).map(v => v.path));
          return false;
        }

        return clientRequest;
      }));
  }
}
