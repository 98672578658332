import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() pagesCount: number;
  @Input() alignCenter = false;
  @Input() updateQuery = false;
  @Input() startPage = 1;

  set page(page: number) {
    this._page = page;
    this._changeDetector.detectChanges();
    this._update();
  }
  get page() { return this._page; }
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  iconChevronLeft = faChevronLeft;
  iconChevronRight = faChevronRight;

  pages: number[];
  trimLeft = false;
  trimRight = true;

  private _page = 1;

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.updateQuery && this._route.snapshot.queryParams.page) {
      this._page = parseInt(this._route.snapshot.queryParams.page, 10);
    } else if (this.startPage) {
      this._page = this.startPage;
    }

    this._makePagesArray();
  }

  hasPrev(): boolean {
    return this.page > 1;
  }

  hasNext(): boolean {
    return this.page < this.pagesCount;
  }

  prev(): void {
    this.set(this.page - 1);
  }

  next(): void {
    this.set(this.page + 1);
  }

  set(page: number) {
    if (this.page !== page && page > 0 && page <= this.pagesCount) {
      this.page = page;
    }
  }

  private _update() {
    if (this.updateQuery) {
      this._updateParam();
    }

    this.pageChange.emit(this.page);
  }

  private _makePagesArray() {
    let pagesToShow = 4;

    if (this.pagesCount < pagesToShow) {
      pagesToShow = this.pagesCount;
      this.trimLeft = this.trimRight = false;
    }

    this.pages = Array.from(Array(pagesToShow).keys()).map(value => {
      let offset = 0;

      if (this.page > 3 && this.pagesCount > pagesToShow) {
        offset = this.page - 3;
        this.trimLeft = true;
      }

      if (offset > this.pagesCount - pagesToShow) {
        offset = this.pagesCount - pagesToShow;
        this.trimRight = false;
      }

      return offset + value + 1;
    });
  }

  private _updateParam() {
    const queryParams = { page: this.page };

    this._router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this._route
    });
  }
}
