import { Component } from '@angular/core';
import { ConsultModalComponent } from "../../../modals/consult-modal/consult-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { NgxMetrikaService } from "@kolkov/ngx-metrika";

@Component({
  selector: 'app-ask-a-question',
  templateUrl: './ask-a-question.component.html',
  styleUrls: ['./ask-a-question.component.scss']
})
export class AskAQuestionComponent {

  constructor(private _modals: NgbModal) { }

  openConsult() {
    this._modals.open(ConsultModalComponent, { size: 'lg' });
  }
}
