import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'septhousand' })
export class SepthousandPipe implements PipeTransform {
  transform(value: string | number, digits = -1, force = false): any {
    let number,
      type = typeof value;

    if (type !== 'number' && type !== 'string') {
      return value;
    }

    if (typeof value === 'string') {
      number = parseFloat(value);

      if (isNaN(number)) {
        return value;
      }
    } else {
      number = value;
    }

    let splitted = number.toString().split('.');

    if (splitted[0].length > 3) {
      splitted[0] = splitted[0]     // "1234"
        .split('')          // "1", "2", "3," "4"
        .reverse()                  // "4, "3", "2", "1"
        .join('')                   // "4321"
        .match(/.{1,3}/g)    // "432", "1"
        .join(' ')                  // "432 1"
        .split('')          // "4", "3", "2", " ", "1"
        .reverse()                  // "1", " ", "2", "3", "4"
        .join('');                  // "1 234"
    }

    if (digits > 0) {
      if (splitted[1] && splitted[1].length !== digits) {
        splitted[1] = (splitted[1] + '0'.repeat(digits)).slice(0, digits);
      } else {
        splitted[1] = '0'.repeat(digits);
      }
    } else if (digits === 0) {
      splitted = splitted.slice(0, 1);
    }

    return splitted.join('.');
  }
}
