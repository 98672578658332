import { IRequestsFilter } from './requests-filter.interface';
import { PageRequestOptions } from '../../../client-request-resource';

export class RequestsFilter implements IRequestsFilter {
  service: number;
  contract_sum_min: number;
  contract_sum_max: number;
  term_sum_min: number;
  term_sum_max: number;
  inn: string;

  constructor(data?: Partial<IRequestsFilter>) {
    if (data) {
      this.fill(data);
    }
  }

  fill(data: Partial<IRequestsFilter>) {
    this.service = data.service || null;
    this.contract_sum_min = data.contract_sum_min;
    this.contract_sum_max = data.contract_sum_max;
    this.term_sum_min = data.term_sum_min;
    this.term_sum_max = data.term_sum_max;
    this.inn = data.inn;
  }

  toPageOptions(options: Pick<PageRequestOptions, 'page' | 'page_size' | 'archive'>): PageRequestOptions {
    return Object.assign({
      service: this.service,
      contract_sum_min: this.contract_sum_min,
      contract_sum_max: this.contract_sum_max
    }, options);
  }
}
