<div class="modal-header">
  <h2 class="modal-title">{{ title }}</h2>
</div>
<div class="modal-body pb-4">
  <h3 class="mb-5 text-center" *ngIf="message">{{ message }}</h3>
  <p class="text-danger text-center mb-3" *ngIf="errorMessage">{{ errorMessage }}</p>

  <div class="info-cards mb-5">
    <div class="info-card">
      <p class="text-muted">Заявка</p>
      <h5>№ {{ request.id }}</h5>
    </div>
    <div class="info-card">
      <p class="text-muted">Дата от</p>
      <h5>{{ request.created_at | date:'yyyy.MM.dd' }}</h5>
    </div>
    <div class="info-card">
      <p class="text-muted">Сумма</p>
      <h5>на {{ sum | septhousand }} ₽</h5>
    </div>
    <div class="info-card" *ngIf="!isUser">
      <p class="text-muted">Кому</p>
      <h5>{{ request.company_declarer.name || request.company_declarer.inn }}</h5>
    </div>
  </div>

  <p class="text-center">
    <button class="btn btn-secondary btn-lg mr-5" (click)="no()" [disabled]="loading">{{ dismissText }}</button>
    <button class="btn btn-primary btn-lg" (click)="yes()" [disabled]="loading">{{ confirmText }}<fa-icon [icon]="iconLoading" class="ml-2" *ngIf="loading"></fa-icon></button>
  </p>
</div>
