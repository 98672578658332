<table class="table w-100 table-striped table-hover">
  <thead class="thead-light">
  <tr>
    <th>#</th>
    <th>№ заявки</th>
    <th>Тип заявки</th>
    <th>Название компании заявителя</th>
    <th>Название компании контрагента</th>
    <th>Сумма заявки в рублях</th>
    <th>{{ serviceType === ClientServiceType.FACTORING ? 'Срок отсрочки (дней)' : 'Срок (мес)' }}</th>
    <th>Кол-во предложений на заявку</th>
    <th>Статус заявки</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let request of requests; index as i;">
    <tr *ngIf="i >= (page-1) * pageSize && i < page * pageSize" (click)="toRequest(request)" [class.table-warning]="request.updated">
      <th>{{ i + 1 }}</th>
      <td>{{ request.id }}</td>
      <td>{{ request.service.name }}</td>
      <td>{{ request.company_declarer?.name || request.company_declarer?.inn || '- Нет -' }}</td>
      <td>{{ request.company_contractor?.name || request.company_contractor?.inn || '- Нет -' }}</td>
      <td>{{ request.contract_sum || request.sum | septhousand }}</td>
      <td>{{ request.contract_period || request.credit_term || request.limitation }}</td>
      <td>{{ request.offers_count }}</td>
      <td>{{ request.status }}</td>
    </tr>
  </ng-container>
  </tbody>
</table>
