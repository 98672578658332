import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  styleUrls: ['./header-dropdown.component.scss']
})
export class HeaderDropdownComponent implements OnInit {
  @Input() text: string;
  @Input() link?: string;
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;

  show = false;

  constructor(private _host: ElementRef<HTMLElement>) { }

  ngOnInit(): void {
    // this._host.nativeElement.removeChild(this.container.nativeElement);
    // document.body.appendChild(this.container.nativeElement);
  }

  @HostListener('mouseover', ['$event'])
  onHover($event) {
    $event.stopPropagation();
    // this.container.nativeElement.style.display = 'block';
  }

  @HostListener('mouseout', ['$event'])
  onLeave($event) {
    $event.stopPropagation();
    // this.container.nativeElement.style.display = 'none';
  }

}
