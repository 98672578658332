import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { IEnrichmentResponse, IScoringValue } from '../types';
import { enrichmentMethodList } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ScoringService {
  constructor(private _http: HttpClient, private _auth: AuthService) { }

  get(inn: number, contract_sum?: number, contract_period?: number): Observable<IScoringValue[]> {
    const data = contract_sum && contract_period
      ? { inn, contract_sum, contract_period }
      : { inn };

    return this._http.post<IScoringValue[]>(environment.apiEndpoint + '/ua/scoring', data)
      .pipe(tap(x => {
        const user = this._auth.authenticatedUser();
        if (!user.legal_detail || !user.legal_detail.inn) {
          this._auth.checkAuthentication().subscribe();
        }
      }));
  }

  enrich(inn: number | string): Observable<IEnrichmentResponse> {
    const methods = Object.keys(enrichmentMethodList);

    return from(methods).pipe(
      concatMap(method => {
        return this._http.post(environment.apiEndpoint + '/focus', {inn: inn as string, method: method})
          .pipe(map(data => {
            return {
              label: enrichmentMethodList[method],
              progress: (((methods.indexOf(method) + 1) / methods.length) * 100) | 0,
              method: method,
              inn: inn,
              data: data
            } as IEnrichmentResponse;
          }));
      })
    );
  }
}
