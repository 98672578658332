import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
import { UserService } from '../../user/user.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { SignUpFormComponent } from '../../shared/components/sign-up-form/sign-up-form.component';
import { AnalyticService } from '../../services/analytic.service';

@Component({
  selector: 'app-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss']
})
export class SignUpModalComponent {
  faCircleNotch = faCircleNotch;
  loading = false;
  form: SignUpFormComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private _modal: NgbModal,
    private _users: UserService,
    private _auth: AuthService,
    private _analytics: AnalyticService,
    private _router: Router
  ) {
    const url = this._router.url.split('?').shift();
    this._analytics.eventYandex('signUpModal_open', { params: { url } });
    this._analytics.eventGoogle('Open', 'Forms', 'Registration');
  }

  signInClick($event: Event): void {
    $event.preventDefault();
    this.openSignIn();
  }

  onEvent(success: boolean) {
    if (success) {
      this.activeModal.close('success sign up');
    }
  }

  openSignIn({ reason = 'Open sign in', message = null, email = null} = {}) {
    this.activeModal.close(reason);

    const modal = this._modal.open(SignInModalComponent, { size: 'lg', beforeDismiss: () => false });
    if (message) modal.componentInstance.message = message;
    if (email) modal.componentInstance.form.controls.email.setValue(email);
  }
}
