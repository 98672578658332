import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHttpModel } from '../app/types';
import { serviceTypeRoutes } from '../app/constants';

export abstract class AbstractUaHttpModel implements IHttpModel {
  public abstract id: number;
  protected abstract _uaEndpoint: string;

  protected constructor(protected readonly _http: HttpClient) {}

  protected _handleUpdate(action$: Observable<this>) {
    return action$.pipe(map((data: this) => {
      Object.assign(this, data);
      return this;
    }));
  }

  getQueryUrl(): string {
    return `${environment.apiEndpoint}/ua/${this._uaEndpoint}`;
  }

  getEntryUrl(): string {
    return `${this.getQueryUrl()}/${this.id}`;
  }

  save(): Observable<this> {
    return this._handleUpdate(this.id
      ? this._http.patch<this>(this.getEntryUrl(), this._pickData())
      : this._http.post<this>(this.getEntryUrl(), this._pickData())
    );
  }

  reload(): Observable<this> {
    return this._handleUpdate(this._http.get<this>(this.getEntryUrl()));
  }

  delete(): Observable<null> {
    return this._http.delete<null>(this.getEntryUrl());
  }

  private _pickData() {
    const data: any = {};

    for (const field of this._pickFields()) {
      const value = this[field];
      if (typeof value === 'function') continue;
      data[field] = value;
    }

    return data;
  }

  private _pickFields(): string[] {
    const fields: string[] = [];

    for (const field in this) {
      if (typeof this[field] === 'function' || typeof field !== 'string' || field.startsWith('_')) continue;
      fields.push(field);
    }

    return fields;
  }
}
