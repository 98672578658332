import { Observable } from 'rxjs';
import { ClientRequestResource } from './client-request-resource';
import { Factoring } from '../models/client-request/factoring';
import { FactoringDetailed } from '../models/client-request/factoring-detailed';
import { Leasing } from '../models/client-request/leasing';
import { LeasingDetailed } from '../models/client-request/leasing-detailed';
import { Credit } from '../models/client-request/credit';
import { CreditDetailed } from '../models/client-request/credit-detailed';
import { OfferFactoring } from '../models/offer/offer-factoring';
import { OfferLeasing } from '../models/offer/offer-leasing';
import { OfferCredit } from '../models/offer/offer-credit';
import { SafeHtml } from '@angular/platform-browser';
import { Guarantee } from '../models/client-request/guarantee';
import { GuaranteeDetailed } from '../models/client-request/guarantee-detailed';
import { OfferGuarantee } from '../models/offer/offer-guarantee';
import { IDoc, IDocTypeDetailed } from './services/files';

export interface IHttpModel {
  id: number;

  save(): Observable<this>;
  reload(): Observable<this>;
  delete(): Observable<null>;
  getQueryUrl(): string;
  getEntryUrl(): string;
}

export enum ClientServiceType {
  FACTORING = 1,
  LEASING,
  CREDIT,
  GUARANTEE
}

export interface IClientService {
  id: number;
  name: string;
  type: ClientServiceType;
  hide_second_inn: boolean;
}

export interface IClientServiceDetailed extends IClientService {
  doc_types: IDocTypeDetailed[];
  formulas: IFormula[];
}

export interface IFormulaField {
  label: string;
  min: number;
  max: number;
  step: number;
  multiplier: number;
}

export interface IFormulaResult {
  label: string;
  result: string;
  unit: string;
}

export interface IFormula {
  fields: IFormulaField[];
  results: IFormulaResult[];
}

export interface ICompanyInnInfo {
  inn: string;
  name?: string;
}

export interface IClientRequest extends IHttpModel {
  id: number;
  company_declarer: ICompanyInnInfo;
  company_contractor?: ICompanyInnInfo;
  offers_count: number;
  service: IClientService;
  status: IClientRequestStatusObject;
  created_at: string;
  viewed_at: string;
  updated: boolean;
  draft: boolean;
  archived: boolean;
  reminder_time: string | null;

  loadOffers(): Observable<IOffer[]>;
  newOffer(IOfferData): Observable<IOffer>;
  getOffersEndpoint(): string;
  isReminderOverdue(): boolean;
  getTerm(): number;
  getSum(): number;
  getStyle(): string;
  getStatusText(): string;
  getPageUrl(): string;
  getCategoryUrl(): string;
}

export interface IClientRequestStatusObject {
  code: IClientRequestStatus;
  msg: string;
}

export type IClientRequestFactorStatus =
  'NO_OFFER' |
  'NOT_ACCEPTED' |
  'NEW_MESSAGE' |
  'IS_FINANCED' |
  'CLIENT_IS_FINANCED' |
  'IS_REJECTED' |
  'IS_ACCEPTED';

export type IClientRequestClientStatus =
  'NO_OFFERS' |
  'IS_DRAFT' |
  'IS_CANCELLED' |
  'NEW_MESSAGE' |
  'IS_FINANCED' |
  'CREDITOR_IS_FINANCED' |
  'OFFERS';

export type IClientRequestStatus = IClientRequestFactorStatus | IClientRequestClientStatus;

export interface IClientRequestExtra {
  scoring: IScoringValue[];
  docs: IDoc[];
}

export interface IClientRequestDetailed extends IClientRequest, IClientRequestExtra {}

export interface IFactoring extends IClientRequest {
  contract_sum: number;
  contract_period: number;
}

export interface IFactoringDetailed extends IFactoring, IClientRequestDetailed {
  contract_period_left: number;
}

export enum LeasingPayType {
  EQUAL = 1,
  DECREASING,
  SEASON
}

export interface ILeasing extends IClientRequest {
  object: string;
  year: string;
  pay_type: LeasingPayType;
  limitation: number;
  prepaid: string;
  price: number;
  count: number;
  wear_degree: number;
  is_change_contract_of_sale: boolean;
  pay_schema: string[];
  season_chart: number[];
  sum?: number;
}

export interface ILeasingDetailed extends ILeasing, IClientRequestDetailed {
  contract: IDoc;
  specification: IDoc;
}

export interface ICredit extends IClientRequest {
  credit_type: number;
  credit_target: number;
  credit_term: number;
  sum: number;
  material_losses: boolean;
}

export interface ICreditDetailed extends ICredit, IClientRequestDetailed {
  revenue: number[];
}

export enum GuaranteeFZ {
  FZ44 = 1,
  FZ223,
  OTHER
}

export interface IGuarantee extends IClientRequest {
  sum: number;
  limitation: number;
  fz: GuaranteeFZ;
  competition_url: string;
}

export interface IGuaranteeDetailed extends IGuarantee, IClientRequestDetailed { }

export enum OfferStatus {
  DEFAULT = 'default',
  ACCEPTED = 'accepted',
  FINANCED = 'financing',
  REJECTED = 'rejected',
  CANCELED = 'canceled'
}

export interface IOfferData {
  commission_text: string;
  additional_info: string;
  validity: string;
}

export interface IOfferFactoringData extends IOfferData {
  finance_limit: number;
  finance_percent: number;
  validity: string;
  rate: number;
}

export interface IOfferLeasingData extends IOfferData {
  rate: number;
  effective_rate: number;
  payment_schedule_file: IDoc;
}

export interface IOfferCreditData extends IOfferData {
  rate: number;
  effective_rate: number;
  payment_schedule_file: IDoc;
}

export interface IOfferGuaranteeData extends IOfferData {
  price_in_percent: number;
  bankguarantee_status: GuaranteeOfferStatus;
}

export interface IOfferStatus {
  code: OfferStatus;
  label: string;
  message: string;
}

export interface IOffer extends IOfferData, IHttpModel {
  id: number;
  created_at: string;
  client_done: boolean;
  factor_done: boolean;
  status: IOfferStatus;
  updated: boolean;

  accept(): Observable<this>;
  done(): Observable<this>;
  reject(): Observable<this>;
  cancel(message?: string): Observable<this>;

  getOfferValue(): number;
  getRate(): number;
  getStyle(): string;
}

export interface IOfferFactoring extends IOffer, IOfferFactoringData {}
export interface IOfferLeasing extends IOffer, IOfferLeasingData {}
export interface IOfferCredit extends IOffer, IOfferCreditData {}
export interface IOfferGuarantee extends IOffer, IOfferGuaranteeData {}

export interface IEnrichmentResponse {
  label: string;
  progress: number;
  method: string;
  inn: string | number;
  data: any[];
}

export interface IScoringValue {
  label: string;
  value: number | null | boolean;
  flag?: string;
}

export enum IFileUploaderEventType {
  Select,
  Start,
  Progress,
  Complete,
  Error,
  Deleted
}

export interface IDataPage<T> {
  data: T[];
  page: number;
  count: number;
}

export interface ChatMessage {
  id: number;
  user: string;
  message: string;
  doc?: {
    filename: string;
    doc: string;
    doc_type: string;
    human_size: string;
    filesize: number;
  };
  docs?: Array<{
    filename: string;
    doc: string;
    doc_type: string;
    human_size: string;
    filesize: number;
  }>;
  is_mine: boolean;
  created_at: string;
  delivery_at: string;
  read_at: string;
}

export interface CalcField {
  name: string;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  multiplier?: number;
}

export interface CalcResult {
  name: string;
  label: string;
  result: (fields: {[field: string]: number},
           results: {[result: string]: number}) => number;
  unit: string;
  prefix?: string;
  disclaimer?: string;
}

export interface CalcRule {
  cond: (IClientService) => boolean;
  fields: CalcField[];
  results: CalcResult[];
}

export interface FaqEntry {
  content: string;
  head: string;
  id: number;
  lead: string;
  safeContent?: SafeHtml;
  safeLead?: SafeHtml;
}

export type AnyClientRequestResource = ClientRequestResource<IClientRequest, IClientRequestDetailed>;
export type FactoringResource = ClientRequestResource<Factoring, FactoringDetailed>;
export type LeasingResource = ClientRequestResource<Leasing, LeasingDetailed>;
export type CreditResource = ClientRequestResource<Credit, CreditDetailed>;
export type GuaranteeResource = ClientRequestResource<Guarantee, GuaranteeDetailed>;

export type AnyOffer = IOffer | OfferFactoring | OfferLeasing | OfferCredit | OfferGuarantee;
export type AnyClientRequest = IClientRequest | Factoring | Leasing | Credit | Guarantee;
export type AnyClientRequestDetailed = IClientRequestDetailed | FactoringDetailed | LeasingDetailed | CreditDetailed | GuaranteeDetailed;

export interface Article {
  id: string;
  name: string;
  slug: string;
  url: string;
  description: string;
  body: string;
  children: Article[];
}

export enum ArticleType {
  digital = 'digital_service',
  additional = 'additional_service'
}

export enum GuaranteeOfferStatus {
  PRE_APPROVED = 1,
  APPROVED
}

export type HTMLInputElementType = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';

export type ProgressBarType = 'success' | 'info' | 'warning' | 'danger';
