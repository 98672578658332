import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-column-chart',
  templateUrl: './vertical-column-chart.component.html',
  styleUrls: ['./vertical-column-chart.component.scss']
})
export class VerticalColumnChartComponent implements OnInit {
  @Input() values: {label: string, value: number}[];

  columns: {width: string, label: string, value: number}[] = [];

  ngOnInit() {
    const max = Math.max.apply(null, this.values.map(c => c.value));
    const mult = max === 0 ? 0 : 100 / max;
    this.columns = this.values.map(c => Object.assign({width: (c.value*mult) + '%'}, c));
  }
}
