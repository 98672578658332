import { HttpClient } from '@angular/common/http';
import {
  ClientServiceType,
  CreditResource,
  FactoringResource,
  GuaranteeResource,
  IClientRequest,
  IClientRequestDetailed,
  IDataPage,
  LeasingResource
} from './types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { Factoring } from '../models/client-request/factoring';
import { Leasing } from '../models/client-request/leasing';
import { Credit } from '../models/client-request/credit';
import { LeasingDetailed } from '../models/client-request/leasing-detailed';
import { CreditDetailed } from '../models/client-request/credit-detailed';
import { FactoringDetailed } from '../models/client-request/factoring-detailed';
import { GuaranteeDetailed } from '../models/client-request/guarantee-detailed';
import { Guarantee } from '../models/client-request/guarantee';
import { serviceTypeRoutes } from './constants';

export class ClientRequestResource<R extends IClientRequest, RD extends IClientRequestDetailed> {
  private readonly _endpoint;

  static buildFactoring(http: HttpClient): FactoringResource {
    return this._build<Factoring, FactoringDetailed>(
      http,
      ClientServiceType.FACTORING,
      Factoring,
      FactoringDetailed
    );
  }

  static buildLeasing(http: HttpClient): LeasingResource {
    return this._build<Leasing, LeasingDetailed>(
      http,
      ClientServiceType.LEASING,
      Leasing,
      LeasingDetailed
    );
  }

  static buildCredit(http: HttpClient): CreditResource {
    return this._build<Credit, CreditDetailed>(
      http,
      ClientServiceType.CREDIT,
      Credit,
      CreditDetailed
    );
  }

  static buildGuarantee(http: HttpClient): GuaranteeResource {
    return this._build<Guarantee, GuaranteeDetailed>(
      http,
      ClientServiceType.GUARANTEE,
      Guarantee,
      GuaranteeDetailed
    );
  }

  private static _build<R extends IClientRequest, RD extends IClientRequestDetailed>
  (
    http: HttpClient,
    serviceType: ClientServiceType,
    clientRequestClass: new (...args: any[]) => R,
    clientRequestDetailedClass: new (...args: any[]) => RD
  ) {
    return new ClientRequestResource<R, RD>(
      http,
      serviceType,
      clientRequestClass,
      clientRequestDetailedClass
    );
  }

  private constructor(
    private _http: HttpClient,
    public readonly serviceType: ClientServiceType,
    private readonly _ClientRequestClass: new (...args: any[]) => R,
    private readonly _ClientRequestDetailedClass: new (...args: any[]) => RD
  ) {
    this._endpoint = environment.apiEndpoint + '/ua/' + serviceTypeRoutes[serviceType];
  }

  public page(options: PageRequestOptions): Observable<IDataPage<R>> {
    const params = {
      page: '1',
      page_size: '11'
    };

    for (const opt in options || {}) {
      if ([undefined, null, ''].includes(options[opt])) {
        continue;
      }

      params[opt] = options[opt].toString();
    }

    return this._http.get<[]>(this._endpoint, {params, observe: 'response'})
      .pipe(map(response => ({
        data: response.body.map(r => new this._ClientRequestClass(this._http, r)),
        page: parseInt(params.page, 10),
        count: parseInt(response.headers.get('x-page-paginator-count'), 10)
      } as IDataPage<R>)
    ));
  }

  get(id: number): Observable<RD> {
    return this._http.get(`${this._endpoint}/${id}`)
      .pipe(
        map(clientRequest => {
          const cr = new this._ClientRequestDetailedClass(this._http, clientRequest);
          console.log('request got:', cr);
          return cr;
        })
      );
  }

  update(id, data): Observable<RD> {
    if (!id) {
      return this.create(data);
    }

    return this._http.put(`${this._endpoint}/${id}`, data)
      .pipe(
        map(clientRequest => {
          const cr = new this._ClientRequestDetailedClass(this._http, clientRequest);
          console.log('request updated:', cr);
          return cr;
        })
      );
  }

  create(data): Observable<RD> {
    return this._http.post(this._endpoint, data)
      .pipe(
        map(clientRequest => {
          const cr = new this._ClientRequestDetailedClass(this._http, clientRequest);
          console.log('request created:', cr);
          return cr;
        })
      );
  }
}

export interface PageRequestOptions {
  page?: number;
  page_size?: number;
  service?: number;
  contract_period_contdown?: number;
  created_at?: NgbDateStruct | string;
  contract_sum_max?: number;
  contract_sum_min?: number;
  archive?: boolean;
  draft?: boolean;
}
