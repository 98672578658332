<ol class="custom-list">
  <li>
    Вам нужны дополнительные средства?

    <ul>
      <li>Рассчитайте лимит на нашем калькуляторе</li>
      <li>Заполните анкету</li>
      <li>Отправьте заявку</li>
    </ul>
  </li>
  <li>Мы подбираем для вас лучшее предложение</li>
  <li>Вы получаете предложения от банков и финансовых компаний</li>
  <li>Вы выбираете лучшее предложение</li>
  <li class="custom-list-item-green">Вы получаете необходимое финансирование</li>
</ol>
