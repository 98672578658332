<div class="container mb-5">
  <div class="calculator">
    <form [formGroup]="form" (submit)="submit()">
      <!--<div class="form-group row align-items-center">-->
        <!--<label for="service" class="col-3">На что</label>-->
        <!--<div class="col-6">-->
          <!--<select id="service" class="form-control" formControlName="service" [class.is-invalid]="form.get('service').touched && form.get('service').invalid">-->
            <!--<option value="" selected>Выбрать</option>-->
            <!--<option value="1">Факторинг</option>-->
            <!--<option value="2">Лизинг</option>-->
            <!--<option value="3">Кредит</option>-->
          <!--</select>-->
          <!--<div class="invalid-feedback">Нужно выбрать</div>-->
        <!--</div>-->
      <!--</div>-->
      <div class="form-group row align-items-center">
        <label for="inputSum" class="col-3">Сколько, рублей</label>
        <div class="col-2">
          <input appFormat [min]="100000" [max]="10000000000" formatType="float" [separate]="true" id="inputSum" class="form-control" formControlName="sum" [class.is-invalid]="form.get('sum').touched && form.get('sum').invalid">
          <div class="invalid-feedback" *ngIf="form.get('sum').errors?.required">Обязательное поле</div>
          <div class="invalid-feedback" *ngIf="form.get('sum').errors?.min">Не меньше 1</div>
          <div class="invalid-feedback" *ngIf="form.get('sum').errors?.pattern">Только числа</div>
        </div>
        <label for="inputPeriod" class="col-2 text-right">На сколько дней</label>
        <div class="col-2">
          <input appFormat [min]="1" [max]="5400" formatType="integer" id="inputPeriod" class="form-control" formControlName="period" [class.is-invalid]="form.get('period').touched && form.get('period').invalid">
          <div class="invalid-feedback" *ngIf="form.get('period').errors?.required">Обязательное поле</div>
          <div class="invalid-feedback" *ngIf="form.get('period').errors?.min">Не меньше 1</div>
          <div class="invalid-feedback" *ngIf="form.get('period').errors?.pattern">Только целые числа</div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label for="inputEmail" class="col-3">Почта для предложений</label>
        <div class="col-6">
          <input id="inputEmail" class="form-control" formControlName="email" [class.is-invalid]="form.get('email').touched && form.get('email').invalid ">
          <div class="invalid-feedback" *ngIf="form.get('email').errors?.required">Обязательное поле</div>
          <div class="invalid-feedback" *ngIf="form.get('email').errors?.email">Неверный формат email</div>
          <ng-container *ngIf="form.get('email').errors?.backend">
            <div class="help-block mt-1 text-danger" *ngFor="let error of form.get('email').errors.backend">{{ error }}</div>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-6 offset-3">
          <button type="submit" class="btn btn-lg btn-block btn-primary" [disabled]="!form.valid || loading">Рассчитать</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="offers-block pt-5" *ngIf="offers" #offersContainer>
  <div class="container">
    <h1 class="text-center mb-5">Предложения</h1>

    <div class="offers">
      <div class="offer" *ngFor="let offer of offers">
        <div class="offer-left">
          <h3 class="mb-0">{{ offer.label }}</h3>
          <a [routerLink]="offer.link" target="_blank" class="text-muted" [ngbPopover]="offer.detail" autoClose="outside">Что это?</a>
        </div>
        <div class="offer-right text-muted">
          от <span>{{ offer.rate }}%</span> годовых
          <a href="javascript:" (click)="select(offer)" class="btn btn-lg btn-primary ml-4">Оформить заявку</a>
        </div>
      </div>
    </div>
  </div>
</div>
