<div class="modal-header">
  <h2 class="modal-title">{{ step == 2 ? 'Поздравляем!' : 'Подтвердите ваш номер телефона' }}</h2>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-8 offset-2" [ngSwitch]="step">
      <!-- PHONE FORM : STEP 1 -->
      <form [formGroup]="phoneForm" (submit)="nextStep()" *ngSwitchCase="0">
        <h4 class="mb-4 text-center">Для продолжения работы с Myfi вам нужно указать и подтвердить ваш номер телефона</h4>

        <div class="form-group mb-4">
          <label>Номер телефона</label>
          <input type="text" placeholder="+71234567890" class="form-control" formControlName="new_mobile_phone" [class.is-invalid]="phone.touched && phone.invalid || backendErrors" (input)="resetBackendErrors()" />
          <div class="invalid-feedback" *ngIf="phone.errors?.required">Необходимо ввести телефон</div>
          <div class="invalid-feedback" *ngIf="phone.errors?.pattern">Неверный формат телефона</div>
          <ng-container *ngFor="let errorName of ['new_mobile_phone', 'non_field_errors']">
            <ng-container *ngIf="backendErrors && backendErrors[errorName]">
              <div class="text-danger mt-1" *ngFor="let error of backendErrors[errorName]">{{ error }}</div>
            </ng-container>
          </ng-container>
        </div>

        <p class="text-danger" *ngIf="backendErrors">{{ backendErrors.detail }}</p>

        <button type="submit" class="btn btn-primary btn-block btn-lg" [disabled]="phoneForm.invalid || loading">Получить SMS-код подтверждения<fa-icon class="ml-2" [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon></button>
      </form>
      <!-- // PHONE FORM : STEP 1 -->

      <!-- CODE FORM : STEP 2 -->
      <form [formGroup]="codeForm" (submit)="nextStep()" *ngSwitchCase="1">
        <h4 class="text-center">Мы прислали SMS-код подтверждения на номер</h4>
        <h4 class="mb-4 text-center">{{ phone.value }} <a href="javascript:void(0)" (click)="reset()"><fa-icon [icon]="iconEdit"></fa-icon></a></h4>

        <div class="form-group mb-4">
          <label>Введите SMS-код</label>
          <div class="row">
            <div class="col-4">
              <input type="text" class="form-control" formControlName="sms_code" [class.is-invalid]="code.touched && code.invalid || backendErrors" />
            </div>
            <div class="col-8">
              <button type="submit" class="btn btn-primary btn-lg btn-block" [disabled]="codeForm.invalid">Подтвердить номер<fa-icon class="ml-2" [icon]="iconLoading" [spin]="true" *ngIf="loading"></fa-icon></button>
            </div>
          </div>

          <ng-container *ngIf="code.touched && code.invalid || backendErrors">
            <div class="text-danger mt-1" *ngIf="code.errors?.required">Необходимо ввести код</div>
            <div class="text-danger mt-1" *ngIf="code.errors?.pattern">Код - это 6 цифр. Он должен придти по смс.</div>
            <ng-container *ngFor="let errorName of ['sms_code', 'new_mobile_phone', 'non_field_errors']">
              <ng-container *ngIf="backendErrors && backendErrors[errorName]">
                <div class="text-danger mt-1" *ngFor="let error of backendErrors[errorName]">{{ error }}</div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </form>
      <!-- CODE FORM : STEP 2 -->

      <div *ngSwitchCase="2" class="text-center">
        <img src="/assets/svg/checked.svg" />

        <h3 class="my-5">Ваш номер {{ phone.value }} успешно подтвержден.</h3>

        <button type="button" class="btn btn-primary btn-lg px-5" (click)="activeModal.close()">Ок</button>
      </div>
    </div>
  </div>
</div>
