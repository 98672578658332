import { ClientRequest } from './client-request';
import { ILeasing, IOfferLeasing, LeasingPayType } from '../../app/types';
import { OfferLeasing } from '../offer/offer-leasing';

export class Leasing extends ClientRequest<IOfferLeasing> implements ILeasing {
  limitation: number;
  object: string;
  pay_type: LeasingPayType;
  prepaid: string;
  sum: number;
  year: string;
  count: number;
  is_change_contract_of_sale: boolean;
  pay_schema: string[];
  price: number;
  season_chart: number[];
  wear_degree: number;

  protected _uaEndpoint = 'leasing';

  getTerm() {
    return this.limitation;
  }

  getSum() {
    return this.sum || this.price * this.count;
  }

  protected _castOffer(data: IOfferLeasing): OfferLeasing {
    return new OfferLeasing(this._http, data);
  }
}
