import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { RequestFormDataService } from '../request-form-data.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { interval } from 'rxjs';
import { AuthService } from '../../services/auth.service';

interface ServiceOffer {
  id: number;
  label: string;
  detail: string;
  link: string;
  rate: number;
}

@Component({
  selector: 'app-page-landing-index',
  templateUrl: './page-landing-index.component.html',
  styleUrls: ['./page-landing-index.component.scss']
})
export class PageLandingIndexComponent {
  loading = false;
  offers: ServiceOffer[];

  @ViewChild('offersContainer')
  set offersContainer(container) {
    if (container) {
      setTimeout(() => container.nativeElement.scrollIntoView({ block: 'start' }), 0);
    }
  }

  form = this._requestFormService.calcForm;

  constructor(private _router: Router, private _requestFormService: RequestFormDataService) { }

  submit() {
    this.offers = this._requestFormService.calculate();
  }

  select(offer) {
    this._requestFormService.resetRequestStorage();
    this._requestFormService.select(offer);
    this._router.navigate(['landing', 'go']);
  }
}
