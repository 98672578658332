<div class="line-tabs line-tabs--small mb-4">
  <div class="tab">
    <a class="nav-link" [routerLink]="getPageAddress()" [class.active]="!archiveRla.isActive">Открытые заявки</a>
  </div>
  <div class="tab">
    <a class="nav-link" [routerLink]="getPageAddress() + '/archive'" routerLinkActive="active" #archiveRla="routerLinkActive">Завершённые</a>
  </div>
</div>

<div class="row">
  <div class="col-12 col-lg-8 col-xl-9 order-1 order-lg-0">
    <ng-container *ngIf="dataPage$ | async as dataPage">
      <app-request-card *ngFor="let request of dataPage.data" [request]="request"></app-request-card>
    </ng-container>
  </div>
  <div class="col">
    <div class="filter-sticky">
      <app-requests-filter [serviceType]="resource.serviceType" (filterChange)="updateFilter($event)"></app-requests-filter>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-lg-9">
    <ng-container *ngIf="dataPage$ | async as dataPage">
      <app-paginator
        *ngIf="getPageCount(dataPage.count) > 1"
        (pageChange)="loadPage($event)"
        [pagesCount]="getPageCount(dataPage.count)"
        [alignCenter]="true"
        [updateQuery]="true"
      ></app-paginator>
    </ng-container>
  </div>
</div>
