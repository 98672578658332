<form [formGroup]="form">
  <div class="form-group mb-5">
    <label class="mb-3">Сумма банковской гарантии, ₽</label>
    <input
      formControlName="sum"
      type="text"
      class="form-control w-auto"
      [class.is-invalid]="form.get('sum').touched && form.get('sum').invalid"
      appFormat
      [min]="MIN_CONTRACT_SUM"
      [max]="MAX_CONTRACT_SUM"
      formatType="float"
      [separate]="true"
    />
    <p class="invalid-feedback" *ngIf="form.get('sum').errors?.required">Обязательное поле</p>
    <p class="invalid-feedback" *ngIf="form.get('sum').errors?.min || form.get('sum').errors?.max">Должно быть от 100 тыс. до 10 млрд.</p>
  </div>

  <div class="form-group mb-5">
    <label>Срок гарантии, мес</label>
    <p class="small text-muted">Согласно контракту</p>

    <div class="row">
      <app-changers-input
        class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2"
        [class.is-invalid]="form.get('limitation').touched && form.get('limitation').invalid"
        formControlName="limitation"
        [separate]="true"
      ></app-changers-input>
    </div>

    <ng-container *ngIf="form.get('limitation').touched">
      <p class="invalid-feedback" *ngIf="form.get('limitation').errors?.required">Обязательное поле</p>
      <p class="invalid-feedback" *ngIf="form.get('sum').errors?.min">Не может быть меньше 1</p>
    </ng-container>
  </div>

  <div class="form-group mb-5">
    <label>Федеральный закон</label>
    <p class="small text-muted">Выберите федеральный закон, по которому идут торги</p>
    <select
      formControlName="fz"
      class="form-control w-auto"
      [class.is-invalid]="form.get('fz').touched && form.get('fz').invalid"
    >
      <option [ngValue]="undefined">Выберите</option>
      <option *ngFor="let type of guaranteeFzName | keyvalue" [value]="type.key">{{ type.value }}</option>
    </select>
    <p class="invalid-feedback" *ngIf="form.get('fz').errors?.required">Нужно выбрать</p>
  </div>

  <div class="form-group mb-5">
    <label>Ссылка на конкурс</label>
    <p class="small text-muted">Вставьте ссылку на страницу с информацией о торгах</p>
    <input
      formControlName="competition_url"
      type="text"
      placeholder="https://"
      class="form-control"
      [class.is-invalid]="form.get('competition_url').touched && form.get('competition_url').invalid"
    />
    <p class="invalid-feedback" *ngIf="form.get('competition_url').errors?.required">Обязательное поле</p>
    <p class="invalid-feedback" *ngIf="form.get('competition_url').errors?.pattern">Введите корректный URL</p>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
      <div class="form-group">
        <label>ИНН заявителя</label>
        <div class="separated-input-group">
          <input formControlName="inn" class="form-control" type="text" [class.is-invalid]="form.get('inn').touched && (form.get('inn').invalid || inn1Error)" [readOnly]="lockInn" />
          <button class="btn btn-primary btn-hollow btn-lg" (click)="loadInn1()" [disabled]="loading || enrichOngoing || !validScoring1()">Скоринг</button>
        </div>
        <p class="help-block text-danger" *ngIf="form.get('inn').touched && form.get('inn').invalid && form.get('inn').errors.required">Введите ИНН</p>
        <p class="help-block text-danger" *ngIf="form.get('inn').touched && form.get('inn').invalid && form.get('inn').errors.pattern">Недопустимый ИНН</p>
        <p class="help-block mt-1" *ngIf="inn1Error">{{ inn1Error }}</p>
      </div>
    </div>
  </div>
</form>
