import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { faCheck, faCheckDouble, faPaperclip, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { concatMap, switchMap, tap } from 'rxjs/operators';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { FactoringDetailed } from '../../../../models/client-request/factoring-detailed';
import { ChatMessage } from '../../../types';
import { ChatService } from '../../../services/chat.service';
import { LeasingDetailed } from '../../../../models/client-request/leasing-detailed';
import { CreditDetailed } from '../../../../models/client-request/credit-detailed';
import { serviceTypeRoutes } from '../../../constants';
import { DatePipe } from '@angular/common';
import { IFileUploadEvent } from '../../../services/files';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() height = 0;
  @Input() factorRequest: FactoringDetailed | LeasingDetailed | CreditDetailed;
  @Input() factorResponseId: number;
  @Input() enabled = true;
  @ViewChild('messagesElement', { static: true }) messagesElementRef: ElementRef;
  @ViewChild('uploader', { static: true }) uploader: FileUploaderComponent;

  iconPlane = faPaperPlane;
  iconFile = faFile;
  iconClip = faPaperclip;
  iconTimes = faTimes;
  iconCheck = faCheck;
  iconDoubleCheck = faCheckDouble;

  messages$: Subject<ChatMessage[]> = new Subject<ChatMessage[]>();
  text: string;

  fileUploading = false;
  fileProgress = 0;
  fileError = '';

  files: {name: string, id: number}[] = [];

  autoUpdateSub: Subscription;
  loadMessages$: Observable<ChatMessage[]>;

  constructor(
    private _element: ElementRef,
    private _chat: ChatService,
    private _cdr: ChangeDetectorRef,
    private _datePipe: DatePipe
  ) {
  }

  ngOnDestroy(): void {
    this.autoUpdateSub.unsubscribe();
  }

  ngOnInit() {
    this.messages$.subscribe(() => this.scrollToBottom());

    this.loadMessages$ = this._chat
      .getAll(serviceTypeRoutes[this.factorRequest.service.type], this.factorResponseId)
      .pipe(tap(messages => {
        this.messages$.next(messages.sort((a, b) => a.id > b.id ? 1 : -1));
      }));

    this.loadMessages$.subscribe();

    this.autoUpdateSub = interval(5000)
      .pipe(
        switchMap(() => this.loadMessages$)
      )
      .subscribe();

    if (this.height) {
      this.setHeight(this.height);
    }

    this.uploader.startUploading.subscribe(() => {
      this.fileUploading = true;
      this.fileError = '';
    });

    this.uploader.progressReport.subscribe((event: IFileUploadEvent) => this.fileProgress = event.progress);

    this.uploader.catch.subscribe((event: IFileUploadEvent) => {
      this.fileError = event.errorMessage;
      this.fileUploading = false;
    });

    this.uploader.complete.subscribe(event => {
      this.fileUploading = false;
      this.files.push({
        name: event.response.filename,
        id: event.response.id
      });
    });
  }

  send() {
    if (!this.text && !this.files.length) return;

    const text = this.text,
      files = this.files;

    this.files = [];
    this.text = '';
    this._cdr.detectChanges();

    this._chat.send(serviceTypeRoutes[this.factorRequest.service.type], this.factorResponseId, text, files.map(file => file.id))
      .pipe(concatMap(() => this.loadMessages$)).subscribe();
  }

  fileDisabled() {
    return this.fileUploading;
  }

  attachFile() {
    if (this.fileDisabled()) return;

    this.uploader.reset();
    this.uploader.openSelector();
  }

  removeFile(id: number) {
    const i = this.files.findIndex(value => value.id === id);

    if (i != null) {
      return this.files.splice(i, 1);
    }

    return null;
  }

  areaKeyPress($event: KeyboardEvent) {
    if ($event.code === 'Enter' && !$event.shiftKey) {
      $event.preventDefault();
      this.send();
    }
  }

  scrollToBottom() {
    const el = this.messagesElementRef.nativeElement;
    el.scrollTop = el.scrollHeight;
  }

  onMessagesScroll($event) {
    return $event;
  }

  setHeight(height: number) {
    this._element.nativeElement.style['height'] = height + 'px';
  }

  messageStatus(message: ChatMessage): string {
    let status = 'Доставлено ' + this.messageTime(message.delivery_at);

    if (message.read_at) {
      status += '\nПрочитано ' + this.messageTime(message.read_at);
    }

    return status;
  }

  messageTime(time: string) {
    const date = new Date(time);
    const now = new Date();

    if (date.getDate() !== now.getDate() || date.getMonth() !== now.getMonth() || date.getFullYear() !== now.getFullYear()) {
      return this._datePipe.transform(date, 'short', undefined, 'ru-RU');
    } else {
      return this._datePipe.transform(date, 'shortTime', undefined, 'ru-RU');
    }
  }
}
