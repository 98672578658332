import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesTypesService } from '../services/services-types.service';
import { VAT_FORMAT_REGEX } from '../constants';
import { emailAsyncValidator } from './email-async-validator';
import { HttpClient } from '@angular/common/http';
import { IClientService } from '../types';
import { debounceTime } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';

interface CalcForm {
  sum: number;
  period: number;
  email: string;
}

interface RequestForm {
  service: number;
  inn: string;
  inn_second: string;
  contract_sum: number;
  contract_period: number;
}

@Injectable({
  providedIn: 'root'
})
export class RequestFormDataService {
  private _onInit = new ReplaySubject();
  onInit = this._onInit.asObservable();

  private _calcForm: FormGroup;
  private _requestForm: FormGroup;
  private _services: IClientService[];
  private _selected;

  private _basePercents = [
    {
      percent: 0.25,
      rule: (sum) => sum > 10000000
    },
    {
      percent: 0.2,
      rule: (sum) => sum > 1000000 && sum < 10000000
    },
    {
      percent: 0.15,
      rule: (sum) => sum < 1000000
    },

    // DEFAULT
    {
      percent: 0.15,
      rule: () => true
    }
  ];

  private _links = [
    undefined,
    '/services/factoring/recourse',
    '/services/micro/factoring',
    '/services/purchase/purchasing',
    '/services/factoring/export',
    '/services/purchase/import',
    '/services/factoring/no-recourse',
    '/services/state/fz44',
    '/services/state/fz223'
  ];

  private _serviceNumbers = [
    undefined,
    'Факторинг c регрессом',
    'Микрофакторинг',
    'Закупочный факторинг',
    'Экспортный факторинг',
    'Импортный факторинг',
    'Факторинг без регресса',
    'Госфакторинг - 44-ФЗ',
    'Госфакторинг - 223-ФЗ'
  ];

  get calcForm() {
    return this._calcForm;
  }

  get requestForm() {
    return this._requestForm;
  }

  get selected() {
    return this._selected;
  }

  private get _sum() {
    return parseFloat(this._calcForm.get('sum').value);
  }

  private get _period() {
    return parseInt(this._calcForm.get('period').value, 10);
  }

  private static getLocalStorageKey(name: string): string {
    return `landing_${name}`;
  }

  private static getFromLocalStorage<T extends object>(name: string, defaults: T): T {
    const key = this.getLocalStorageKey(name);
    let error = false;
    let data;

    try {
      data = JSON.parse(localStorage.getItem(key));

      if (typeof data !== 'object') {
        error = true;
      }
    } catch (e) {
      error = true;
    }

    if (error) {
      this.removeLocalStorage(name);
    }

    data = Object.assign({}, defaults, data);

    return data;
  }

  private static saveToLocalStorage<T extends object>(name: string, data: T): void {
    const key = this.getLocalStorageKey(name);
    console.log('Save to "' + key + '": ' + JSON.stringify(data));
    localStorage.setItem(key, JSON.stringify(data));
  }

  private static removeLocalStorage(name: string): void {
    const key = this.getLocalStorageKey(name);
    localStorage.removeItem(key);
  }

  private static saveCalcForm(data: CalcForm) {
    this.saveToLocalStorage<CalcForm>('calc', data);
  }

  private static saveRequestForm(data: RequestForm) {
    this.saveToLocalStorage<RequestForm>('request', data);
  }

  private static saveOffer(data: {offer: object}) {
    this.saveToLocalStorage<object>('offer', data);
  }

  constructor(
    private _fb: FormBuilder,
    private _servicesTypes: ServicesTypesService,
    private _http: HttpClient
  ) {
    _servicesTypes.loadAll().subscribe(services => {
      this._services = services.filter(service => this._serviceNumbers.includes(service.name));

      const savedData = RequestFormDataService.getFromLocalStorage<{offer: object}>('offer', { offer: null });
      if (savedData.offer) {
        this.select(savedData.offer);
      }

      this._onInit.next();
    });

    this.createCalcForm();
  }

  reset(): RequestFormDataService {
    this.resetCalcStorage();
    this.resetRequestStorage();
    this.createCalcForm();
    return this;
  }

  resetCalcStorage() {
    RequestFormDataService.removeLocalStorage('calc');
  }

  resetRequestStorage() {
    RequestFormDataService.removeLocalStorage('request');
  }

  calculate() {
    const offers = [];

    for (const service of this._services) {
      offers.push({
        id: service.id,
        label: service.name,
        link: this._links[this._serviceNumbers.indexOf(service.name)],
        rate: parseFloat(this.calcPercent(service.name, this._sum, this._period).toFixed(2))
      });
    }

    return offers.sort((a, b) => a.rate > b.rate ? 1 : -1).slice(0, 3);
  }

  select(offer) {
    this._selected = offer;
    RequestFormDataService.saveOffer({ offer });
    this.createRequestForm();
  }

  private calcPercent(serviceName: string, sum: number, period: number) {
    const num = this._serviceNumbers.indexOf(serviceName);
    const basePercent = this._basePercents.find(value => value.rule(sum)).percent;

    return (basePercent + num / 10) / 352 * period * 100;
  }

  private createCalcData() {
    return RequestFormDataService.getFromLocalStorage<CalcForm>('calc', {
      sum: 100000,
      period: 1,
      email: ''
    });
  }

  private createRequestData() {
    const calcData = this._calcForm.value;
    return RequestFormDataService.getFromLocalStorage<RequestForm>('request', {
      service: this._selected,
      inn: '',
      inn_second: '',
      contract_sum: calcData.sum,
      contract_period: calcData.period
    });
  }

  private createCalcForm() {
    const data = this.createCalcData();

    this._calcForm = this._fb.group({
      sum: [data.sum, [Validators.required, Validators.min(1), Validators.pattern(/^\d+([,.]\d+)?$/)]],
      period: [data.period, [Validators.required, Validators.min(1), Validators.pattern(/^\d+$/)]],
      email: [data.email, [Validators.required, Validators.email], emailAsyncValidator(this._http)]
    });

    this._calcForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((form: CalcForm) => RequestFormDataService.saveCalcForm(form));
  }

  private createRequestForm() {
    const requestData = this.createRequestData();

    this._requestForm = this._fb.group({
      service: [requestData.service, Validators.required],
      inn: [requestData.inn, [Validators.required, Validators.pattern(VAT_FORMAT_REGEX)]],
      inn_second: [requestData.inn_second, [Validators.required, Validators.pattern(VAT_FORMAT_REGEX)]],
      contract_sum: [requestData.contract_sum, [Validators.required, Validators.min(1)]],
      contract_period: [requestData.contract_period, [Validators.required, Validators.min(1)]]
    });

    this._requestForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((form: RequestForm) => RequestFormDataService.saveRequestForm(form));
  }
}
