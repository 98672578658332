import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-index',
  templateUrl: './card-index.component.html',
  styleUrls: ['./card-index.component.scss']
})
export class CardIndexComponent {
  @Input() header: string;
  @Input() image?: string;
}
