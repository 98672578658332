import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { supportPhoneFormat } from '../constants';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  title = 'Подать заявку';

  supportPhoneFormat = supportPhoneFormat;

  constructor(private _auth: AuthService, private _router: Router) {
  }

  ngOnInit(): void {
    this._auth.checkAuthentication().subscribe(user => {
      if (user) {
        this._router.navigate(['account', 'factoring']);
      }
    });
  }
}
