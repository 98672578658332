<div class="bg"></div>

<div class="wrapper">
  <header class="pt-4 container">
    <div class="phone text-right">
      Есть вопросы?
      <img src="/assets/svg/landing/phone.svg" class="mx-3">
      <b>{{ supportPhoneFormat }}</b>
    </div>

    <div class="logo text-center">
      <img src="/assets/svg/logo.svg" width="289">
      <p class="mt-3">Платформа быстрых финансов для бизнеса</p>
    </div>

    <h1 class="text-center mt-4 mb-5">Быстрые деньги для бизнеса</h1>
  </header>

  <router-outlet></router-outlet>

  <footer class="container py-5">
    <small class="text-muted">
      © 2019 Все права защищены и охраняются законом. Использование материалов сайта разрешено только с письменного
      разрешения ООО «МайФи».<br />
      Данный интернет-сайт носит исключительно информационный характер и не является публичной офертой.
    </small>
  </footer>
</div>
