<app-header [config]="{ variant: 'white' }"></app-header>
<div class="header-spacer bg-light">
  <div class="container d-lg-none mobile-nav">
    <a class="btn btn-dark mr-2 mb-2" routerLinkActive="active" routerLink="/account/factoring">Факторинг</a>
    <a class="btn btn-dark mr-2 mb-2" routerLinkActive="active" routerLink="/account/leasing">Лизинг</a>
    <a class="btn btn-dark mr-2 mb-2" routerLinkActive="active" routerLink="/account/credit">Кредит</a>
    <a class="btn btn-dark mr-2 mb-2" routerLinkActive="active" routerLink="/account/bank-guarantee">Банковская гарантия</a>
  </div>
  <app-page-heading>
    <h2 class="m-0">{{ title }}</h2>
    <a [routerLink]="[serviceRoute, 'create']" class="btn btn-primary btn-lg ml-auto" *ngIf="showCreate()">+<span class="d-none d-md-inline"> Подать заявку</span></a>
  </app-page-heading>

  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<app-footer></app-footer>
