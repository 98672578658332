import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../services/auth.service';
import { ScoringService } from '../../services/scoring.service';
import { SepthousandPipe } from '../../shared/pipes/septhousand.pipe';
import { faAngleDown, faAngleUp, faChevronDown, faChevronUp, faCircleNotch, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfirmService } from '../../services/confirm.service';
import { creditTargets, guaranteeFzName, leasingPayTypes, MONTHS, serviceTypeNames, serviceTypeRoutes } from '../../constants';
import {
  AnyClientRequestDetailed,
  AnyOffer,
  ClientServiceType,
  ILeasingDetailed,
  IScoringValue
} from '../../types';

@Component({
  selector: 'app-page-request',
  templateUrl: './page-request.component.html',
  styleUrls: ['./page-request.component.scss']
})
export class PageRequestComponent {
  title = 'Просмотр заявки';
  serviceType: ClientServiceType;
  serviceTypeName: string;
  fileIcon = faFile;
  clientRequest: AnyClientRequestDetailed;
  offers: AnyOffer[];
  isUser: boolean;
  isFactor: boolean;
  declarerScoring: IScoringValue[];
  showDelete = false;

  readonly iconLoading = faCircleNotch;
  readonly iconLink = faExternalLinkAlt;
  readonly iconChevronUp = faChevronUp;
  readonly iconChevronDown = faChevronDown;
  readonly fzName = guaranteeFzName;

  infoVisible = false;
  docsVisible = false;

  creditTargets = creditTargets;
  leasingPayTypes = leasingPayTypes;

  isFactoring = false;
  isLeasing = false;
  isCredit = false;
  isGuarantee = false;

  deleteError = '';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthService,
    private _scoring: ScoringService,
    private _sep: SepthousandPipe,
    private _modal: NgbModal,
    private _http: HttpClient,
    private _confirm: ConfirmService
  ) {
    this.clientRequest = _route.snapshot.data.factorRequest;
    this.serviceType = _route.snapshot.data.serviceType;
    this.serviceTypeName = serviceTypeNames[this.serviceType];
    this.declarerScoring = this.clientRequest.scoring;
    this.isUser = !!_auth.authenticatedUser().acl.can_start_ua;
    this.isFactor = _auth.authenticatedUser().isFactor();

    switch (this.serviceType) {
      case ClientServiceType.FACTORING: this.isFactoring = true; break;
      case ClientServiceType.LEASING: this.isLeasing = true; break;
      case ClientServiceType.CREDIT: this.isCredit = true; break;
      case ClientServiceType.GUARANTEE: this.isGuarantee = true; break;
    }

    this.loadOffers();
  }

  chartValues() {
    return (this.clientRequest as ILeasingDetailed)
      .season_chart.map((value, i) => ({label: MONTHS[i], value: value}));
  }

  addOne(n) {
    return parseInt(n, 10) + 1;
  }

  delete() {
    this._confirm.open({ message: 'Это действие нельзя отменить. Удалить заявку?' })
      .subscribe(() => this.sendDelete());
  }

  private loadOffers() {
    this.clientRequest.loadOffers().subscribe(offers => {
      this.offers = offers;
      this.showDelete = this.isUser && !offers.length;
    });
  }

  private sendDelete() {
    this.deleteError = '';

    this.clientRequest.delete().subscribe(
      () => this._router.navigate(['account', serviceTypeRoutes[this.serviceType]]),
      (httpError: HttpErrorResponse) => this.deleteError = httpError.error.detail
    );
  }

}
