<nav>
  <ul class="pagination" [class.justify-content-center]="alignCenter">
    <li class="page-item" [class.disabled]="!hasPrev()">
      <a class="page-link"
         href="javascript:void(0)"
         [attr.aria-disabled]="!hasPrev()"
         (click)="prev()"
      >
        <fa-icon [icon]="iconChevronLeft"></fa-icon>
      </a>
    </li>

    <ng-container *ngIf="trimLeft">
      <li class="page-item">
        <a class="page-link" href="javascript:void(0)" (click)="set(1)">1</a>
      </li>
      <li class="page-item disabled" *ngIf="pages[0] != 2">
        <a class="page-link" disabled>...</a>
      </li>
    </ng-container>

    <li class="page-item" *ngFor="let iconPage of pages" [class.active]="iconPage == page">
      <a class="page-link" href="javascript:void(0)"
         [attr.aria-disabled]="iconPage == page"
         (click)="set(iconPage)"
      >
        {{ iconPage }}
      </a>
    </li>

    <ng-container *ngIf="trimRight">
      <li class="page-item disabled" *ngIf="pages[pages.length-1] != pagesCount-1">
        <a class="page-link" disabled>...</a>
      </li>
      <li class="page-item">
        <a class="page-link" href="javascript:void(0)" (click)="set(pagesCount)">{{ pagesCount }}</a>
      </li>
    </ng-container>

    <li class="page-item" [class.disabled]="!hasNext()">
      <a class="page-link"
         href="javascript:void(0)"
         [attr.aria-disabled]="!hasNext()"
         (click)="next()"
      >
        <fa-icon [icon]="iconChevronRight"></fa-icon>
      </a>
    </li>
  </ul>
</nav>
