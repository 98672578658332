import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { ActivatedRoute, Router } from '@angular/router';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AnalyticService } from '../../services/analytic.service';

@Component({
  selector: 'app-password-request-modal',
  templateUrl: './password-request-modal.component.html',
  styleUrls: ['./password-request-modal.component.scss']
})
export class PasswordRequestModalComponent implements OnInit {
  faCircleNotch = faCircleNotch;
  form: FormGroup = null;

  error: string;
  message: string;
  loading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _modal: NgbModal,
    private _http: HttpClient,
    private _router: Router,
    private _route: ActivatedRoute,
    private _analytics: AnalyticService
  ) {
    this._analytics.eventYandex('passwordResetModal_open');
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  signInClick(): void {
    this.activeModal.close('Sign in click');
    this._modal.open(SignInModalComponent, { size: 'lg', beforeDismiss: () => false });
  }

  submit() {
    this._analytics.eventYandex('passwordRequest_submit');

    this.loading = true;
    this.error = null;

    this._http.post(`${environment.apiEndpoint}/auth/send-password-form`, {
      email: this.form.get('email').value
    }).subscribe({
      next: value => {
        this.loading = false;
        this.message = 'Если данный email был зарегистрирован, на него отправлено сообщение с инструкциями по восстановлению пароля.';
      },
      error: err => {
        this.loading = false;
        this.error = err.error.detail;
      }
    });
  }

  close(): void {
    this.activeModal.close('Close button click');
  }
}
