<div class="input-group mb-3">
  <div class="input-group-prepend">
    <button #subBtn class="btn btn-light" type="button" (mousedown)="subMouseDown()" [disabled]="disabled">—</button>
  </div>
  <input
    #input="appFormat"
    class="form-control text-center"
    type="text"
    appFormat
    formatType="integer"
    [separate]="separate"
    [max]="max"
    [min]="min"
    [class.is-invalid]="invalid"
    (blur)="onTouched && onTouched()"
    [(ngModel)]="value"
    [disabled]="disabled"
  />
  <div class="input-group-append">
    <button #addBtn class="btn btn-light" type="button" (mousedown)="addMouseDown()" [disabled]="disabled">+</button>
  </div>
</div>
