<div class="partners py-5" [class]="bgClass">
  <div class="container partners py-4">
    <div class="partners-grid">
      <div class="partners-grid-text">
        <h2 class="mb-4">Партнёры</h2>
        <p>Нам доверяют крупнейшие <br />компании и лидеры рынка</p>
      </div>
      <div class="partners-grid-list">
        <ul class="check-list">
          <li>Банки</li>
          <li>Факторинговые компании</li>
          <li>Лизинговые компании</li>
          <li>Предприятия любого уровня</li>
        </ul>
      </div>
      <div class="partners-grid-link pt-4 text-center text-md-left">
        <a class="text-decoration-none mt-4" [routerLink]="'/partners'">
          Смотреть все <span class="btn-arrow align-middle ml-3"></span>
        </a>
      </div>
      <div class="partners-grid-hands partners-handshake" *ngIf="hands"></div>
    </div>
  </div>
</div>
