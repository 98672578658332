import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ServicesTypesService } from '../../services/services-types.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ScoringService } from '../../services/scoring.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  catchError,
  concatMap,
  debounceTime,
  finalize,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { concat, EMPTY, merge, Observable, of, Subscription, throwError } from 'rxjs';
import { CodeConfirmService } from '../../services/code-confirm.service';
import { AuthService } from '../../services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AnyClientRequestDetailed,
  AnyClientRequestResource,
  ClientServiceType,
  IClientService,
  IClientServiceDetailed,
  IEnrichmentResponse
} from '../../types';
import { serviceTypeClass, serviceTypeNames, serviceTypeRoutes } from '../../constants';
import { ScoringTableData } from './scoring-table-data';
import { CRManagerBuilderService } from '../../services/cr-manager-builder.service';
import { ConfirmService } from '../../services/confirm.service';
import { AnalyticService } from '../../services/analytic.service';
import { IRequestSubComponent } from './interfaces';
import { ConfirmResults } from '../../confirm-results.enum';

@Component({
  selector: 'app-page-request-create',
  templateUrl: './page-request-create.component.html',
  styleUrls: ['./page-request-create.component.scss']
})
export class PageRequestCreateComponent implements OnDestroy, OnInit {
  title = 'Подать заявку';

  @ViewChild('loanFalse', { static: true }) loanFalse;
  @ViewChild('scoringBlock', { static: true }) scoringBlock;
  @ViewChild('tabsBlock', { static: true }) tabsBlock;

  @ViewChild('subComponent', { static: false })
  set subComponent(component: IRequestSubComponent) {
    if (!component) {
      return;
    }

    this._subComponent = component;

    if (this._subFormChangeSub) {
      this._subFormChangeSub.unsubscribe();
      this._subFormChangeSub = null;
    }

    if (this.isDraft) {
      component.form.markAllAsTouched();
    }

    this._subFormChangeSub = merge(component.form.valueChanges, this.draftChanged)
      .pipe(debounceTime(5000))
      .subscribe(() => this.saveDraft());

    this._changeDetector.detectChanges();
  }

  scoring: ScoringTableData[] = [
    new ScoringTableData(this._http, this._scoring),
    new ScoringTableData(this._http, this._scoring)
  ];

  readonly form = this._fb.group({
    service: ['', Validators.required],
    docs: [[]]
  });
  readonly draftChanged = new EventEmitter<void>();
  readonly iconLoading = faCircleNotch;
  readonly ClientServiceType = ClientServiceType;

  serviceType: ClientServiceType;
  clientRequest: AnyClientRequestDetailed = null;
  isDraft = false;

  processedInn = [];
  loading = false;

  services: IClientService[] = [];
  services$: Observable<IClientService[]>;
  servicesLoading = false;
  clientRequestResource: AnyClientRequestResource;

  selectedService: IClientServiceDetailed = null;
  selectedServiceLoading = false;

  enrichProgress = 0;
  enrichOngoing = false;
  enrichOngoingInn = null;
  enrichCurrentMethod = '';
  enrichError = '';
  error = '';
  step = 1;
  showScoring = 0;
  creditLeanFailed = false;

  private _subComponent: IRequestSubComponent | null;
  private _subFormChangeSub: Subscription;

  constructor(
    public route: ActivatedRoute,
    private _servicesTypes: ServicesTypesService,
    private _crManagerBuilder: CRManagerBuilderService,
    private _fb: FormBuilder,
    private _scoring: ScoringService,
    private _http: HttpClient,
    private _router: Router,
    private _analytics: AnalyticService,
    private _codeConfirm: CodeConfirmService,
    private _auth: AuthService,
    private _modal: NgbModal,
    private _changeDetector: ChangeDetectorRef,
    private _confirm: ConfirmService
  ) {
    this.title = 'Заявка на ' + serviceTypeNames[this.route.snapshot.data.serviceType];

    if (route.snapshot.data.factorRequest) {
      this.clientRequest = route.snapshot.data.factorRequest;
      this.serviceType = this.clientRequest.service.type;
      this.clientRequestResource = this._crManagerBuilder.getByServiceType(this.clientRequest.service.type);
      this.isDraft = true;
      this.step = 2;
    } else if (route.snapshot.data.serviceType) {
      this.serviceType = route.snapshot.data.serviceType;
      this.clientRequestResource = this._crManagerBuilder.getByServiceType(this.serviceType);
    }

    this.loadServices();
  }

  ngOnInit(): void {
    this.services$ = this._servicesTypes.loadAll().pipe(
      map(services => {
        if (this.serviceType) {
          return services.filter(s => s.type === this.serviceType);
        }

        return services;
      })
    );
  }

  ngOnDestroy(): void {
    if (this._subFormChangeSub)
      this._subFormChangeSub.unsubscribe();
  }

  loadServices() {
    this.servicesLoading = true;
    this._servicesTypes.loadAll()
      .subscribe(services => {
        if (this.serviceType) {
          services = services.filter(s => s.type === this.serviceType);
        }

        if (this.isDraft) {
          this.form.get('service').setValue(this.clientRequest.service.id);
          this.selectService(this.clientRequest.service.id, false);
        }

        this.services = services;
        this.servicesLoading = false;
      });
  }

  selectService(id: number, save = true) {
    this.scoring.forEach(s => s.reset());

    if (!id) {
      this.selectedService = null;
      return;
    }

    this._analytics.eventYandex('requestCreate_changeServiceType', { params: { id } });
    this.selectedServiceLoading = true;

    this._servicesTypes.load(id)
      .subscribe(service => {
        if (this.clientRequest) {
          if (save) {
            this.draftChanged.emit();
          }
        } else {
          const serviceDetailedClass = serviceTypeClass[service.type].detailed;
          this.clientRequest = new serviceDetailedClass;
          this.services = this.services.filter(s => s.type === service.type);
          this.clientRequestResource = this._crManagerBuilder.getByServiceType(service.type);
        }

        this.selectedService = service;
        this.selectedServiceLoading = false;

        const docsByTypes = this.selectedService.doc_types.map(type => {
          if (this.clientRequest.docs) {
            return [ this.clientRequest.docs.filter(d => d.doc_type.id === type.id) ];
          } else {
            return [[]];
          }
        });
        this.form.setControl('docs', this._fb.array(docsByTypes));
        this.form.get('docs').valueChanges.subscribe(() => this.draftChanged.emit());
      });
  }

  nextStep() {
    switch (this.step) {
      case 1:
        if (!this.form.get('service').value) {
          return;
        }
        this.selectService(this.form.get('service').value);
        break;
      case 3:
        this.submit();
    }

    this.tabsBlock.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.step++;
  }

  nextStepDisabled() {
    switch (this.step) {
      case 1: return !this.form.get('service').value;
      case 2: return !this._subComponent?.form.valid;
      case 3: return this.submitDisabled();
    }
  }

  tooltipText() {
    switch (this.step) {
      case 1: return 'Выберите услугу';
      case 2: return 'Заполните форму без ошибок';
      case 3: return 'Загрузите необходимые файлы';
    }
  }

  loadInn({inn, sum = 0, period = 0}, n = 0) {
    this._analytics.eventYandex('requestCreate_scoring1');
    this.showScoring = n;
    const scoring = this.scoring[n];

    this.scoringBlock.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});

    scoring.isLoading = true;

    this.enrichInn(inn).subscribe({
      complete: () => scoring.load({inn, sum, period}).subscribe(),
      error: (errorResponse: HttpErrorResponse) => {
        const error = errorResponse.error.vat_id || errorResponse.error.__all__ || 'Ошибка обогащения анкеты';

        console.log('Inn 1 enrichment error:', error);
        scoring.error = error;
        scoring.isLoading = false;
      }
    });
  }

  saveDraft() {
    const data = this._prepareData(false);

    if (data === null) {
      return;
    }

    this.clientRequestResource.update(this.clientRequest ? this.clientRequest.id : null, data)
      .pipe(
        tap(result => {
          this.clientRequest = result;
        }),
        catchError(response => {
          console.log('Request save error: ', response);
          return throwError(response);
        })
      )
      .subscribe();
  }

  deleteDraft() {
    this._confirm.open({ message: 'Это действие нельзя отменить. Удалить черновик?' })
      .subscribe(reason => {
        if (reason === ConfirmResults.CONFIRM) {
          if (this.clientRequest && this.clientRequest.id)
            this.clientRequest.delete().subscribe();

          this._router.navigate(['account', serviceTypeRoutes[this.clientRequest.service.type]]);
        }
      });
  }

  submit() {
    const data = this._prepareData();

    if (data === null)
      return;

    if (!this.creditLeanFailed) {
      this.saveDraft();
    }

    data.draft = false;
    this.loading = true;
    this.error = null;

    const enrich$ = this._prepareScoring(data);

    const loanCheck$ = this._http.post<any>(environment.apiEndpoint + '/soft_loan', { inn: data.inn })
      .pipe(
        switchMap(value => {
          if (!value.soft_loan) {
            this.loadInn({inn: data.inn}, 0);
            this.creditLeanFailed = true;
            this.loading = false;
            this.loanFalse.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
            return throwError('Lean check fail');
          }

          return of(value);
        })
      );

    const submit$ = this.clientRequestResource.update(this.clientRequest ? this.clientRequest.id : null, data)
      .pipe(
        tap(result => {
          this._auth.authenticatedUser().legal_detail.inn = data.inn;
          this._analytics.eventYandex('requestCreate_done', { params: { id: result.id } });
          this._analytics.eventGoogle('Sended', 'Requests');
          this._router.navigate(['account', serviceTypeRoutes[this.selectedService.type], result.id]);
        }),
        catchError(response => throwError(response))
      );

    const submitProcess$ = enrich$
      .pipe(
        concatMap(value => {
          if (this.selectedService.type === ClientServiceType.CREDIT && !this.creditLeanFailed) {
            return loanCheck$;
          }

          return of(value);
        }),
        switchMap(() => {
          return submit$;
        })
      );

    submitProcess$.subscribe({
      error: error => {
        this.loading = false;
        if (error.error && error.error.detail) {
          this.error = error.error.detail;
        }
      }
    });
  }

  submitDisabled() {
    return this.servicesLoading
      || !this.form.get('service')
      || this.loading
      || this.form.invalid
      || this.enrichOngoing
      || !this._subComponent?.form.valid
      || this.creditLeanFailed;
  }

  enrichInn(inn: string): Observable<IEnrichmentResponse> {
    if (!inn || this.processedInn.indexOf(inn) !== -1) {
      return EMPTY;
    }

    this._analytics.eventYandex('requestCreate_enrichInn', { params: { inn } });
    this.enrichOngoingInn = inn;
    this.enrichOngoing = true;

    return this._scoring.enrich(inn).pipe(
      tap(value => {
        this.enrichOngoing = true; // todo хак. разобраться, почему отключается прогресс-бар
        this.enrichProgress = value.progress;
        this.enrichCurrentMethod = value.label;
      }),
      catchError(err => this._getEnrichError(err)),
      finalize(() => {
        this._resetEnrich();
        this.processedInn.push(inn);
      })
    );
  }

  private _prepareScoring(data) {
    this.enrichError = '';

    return new Observable<any>(subscriber => {
      concat(
        // Wrappers for delayed call
        of(0).pipe(switchMap(() => this.enrichInn(data.inn))),
        of(0).pipe(switchMap(() => this.enrichInn(data.inn_second)))
      ).subscribe({
        complete: () => {
          subscriber.next(data);
        },

        error: error => {
          subscriber.error(error);
          this.loading = false;
        }
      });
    });
  }

  private _prepareData(checkValid = true) {
    if (checkValid) {
      const valid = this.form.valid && this._subComponent?.form.valid;
      if (!valid) {
        return null;
      }
    }

    const data = Object.assign({}, this.form.value);
    Object.assign(data, this._subComponent?.exportData());

    // flatten IDoc[][] -> Doc.id[]
    data.docs = data.docs.flatMap(docsArray => docsArray.map(doc => doc.id));

    if (!data.inn_second) {
      data.inn_second = '';
    }

    // if (!data.contract_period && data.credit_term) {
    //   data.contract_period = data.credit_term * 30; // todo сказать бекендеру отрубить это поле
    // }

    return data;
  }

  private _getEnrichError(error) {
    this._resetEnrich();
    this.enrichError = error['__all__'] || error;
    return throwError(error);
  }

  private _resetEnrich() {
    this.enrichProgress = 0;
    this.enrichOngoing = false;
  }
}
