import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-ya-map',
  templateUrl: './ya-map.component.html',
  styleUrls: ['./ya-map.component.scss'],
  host: {
    '[style.height]': 'style.height',
    '[style.width]': 'style.width'
  }
})
export class YaMapComponent implements OnInit {
  map: Object;
  style = {
    width: '300px',
    height: '200px'
  };

  @Input()
  set width(w: string) {
    this.style.width = w;
  }

  @Input()
  set height(h: string) {
    this.style.height = h;
  }

  constructor(private _element: ElementRef, @Inject(PLATFORM_ID) private _platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      window['ymaps'].ready(() => {
        this.map = new window['ymaps'].Map(this._element.nativeElement, {
          center: [55.840097, 37.491853],
          zoom: 11
        });

        this.map['geoObjects']
          .add(new window['ymaps'].Placemark([55.840097, 37.491853], {}, {
            iconLayout: 'default#image',
            iconImageHref: '/assets/svg/map-placemark.svg',
            iconImageSize: [28, 37],
            iconImageOffset: [-14, -37]
          }));
      });
    }
  }
}
