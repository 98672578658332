import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bg-text',
  templateUrl: './bg-text.component.html',
  styleUrls: ['./bg-text.component.scss']
})
export class BgTextComponent implements OnInit {
  @Input() bg = '#FFFFFF';
  @Input() color = 'inherit';

  style = {
    backgroundColor: this.bg,
    color: this.color
  };

  constructor() { }

  ngOnInit(): void {
    this.style = {
      backgroundColor: this.bg,
      color: this.color
    };
  }
}
