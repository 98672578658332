import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormControl } from '@angular/forms';

export function phoneAsyncValidator(http: HttpClient) {
  return (phoneInput: FormControl) => {
    return http.post(environment.apiEndpoint + '/users/factoring', { mobile_phone: phoneInput.value })
    .pipe(
      debounceTime(2000),
      catchError(err => {
        if (err.error && err.error.mobile_phone) {
          phoneInput.markAsTouched();
          return of({backend: err.error.mobile_phone});
        }

        return of(null);
      })
    );
  };
}
