import { Component, Input, OnInit } from '@angular/core';
import { PartnersBlockConfig } from './partners-block-config.interface';

@Component({
  selector: 'app-partners-block',
  templateUrl: './partners-block.component.html',
  styleUrls: ['./partners-block.component.scss']
})
export class PartnersBlockComponent implements OnInit {
  private static _defaultConfig: PartnersBlockConfig = {
    variant: 'white',
    hands: true
  };

  @Input() set variant(variant) {
    this.bgClass = `bg-${variant}`;
  }

  @Input() hands = true;

  @Input() set config(config: PartnersBlockConfig) {
    Object.assign(this, PartnersBlockComponent._defaultConfig);

    if (config.hasOwnProperty('variant')) {
      this.variant = config.variant;
    } else {
      this.variant = PartnersBlockComponent._defaultConfig.variant;
    }

    if (config.hasOwnProperty('variant')) {
      this.hands = config.hands;
    } else {
      this.hands = PartnersBlockComponent._defaultConfig.hands;
    }
  }

  bgClass = 'bg-white';

  constructor() { }

  ngOnInit(): void {
  }

}
