<div class="modal-header">
  <h2 class="modal-title">{{ title }}</h2>
</div>
<div class="modal-body text-center pb-4">
  <h3 class="mb-5" *ngIf="message">{{ message }}</h3>
  <p class="text-danger mb-3" *ngIf="errorMessage">{{ errorMessage }}</p>
  <p>
    <fa-icon [spin]="true" [icon]="iconLoading" *ngIf="loading" class="mr-3"></fa-icon>
    <button class="btn btn-danger btn-lg mr-3" (click)="confirm()" [disabled]="loading">{{ confirmText }}</button>
    <button class="btn btn-primary btn-lg" (click)="dismiss()" [disabled]="loading">{{ dismissText }}</button>
  </p>
</div>
