import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faCircleNotch, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../user/user.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { PHONE_REGEX, SMS_CODE_REGEX } from '../../constants';
import { ConfirmCodeInterceptor } from '../../interceptors/confirm-code-interceptor';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AnalyticService } from '../../services/analytic.service';

@Component({
  selector: 'app-phone-confirm-modal',
  templateUrl: './phone-confirm-modal.component.html',
  styleUrls: ['./phone-confirm-modal.component.scss']
})
export class PhoneConfirmModalComponent {
  phoneForm = this._fb.group({
    new_mobile_phone: ['', [Validators.required, Validators.pattern(PHONE_REGEX)]]
  });

  codeForm = this._fb.group({
    sms_code: ['', [Validators.required, Validators.pattern(SMS_CODE_REGEX)]]
  });

  iconLoading = faCircleNotch;
  iconEdit = faPencilAlt;
  backendErrors: {[k: string]: string} = {};
  actionSub: Subscription;
  loading = false;
  step = 0;

  get phone() {
    return this.phoneForm.get('new_mobile_phone');
  }

  get code() {
    return this.codeForm.get('sms_code');
  }

  constructor(
    public activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private _userService: UserService,
    private _auth: AuthService,
    private _analytics: AnalyticService,
    private _router: Router
  ) {
    this._analytics.eventYandex('phoneConfirmModal_open');
  }

  resetBackendErrors() {
    this.backendErrors = {};
  }

  reset() {
    if (this.actionSub) {
      this.actionSub.unsubscribe();
    }

    this.codeForm.reset();
    this.backendErrors = {};
    this.loading = false;
    this.step = 0;
  }

  nextStep() {
    if (!this._auth.isAuthenticated()) {
      this.activeModal.dismiss();
      return;
    }

    if (this.actionSub) {
      this.actionSub.unsubscribe();
    }

    this.resetBackendErrors();
    this.loading = true;

    if (this.step === 2) {
      this._analytics.eventYandex('phoneConfirmModal_codeSent');
    } else {
      this._analytics.eventYandex('phoneConfirmModal_phoneSent');
    }

    this.actionSub = this._userService.updatePhone(
      this._auth.authenticatedUser().id,
      this._auth.authenticatedUser().mobile_phone,
      this.phone.value,
      this.code.value
    )
      .subscribe(
        () => {
          this.loading = false;
          this.step++;

          if (this.step === 2) {
            this._auth.checkAuthentication().subscribe();
          }
        },

        (response: HttpErrorResponse) => {
          this.loading = false;

          if (this.step === 0 && ConfirmCodeInterceptor.isResponseAboutNullCode(response)) {
            this.step = 1;
          } else {
            if (typeof response.error === 'string') {
              this.backendErrors = {non_field_errors: `${response.status} ${response.statusText} ${response.error}`};
            } else {
              this.backendErrors = response.error;
            }
          }
        }
      );
  }
}
