<div class="spoiler" [class.opened]="opened">
  <div class="container">
    <h3 class="title pl-4 pl-lg-5 mb-4" (click)="opened = !opened">{{ title }}</h3>
  </div>
  <div class="spoiler-container bg-light">
    <div class="container">
      <div class="pl-4 pl-lg-5 pt-5 pb-5 spoiler-wrapper">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
