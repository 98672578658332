import { AfterViewInit, Component, ComponentRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ClientRequestResource } from '../../../client-request-resource';
import { IClientRequest, IClientRequestDetailed, IDataPage } from '../../../types';
import { RequestsFilter } from '../requests-filter/requests-filter';
import { Observable, Subscription } from 'rxjs';
import { IRequestsFilter } from '../requests-filter/requests-filter.interface';
import { ActivatedRoute } from '@angular/router';
import { ClosedRequestsService } from '../../../services/closed-requests.service';
import { PaginatorComponent } from '../../../shared/components/paginator/paginator.component';
import { serviceTypeRoutes } from '../../../constants';

@Component({
  selector: 'app-requests-cards',
  templateUrl: './requests-cards.component.html',
  styleUrls: ['./requests-cards.component.scss']
})
export class RequestsCardsComponent implements OnInit, OnDestroy {
  @Input() resource: ClientRequestResource<IClientRequest, IClientRequestDetailed>;
  @Input() archive = false;

  dataPage$: Observable<IDataPage<IClientRequest>>;
  pageSize = 11;
  page = this._route.snapshot.queryParams.page || 1;

  private _filter: RequestsFilter = new RequestsFilter();
  private _closedRequestsSub: Subscription;

  constructor(private _route: ActivatedRoute, private _closedRequests: ClosedRequestsService) {
  }

  ngOnInit(): void {
    this._closedRequestsSub = this._closedRequests.onClose.subscribe(() => {
      this.loadPage();
    });

    this.loadPage();
  }

  ngOnDestroy(): void {
    if (this._closedRequestsSub) {
      this._closedRequestsSub.unsubscribe();
    }
  }

  loadPage(page?: number) {
    if (page && page !== this.page) {
      this.page = page;
    }

    this.dataPage$ = this.resource.page(this._filter.toPageOptions({
      page: this.page,
      archive: this.archive
    }));
  }

  updateFilter(filterData: IRequestsFilter) {
    this._filter.fill(filterData);
    this.loadPage(1);
  }

  getPageCount(totalCount: number): number {
    return Math.ceil(totalCount / this.pageSize);
  }

  getPageAddress() {
    return '/account/' + serviceTypeRoutes[this.resource.serviceType];
  }
}
