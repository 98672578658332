import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing.component';
import { PageLandingIndexComponent } from './page-landing-index/page-landing-index.component';
import { PageLandingRequestComponent } from './page-landing-request/page-landing-request.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: PageLandingIndexComponent,
      },
      {
        path: 'go',
        component: PageLandingRequestComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LandingRoutingModule {
}
