import { Component } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { RequestFormDataService } from '../request-form-data.service';
import { Router } from '@angular/router';
import { ServicesTypesService } from '../../services/services-types.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { ScoringService } from '../../services/scoring.service';
import { FormControl, Validators } from '@angular/forms';
import { PHONE_REGEX } from '../../constants';
import { phoneAsyncValidator } from '../phone-async-validator';
import { IClientServiceDetailed, IFactoringDetailed } from '../../types';

const endpoint = environment.apiEndpoint + '/users/factoring';

@Component({
  selector: 'app-page-landing-request',
  templateUrl: './page-landing-request.component.html',
  styleUrls: ['./page-landing-request.component.scss']
})
export class PageLandingRequestComponent {
  iconBack = faArrowLeft;
  rate = 15;

  loading = true;
  errors: {[k: string]: any} = {};
  public form;
  calcForm = this.requestFormService.calcForm;
  phoneControl = new FormControl(
    '',
    [Validators.required, Validators.pattern(PHONE_REGEX)],
    phoneAsyncValidator(this._http)
  );
  service: IClientServiceDetailed;
  phone = '';
  phoneCheckStage = false;

  constructor(
    public requestFormService: RequestFormDataService,
    private _router: Router,
    private _auth: AuthService,
    private _scoring: ScoringService,
    private _serviceTypesService: ServicesTypesService,
    private _http: HttpClient
  ) {
    requestFormService.onInit.subscribe(() => {
      if (requestFormService.selected) {
        this.form = this.requestFormService.requestForm;

        _serviceTypesService.load(requestFormService.selected.id).subscribe(service => {
          this.service = service;
          this.loading = false;
        });
      } else {
        _router.navigate(['landing']);
      }
    });
  }

  submit() {
    const data = this.prepareData();

    if (data === null) {
      return;
    }

    this.phoneCheckStage = true;
  }

  submitPhone() {
    const data = this.prepareData();

    if (data === null || this.phoneControl.invalid) {
      return;
    }

    data.mobile_phone = this.phoneControl.value;

    const submit$ = this._http.post<IFactoringDetailed>(endpoint, data)
      .pipe(
        tap(result => {
          this.requestFormService.reset();
          this._router.navigate(['account', 'factoring']);
        }),
        catchError(response => {
          console.log('Request create error: ', response);
          this.loading = false;
          return throwError(response);
        })
      );

    this.loading = true;
    this.errors = {};

    submit$.subscribe(
      value => {
        this.loading = false;
        this.phoneCheckStage = true;
        console.log('Process result: ', value);
      },

      error => {
        this.loading = false;
        this.errors = error.error || {};
        console.log('Process error: ', error);
      }
    );
  }

  private prepareData() {
    if (!this.form.valid) {
      return null;
    }

    const data = this.form.value;
    data.email = this.calcForm.value.email;

    if (!data.inn_second) {
      delete data.inn_second;
    }

    return data;
  }
}
