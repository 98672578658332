import { IDoc, FileUploadEventType, IDocType, IFileUploadEvent } from './interfaces';

export class FileUploadEventBuilder {
  constructor(
    private docTypeId: IDocType['id'] | null,
    private fileName: string
  ) {}

  buildSelect(): IFileUploadEvent {
    return this._build(FileUploadEventType.Select);
  }

  buildStart(): IFileUploadEvent {
    return this._build(FileUploadEventType.Start);
  }

  buildProgress(progress: number): IFileUploadEvent {
    return this._build(FileUploadEventType.Progress, progress);
  }

  buildComplete(response: IDoc): IFileUploadEvent {
    return this._build(FileUploadEventType.Complete, 100, response);
  }

  buildError(errorMessage: string): IFileUploadEvent {
    return this._build(FileUploadEventType.Error, 0, null, errorMessage);
  }

  private _build(type: FileUploadEventType, progress = 0, response?: IDoc, message?: string): IFileUploadEvent {
    return {
      docTypeId: this.docTypeId,
      filename: this.fileName,
      type,
      progress,
      response,
      errorMessage: message
    };
  }
}
