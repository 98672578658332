import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { httpDefaultHeaders } from '../constants';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = Object.assign({}, httpDefaultHeaders);
    const accept = req.headers.get('Accept');
    let hasVersion = false;

    if (accept) {
      hasVersion = !!accept.split(';').find(value => value.toLowerCase().includes('version'));
    }

    if (!hasVersion) {
      delete headers.Accept;
    }

    req = req.clone({setHeaders: httpDefaultHeaders});
    return next.handle(req);
  }
}
