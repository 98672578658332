import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ClientServiceType, IClientService, IClientServiceDetailed } from '../types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServicesTypesService {
  private _services: IClientService[] = null;
  private _serviceDetails: {[id: number]: IClientServiceDetailed} = {};

  private _all$ = new Observable<IClientService[]>(subscriber => {
    if (this._services) {
      subscriber.next(this._services);
      subscriber.complete();
    } else {
      this._http.get<IClientService[]>(environment.apiEndpoint + '/services')
        .subscribe({
          next: services => subscriber.next(this._services = services),
          error: e => subscriber.error(e),
          complete: () => subscriber.complete()
        });
    }
  });

  constructor(private _http: HttpClient) { }

  loadAll(): Observable<IClientService[]> {
    return this._all$;
  }

  loadByType(serviceType: ClientServiceType): Observable<IClientService[]> {
    return this.loadAll().pipe(
      map(services => services.filter(s => s.type === serviceType))
    );
  }

  load(id: number): Observable<IClientServiceDetailed> {
    return new Observable<IClientServiceDetailed>(subscriber => {
      if (this._serviceDetails[id]) {
        subscriber.next(this._serviceDetails[id]);
        subscriber.complete();
      } else {
        this._http.get<IClientServiceDetailed>(`${environment.apiEndpoint}/services/${id}`)
          .subscribe({
            next: service => subscriber.next(this._serviceDetails[id] = service),
            error: e => subscriber.error(e),
            complete: () => subscriber.complete()
          });
      }
    });
  }
}
