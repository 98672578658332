import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { BASE_TITLE } from "./constants";
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MyFi';
  hideLayout = false;
  metrikaId = environment.yaMetrikaId;

  constructor(private _title: Title) {
  }

  onActivate($event) {
    let title = `${BASE_TITLE}`;

    if ($event.title) {
      title += ` / ${$event.title}`;
    }

    this._title.setTitle(title);
  }
}
