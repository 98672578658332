import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AnyClientRequestResource,
  ClientServiceType,
  CreditResource,
  FactoringResource, GuaranteeResource,
  LeasingResource
} from '../types';
import { ClientRequestResource } from '../client-request-resource';
import { Factoring } from '../../models/client-request/factoring';
import { FactoringDetailed } from '../../models/client-request/factoring-detailed';
import { Leasing } from '../../models/client-request/leasing';
import { LeasingDetailed } from '../../models/client-request/leasing-detailed';
import { Credit } from '../../models/client-request/credit';
import { CreditDetailed } from '../../models/client-request/credit-detailed';
import { Guarantee } from '../../models/client-request/guarantee';
import { GuaranteeDetailed } from '../../models/client-request/guarantee-detailed';

@Injectable({
  providedIn: 'root'
})
export class CRManagerBuilderService {
  private _factoring: ClientRequestResource<Factoring, FactoringDetailed>;
  private _leasing: ClientRequestResource<Leasing, LeasingDetailed>;
  private _credit: ClientRequestResource<Credit, CreditDetailed>;
  private _guarantee: ClientRequestResource<Guarantee, GuaranteeDetailed>;

  constructor(private _http: HttpClient) {}

  getFactoring(): FactoringResource {
    return this._factoring || (this._factoring = ClientRequestResource.buildFactoring(this._http));
  }

  getLeasing(): LeasingResource {
    return this._leasing || (this._leasing = ClientRequestResource.buildLeasing(this._http));
  }

  getCredit(): CreditResource {
    return this._credit || (this._credit = ClientRequestResource.buildCredit(this._http));
  }

  getGuarantee(): GuaranteeResource {
    return this._guarantee || (this._guarantee = ClientRequestResource.buildGuarantee(this._http));
  }

  getByServiceType(id: ClientServiceType): AnyClientRequestResource {
    switch (id) {
      case ClientServiceType.FACTORING: return this.getFactoring();
      case ClientServiceType.LEASING: return this.getLeasing();
      case ClientServiceType.CREDIT: return this.getCredit();
      case ClientServiceType.GUARANTEE: return this.getGuarantee();
    }

    throw new ErrorEvent('Wrong service type id');
  }
}
