import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesTypesService } from '../../../services/services-types.service';
import { ClientServiceType, IClientService } from '../../../types';
import { Observable } from 'rxjs';
import { Options } from '@angular-slider/ngx-slider';
import { MAX_CONTRACT_SUM, MIN_CONTRACT_SUM } from '../../../constants';
import { IRequestsFilter } from './requests-filter.interface';

const basicRangeOptions: Options = {
  enforceRange: false,
  animate: false,
  animateOnMove: false,
  hideLimitLabels: true,
  boundPointerLabels: true,
  hidePointerLabels: true
};

@Component({
  selector: 'app-requests-filter',
  templateUrl: './requests-filter.component.html',
  styleUrls: ['./requests-filter.component.scss']
})
export class RequestsFilterComponent implements OnInit {
  @Input() serviceType: ClientServiceType;
  @Output() filterChange: EventEmitter<IRequestsFilter> = new EventEmitter<IRequestsFilter>();

  MIN_CONTRACT_SUM = MIN_CONTRACT_SUM;
  MAX_CONTRACT_SUM = MAX_CONTRACT_SUM;

  services$: Observable<IClientService[]>;

  filterForm: FormGroup;
  daysIn3Years: number;

  sumRangeOptions = Object.assign({
    floor: MIN_CONTRACT_SUM,
    ceil: MAX_CONTRACT_SUM,
    logScale: true
  }, basicRangeOptions);

  // termRangeOptions = Object.assign({
  //   floor: 1,
  //   ceil: this.daysIn3Years,
  // }, basicRangeOptions);

  constructor(private _fb: FormBuilder, private _services: ServicesTypesService) {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 3);
    this.daysIn3Years = (+date - +new Date()) / (1000 * 60 * 60 * 24) | 0;
  }

  ngOnInit(): void {
    this.services$ = this._services.loadByType(this.serviceType);

    this.filterForm = this._fb.group({
      service: [null],
      contract_sum_min: [0, [ Validators.required, Validators.min(MIN_CONTRACT_SUM), Validators.max(MAX_CONTRACT_SUM) ]],
      contract_sum_max: [MAX_CONTRACT_SUM, [ Validators.required, Validators.min(MIN_CONTRACT_SUM), Validators.max(MAX_CONTRACT_SUM) ]],
      // term_sum_min: [1, [ Validators.required, Validators.min(1), Validators.max(this.daysIn3Years) ]],
      // term_sum_max: [180, [ Validators.required, Validators.min(1), Validators.max(this.daysIn3Years) ]]
    });
  }

  submit() {
    this.filterChange.emit(this.filterForm.value as IRequestsFilter);
  }

  reset() {
    this.filterForm.setValue({
      service: null,
      contract_sum_min: 0,
      contract_sum_max: MAX_CONTRACT_SUM,
    });
    this.filterChange.emit({} as IRequestsFilter);
  }
}
