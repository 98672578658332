import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpModalComponent } from '../../../modals/sign-up-modal/sign-up-modal.component';
import { SignInModalComponent } from '../../../modals/sign-in-modal/sign-in-modal.component';
import { Router } from '@angular/router';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { SignUpFormComponent } from '../sign-up-form/sign-up-form.component';
import { AnalyticService } from '../../../services/analytic.service';

@Component({
  selector: 'app-sign-up-widget',
  templateUrl: './sign-up-widget.component.html',
  styleUrls: ['./sign-up-widget.component.scss']
})
export class SignUpWidgetComponent {
  constructor(
    private _modals: NgbModal,
    private _router: Router,
    private _analytics: AnalyticService
  ) { }

  signUp() {
    const page = this._router.url.split('/')[1] || 'index';
    this._analytics.eventYandex(page + '_signUpButtonWidget');
    this._modals.open(SignUpModalComponent, { size: 'lg', beforeDismiss: () => false });
  }

  signIn() {
    const page = this._router.url.split('/')[1] || 'index';
    this._analytics.eventYandex(page + '_signInButtonWidget');
    this._modals.dismissAll('open sign in');
    this._modals.open(SignInModalComponent, { size: 'lg', beforeDismiss: () => false });
    return false;
  }
}
