import { Component } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { ConfirmResults } from "../../confirm-results.enum";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  title;
  message;
  confirmText;
  dismissText;

  /* implemented but not used */
  iconLoading = faCircleNotch;
  loading = false;
  errorMessage = '';

  constructor(private _activeModal: NgbActiveModal) { }

  close() {
    this._activeModal.close(ConfirmResults.CLOSE);
  }

  dismiss() {
    this._activeModal.dismiss(ConfirmResults.DISMISS);
  }

  confirm() {
    this._activeModal.close(ConfirmResults.CONFIRM);
  }
}
