import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoComponent } from './components/logo/logo.component';
import { CardIndexComponent } from './components/card-index/card-index.component';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { PageHeadingComponent } from './components/page-heading/page-heading.component';
import { YaMapComponent } from './components/ya-map/ya-map.component';
import { YaMapModule } from './components/ya-map/ya-map.module';
import { SignUpWidgetComponent } from './components/sign-up-widget/sign-up-widget.component';
import { RouterModule } from '@angular/router';
import { SignUpFormComponent } from './components/sign-up-form/sign-up-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { RequestTableComponent } from './components/request-table/request-table.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter, NgbDateParserFormatter,
  NgbDatepickerModule, NgbDropdownModule,
  NgbPopoverModule,
  NgbProgressbarModule
} from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from './components/chat/chat.component';
import { NgxFilesizeModule as FileSizeModule } from 'ngx-filesize';
import { ScoringTableComponent } from './components/scoring-table/scoring-table.component';
import { RequestContentUserComponent } from './components/request-content-user/request-content-user.component';
import { RequestContentFactorComponent } from './components/request-content-factor/request-content-factor.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { AskAQuestionComponent } from './components/ask-a-question/ask-a-question.component';
import { SignUpWidgetFormComponent } from './components/sign-up-widget-form/sign-up-widget-form.component';
import { SepthousandPipe } from './pipes/septhousand.pipe';
import { VerticalColumnChartComponent } from './components/vertical-column-chart/vertical-column-chart.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { DownloadFileLinkComponent } from './components/download-file-link/download-file-link.component';
import { InputFormatDirective } from './directives/input-format/input-format.directive';
import { MarkedPipe } from './pipes/marked.pipe';
import { BgTextComponent } from './components/bg-text/bg-text.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { ConsultBlockComponent } from './components/consult-block/consult-block.component';
import { FullWidthSpoilerComponent } from './components/full-width-spoiler/full-width-spoiler.component';
import { HeaderDropdownComponent } from './components/header-dropdown/header-dropdown.component';
import { HowItWorksListComponent } from './components/how-it-works-list/how-it-works-list.component';
import { PartnersBlockComponent } from './components/partners-block/partners-block.component';
import { OpenConsultDirective } from './directives/open-consult/open-consult.directive';
import { CustomDateParserFormatter } from '../services/custom-date-parser-formatter.service';
import { NgxMaskModule } from 'ngx-mask';
import { BgTextContactsComponent } from './components/bg-text-contacts/bg-text-contacts.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { PluralizeRuPipe } from './pipes/pluralize-ru.pipe';
import { ChangersInputComponent } from './components/changers-input/changers-input.component';

@NgModule({
  declarations: [
    LogoComponent,
    CardIndexComponent,
    BreadcrumbsComponent,
    PageHeadingComponent,
    SignUpWidgetComponent,
    SignUpFormComponent,
    HeaderComponent,
    FooterComponent,
    HeaderMenuComponent,
    RequestTableComponent,
    PaginatorComponent,
    FileUploaderComponent,
    ChatComponent,
    ScoringTableComponent,
    RequestContentUserComponent,
    RequestContentFactorComponent,
    HowItWorksComponent,
    AskAQuestionComponent,
    SignUpWidgetFormComponent,
    SepthousandPipe,
    VerticalColumnChartComponent,
    InfoIconComponent,
    DownloadFileLinkComponent,
    BgTextComponent,
    CalculatorComponent,
    ConsultBlockComponent,
    FullWidthSpoilerComponent,
    HeaderDropdownComponent,
    HowItWorksListComponent,
    PartnersBlockComponent,

    // Directives
    InputFormatDirective,
    OpenConsultDirective,

    // Pipes
    MarkedPipe,
    SepthousandPipe,
    BgTextContactsComponent,
    RangeSliderComponent,
    PluralizeRuPipe,
    ChangersInputComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    YaMapModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    FileSizeModule,
    NgbPopoverModule,
    NgxMaskModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    NgbDropdownModule
  ],
  exports: [
    LogoComponent,
    CardIndexComponent,
    BreadcrumbsComponent,
    PageHeadingComponent,
    SignUpFormComponent,
    SignUpWidgetComponent,
    SignUpWidgetFormComponent,
    HeaderComponent,
    FooterComponent,
    HeaderMenuComponent,
    RequestTableComponent,
    PaginatorComponent,
    FileUploaderComponent,
    ChatComponent,
    ScoringTableComponent,
    RequestContentUserComponent,
    RequestContentFactorComponent,
    HowItWorksComponent,
    AskAQuestionComponent,
    VerticalColumnChartComponent,
    InfoIconComponent,
    DownloadFileLinkComponent,
    YaMapComponent,
    BgTextComponent,
    CalculatorComponent,
    ConsultBlockComponent,
    FullWidthSpoilerComponent,
    HeaderDropdownComponent,
    HowItWorksListComponent,
    PartnersBlockComponent,
    BgTextContactsComponent,
    ChangersInputComponent,

    // Directives
    InputFormatDirective,
    OpenConsultDirective,

    // Pipes
    SepthousandPipe,
    MarkedPipe,
    PluralizeRuPipe,
    ChangersInputComponent
  ],
  providers: [
    SepthousandPipe,
    MarkedPipe,
    PluralizeRuPipe,
    [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
    [{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
  ]
})
export class SharedModule { }
