import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { SignUpModalComponent } from '../sign-up-modal/sign-up-modal.component';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PasswordRequestModalComponent } from '../password-request-modal/password-request-modal.component';
import { ClosedRequestsService } from '../../services/closed-requests.service';
import { AnalyticService } from '../../services/analytic.service';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent implements OnInit {
  faCircleNotch = faCircleNotch;
  form: FormGroup = null;
  error: string;
  message: string;
  loading = false;
  inited = false;

  private url = this._router.url.split('?').shift();

  constructor(
    public activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _modal: NgbModal,
    private _router: Router,
    private _route: ActivatedRoute,
    private _analytics: AnalyticService,
    private _checkRequests: ClosedRequestsService
  ) {
    this._analytics.eventYandex('signInModal_open', { params: { url: this.url } });
    this._analytics.eventGoogle('Open', 'Modals', 'Sign In');
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
    this.inited = true;
  }

  signUpClick(): void {
    this.activeModal.close('Sign up click');
    this._modal.open(SignUpModalComponent, { size: 'lg', beforeDismiss: () => false });
  }

  resetClick(): void {
    this.activeModal.close('Reset password click');
    this._modal.open(PasswordRequestModalComponent, { size: 'lg', beforeDismiss: () => false });
  }

  submit() {
    this._analytics.eventYandex('signInModal_submit', { params: { url: this.url } });

    this.loading = true;
    this._auth
      .login(this.form.value.email, this.form.value.password)
      .pipe(
        tap(() => {
          this.activeModal.close('Authentication success');
          this._checkRequests.performCheck();
          this.loading = false;
        }),
        switchMap(() => this._route.queryParamMap.pipe(
          filter(params => params.has('next')),
          switchMap(params => this._router.navigateByUrl(params.get('next')))
        )),
        catchError(data => {
          this.loading = false;
          this.error = data.error.detail || data.error.non_field_errors;
          return of(null);
        })
      )
      .subscribe();

    return false;
  }

  isEmailRequired(): boolean {
    return this.inited && this.form.controls.email.touched
      && this.form.controls.email.errors
      && this.form.controls.email.errors.required;
  }

  isPasswordRequired(): boolean {
    return this.inited && this.form.controls.password.touched
      && this.form.controls.password.errors
      && this.form.controls.password.errors.required;
  }

  isEmailWrong(): boolean {
    return this.inited && this.form.controls.email.touched
      && this.form.controls.email.errors
      && this.form.controls.email.errors.email;
  }
}
