import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { User } from '../user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FakeSsrStorage } from '../../models/fake-ssr-storage';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static _tokenEndpoint: string = environment.apiEndpoint + '/token';
  private static _authEndpoint: string = environment.apiEndpoint + '/auth';

  private _tokenStorage: Storage;
  private _authenticatedUser: User = null;
  private _update: EventEmitter<User> = new EventEmitter<User>();

  constructor(private _http: HttpClient, @Inject(PLATFORM_ID) platformId: Object) {
    this._tokenStorage = isPlatformBrowser(platformId) ? localStorage : new FakeSsrStorage();
  }

  onUpdate(): Observable<User> {
    return this._update.asObservable();
  }

  getToken(): string | null {
    return this._tokenStorage.getItem('jwt') || null;
  }

  authenticateByToken(token: string): Observable<User|null> {
    this._updateToken(token);
    return this.checkAuthentication();
  }

  authenticatedUser(): User {
    return this._authenticatedUser;
  }

  isAuthenticated(): boolean {
    return this.authenticatedUser() != null;
  }

  checkAuthentication(): Observable<User> {
    const token = this.getToken();

    if (token) {
      return this._http.get<User>(AuthService._authEndpoint)
        .pipe(
          map(user => this._authenticate(user)),
          catchError(error => {
            if (error.status === 401) {
              return this.logout();
            }
          })
        );
    } else {
      return this.logout();
    }
  }

  login(username: string, password: string): Observable<User> {
    return this._http.post<{token: string}>(AuthService._tokenEndpoint, { username, password })
      .pipe(switchMap(data => data.token ? this.authenticateByToken(data.token) : this.checkAuthentication()));
  }

  logout(): Observable<User|null> {
    this._removeToken();
    return of(this._authenticate(null));
  }

  private _authenticate(user: User): User {
    this._authenticatedUser = (user && user.is_authenticated) ? User.from(user) : null;
    this._update.next(this._authenticatedUser);
    return this._authenticatedUser;
  }

  private _updateToken(token: string): void {
    this._tokenStorage.setItem('jwt', token);
  }

  private _removeToken(): void {
    this._tokenStorage.removeItem('jwt');
  }
}
