<div class="container">
  <div class="mb-4">
    <a [routerLink]="clientRequest.getCategoryUrl()" class="d-inline-flex align-items-center text-dark text-decoration-none">
      <span class="btn-arrow btn-arrow-back btn-arrow-grey mr-4"></span> Вернуться назад
    </a>
  </div>

  <div class="row">
    <div class="col-12 col-lg-4 col-xl-3">
      <div class="mb-4">
        <div class="info-block-heading bg-primary text-light p-4" (click)="infoVisible = !infoVisible" (selectstart)="$event.preventDefault()">
          Заявитель
          <fa-icon [icon]="infoVisible ? iconChevronUp : iconChevronDown" class="float-right"></fa-icon>
        </div>
        <div class="bg-white p-4" *ngIf="infoVisible">
          <p class="request-info__line clearfix">
            <span class="request-info__name">№ заявки</span>
            <span class="request-info__value">{{ clientRequest.id }}</span>
          </p>
          <p class="request-info__line clearfix">
            <span class="request-info__name">Тип заявки</span>
            <span class="request-info__value">{{ clientRequest.service.name }}</span>
          </p>
          <p class="request-info__line clearfix">
            <span class="request-info__name">Дата подачи</span>
            <span class="request-info__value">{{ clientRequest.created_at | date:'dd.MM.y' }}</span>
          </p>
          <p class="request-info__line clearfix">
            <span class="request-info__name">ИНН компании заявителя</span>
            <span class="request-info__value">{{ clientRequest.company_declarer?.inn }}</span>
          </p>
          <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer?.name">
            <span class="request-info__name">Название компании заявителя</span>
            <span class="request-info__value">{{ clientRequest.company_declarer.name }}</span>
          </p>
          <ng-container *ngIf="isFactoring">
            <p class="request-info__line clearfix">
              <span class="request-info__name">Сумма заявки (рублей)</span>
              <span class="request-info__value">{{ clientRequest.contract_sum | septhousand }}</span>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Срок отсрочки (дней)</span>
              <span class="request-info__value">{{ clientRequest.contract_period }}</span>
            </p>
          </ng-container>

          <ng-container *ngIf="isCredit">
            <p class="request-info__line clearfix">
              <span class="request-info__name">Цель кредита</span>
              <span class="request-info__value">{{ creditTargets[clientRequest.credit_target].label }}</span>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Срок, мес</span>
              <span class="request-info__value">{{ clientRequest.credit_term }}</span>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Сумма, руб</span>
              <span class="request-info__value">{{ clientRequest.sum | septhousand }}</span>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Выручка за последние 5 кварталов, руб</span>
              <b class="d-block" *ngFor="let quarter of clientRequest.revenue | keyvalue">{{ addOne(quarter.key) }}-й квартал: {{ clientRequest.revenue[quarter.key] | septhousand }} руб</b>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Отсутствие убытков более двух отчетных периодов подряд</span>
              <span class="request-info__value">{{ clientRequest.material_losses ? 'Да' : 'Нет' }}</span>
            </p>
          </ng-container>

          <ng-container *ngIf="isLeasing">
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Предмет лизинга</span>
              <span class="request-info__value">{{ clientRequest.object }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Год</span>
              <span class="request-info__value">{{ clientRequest.year }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Степень износа, %</span>
              <span class="request-info__value">{{ clientRequest.wear_degree | septhousand }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Стоимость за 1 единицу с НДС, руб</span>
              <span class="request-info__value">{{ clientRequest.price | septhousand:2 }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Количество</span>
              <span class="request-info__value">{{ clientRequest.count }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Итого стоимость с НДС, руб</span>
              <span class="request-info__value">{{ clientRequest.sum | septhousand:2 }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Тип лизинговых платежей</span>
              <span class="request-info__value">{{ leasingPayTypes[clientRequest.pay_type] || '- незивестен -' }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Срок лизинга, мес</span>
              <span class="request-info__value">{{ clientRequest.limitation }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Размер аванса, руб</span>
              <span class="request-info__value">{{ clientRequest.prepaid | septhousand:2 }}</span>
            </p>
            <p class="request-info__line clearfix" *ngIf="clientRequest.company_declarer.name">
              <span class="request-info__name">Владелец лота готов менять форму ДКП</span>
              <span class="request-info__value">{{ clientRequest.is_change_contract_of_sale ? 'Да' : 'Нет' }}</span>
            </p>
          </ng-container>

          <ng-container *ngIf="isGuarantee">
            <p class="request-info__line clearfix">
              <span class="request-info__name">Сумма банковской гарантии</span>
              <span class="request-info__value">{{ clientRequest.sum }}</span>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Срок банковской гарантии</span>
              <span class="request-info__value">{{ clientRequest.limitation }}</span>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Федеральный закон</span>
              <span class="request-info__value">{{ fzName[clientRequest.fz] }}</span>
            </p>
            <p class="request-info__line clearfix">
              <span class="request-info__name">Ссылка на конкурс</span>
              <span class="request-info__value"><a [href]="clientRequest.competition_url" target="_blank" [title]="clientRequest.competition_url" class="competition-url"><fa-icon [icon]="iconLink"></fa-icon> {{ clientRequest.competition_url }}</a></span>
            </p>
          </ng-container>

          <p class="request-info__line clearfix">
            <span class="request-info__name">Кол-во предложений на заявку</span>
            <span class="request-info__value">{{ clientRequest.offers_count || 0 }}</span>
          </p>
          <p class="request-info__line clearfix">
            <span class="request-info__name">Статус заявки</span>
            <span class="request-info__value">{{ clientRequest.status.msg }}</span>
          </p>

          <ng-container *ngIf="isLeasing">
            <hr />

            <div class="request-info__line mb-4 clearfix">
              <span class="mb-2">Схема расчётов по договору поставки</span>
              <div class="pay-schema">
                <div *ngFor="let value of clientRequest.pay_schema" [title]="(value | septhousand) + '%'" [style.width]="(value | septhousand) + '%'"></div>
              </div>
            </div>

            <p class="request-info__line clearfix">
              <span class="mb-2">График сезонности, %</span>
              <app-vertical-column-chart [values]="chartValues()"></app-vertical-column-chart>
            </p>

            <div *ngIf="clientRequest.specification || clientRequest.contract">
              <hr />

              <p class="file-label" *ngIf="clientRequest.specification">
                <a [download]="clientRequest.specification.filename" [href]="clientRequest.specification.doc" target="_blank">
                  <fa-icon [icon]="fileIcon"></fa-icon>Спецификация оборудования
                </a>
              </p>
              <p class="file-label" *ngIf="clientRequest.contract">
                <a [download]="clientRequest.contract.filename" [href]="clientRequest.contract.doc" target="_blank">
                  <fa-icon [icon]="fileIcon"></fa-icon>Контракт на поставку
                </a>
              </p>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="mb-4" *ngIf="clientRequest.docs.length">
        <div class="info-block-heading bg-primary text-light p-4" (click)="docsVisible = !docsVisible" (selectstart)="$event.preventDefault()">
          Документы
          <fa-icon [icon]="docsVisible ? iconChevronUp : iconChevronDown" class="float-right"></fa-icon>
        </div>
        <div class="bg-white" *ngIf="docsVisible">
          <ng-container *ngFor="let type of clientRequest.getDocTypes()">
            <div class="p-4 pb-0">
              <div class="text-primary mb-3">{{ type.label }}</div>
              <div class="mb-3" *ngFor="let file of clientRequest.getDocsByType(type.id)">
                <div class="d-flex align-items-center mb-2">
                  <img src="/assets/svg/redesign/file.svg">
                  <div class="file-label ml-3" [title]="file.filename">{{ file.filename }}</div>
                </div>
                <div class="d-flex align-items-center text-muted">
                  <div>{{ file.human_size }}</div>
                  <div class="ml-auto"><a [href]="file.doc" [download]="file.filename" [title]="file.filename"><img src="/assets/svg/download.svg" /></a></div>
                </div>
              </div>
            </div>
            <hr class="m-0">
          </ng-container>
        </div>
      </div>

      <button class="mb-5 btn btn-danger btn-block" title="Удалить заявку" *ngIf="showDelete" (click)="delete()">Удалить</button>
    </div>

    <div class="col">
      <div class="text-center"><fa-icon *ngIf="!offers" [icon]="iconLoading" [spin]="true"></fa-icon></div>
      <ng-container *ngIf="offers">
        <app-request-content-user class="request-card request-responses mb-4" [clientRequest]="clientRequest" [offers]="offers" *ngIf="isUser"></app-request-content-user>
        <app-request-content-factor class="request-card request-responses" [clientRequest]="clientRequest" [offer]="offers[0]" *ngIf="isFactor"></app-request-content-factor>
      </ng-container>
    </div>
  </div>
</div>

<app-consult-block></app-consult-block>
