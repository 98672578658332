import { Component } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { AuthService } from '../../services/auth.service';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IClientRequest, IClientRequestDetailed } from '../../types';
import { ClientRequestResource } from '../../client-request-resource';
import { serviceTypeNames } from '../../constants';

@Component({
  selector: 'app-page-requests',
  templateUrl: './page-requests.component.html',
  styleUrls: ['./page-requests.component.scss']
})
export class PageRequestsComponent {
  readonly iconCalendar = faCalendar;
  readonly iconLoading = faCircleNotch;

  readonly isUser = !this._auth.authenticatedUser().isFactor();

  readonly archive: boolean = this.route.snapshot.data.archive;
  readonly clientRequestResource: ClientRequestResource<IClientRequest, IClientRequestDetailed> = this.route.snapshot.data.resource;
  readonly serviceType = this.clientRequestResource.serviceType;
  readonly serviceTypeName = serviceTypeNames[this.serviceType];
  readonly title = this.serviceTypeName[0].toUpperCase() + this.serviceTypeName.slice(1);

  constructor(
    private _auth: AuthService,
    private _fb: FormBuilder,
    private _router: Router,
    public route: ActivatedRoute
  ) { }
}
