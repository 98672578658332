import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnyClientRequest, ClientServiceType } from '../../../types';
import { serviceTypeRoutes } from '../../../constants';

@Component({
  selector: 'app-request-table',
  templateUrl: './request-table.component.html',
  styleUrls: ['./request-table.component.scss']
})
export class RequestTableComponent {
  @Input() serviceType: ClientServiceType;
  @Input() requests: AnyClientRequest[];
  @Input() pageSize = 11;
  @Input() page = 1;

  ClientServiceType = ClientServiceType;

  constructor(private _router: Router) { }

  toRequest(request: AnyClientRequest) {
    const route = ['account', serviceTypeRoutes[this.serviceType], request.id];

    if (request.draft) {
      route.push('edit');
    }

    this._router.navigate(route);
  }
}
