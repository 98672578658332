import { Injectable } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { from, Observable } from "rxjs";
import { CodeConfirmModalComponent } from "../modals/code-confirm-modal/code-confirm-modal.component";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

function isResponseAboutNullCode(response: HttpErrorResponse) {
  return response.error.sms_code
    && response.error.sms_code.length == 1
    && response.error.sms_code[0] == 'Это поле обязательно.';
}

@Injectable({
  providedIn: 'root'
})
export class CodeConfirmService {
  constructor(private _modal: NgbModal, private _http: HttpClient) { }

  request(
    endpoint: string,
    data: object,
    method: 'post' | 'put' | 'patch' = 'post',
    title = 'Подтвердите действие'
  ) {

    return new Observable(subscriber => {
      const successCallback = result => {
        subscriber.next(result);
        subscriber.complete();
      };

      const errorCallback = response => {
        subscriber.error(response);
      };

      this.send(endpoint, method, data).subscribe(
        successCallback,

        (response: HttpErrorResponse) => {
          if (isResponseAboutNullCode(response)) {
            this._modal.hasOpenModals() && this._modal.dismissAll();
            const modal = this._modal.open(CodeConfirmModalComponent, { size: "lg" });
            Object.assign(modal.componentInstance, {endpoint, data, method, title});

            from(modal.result).subscribe(successCallback, errorCallback);
          } else {
            errorCallback(response);
          }
        }
      )
    });
  }

  private send(endpoint, method, data, code = null) {
    if (code !== null) {
      data.sms_code = code;
    }

    switch (method) {
        case "post":
          return this._http.post(endpoint, data);
        case "put":
          return this._http.put(endpoint, data);
        case "patch":
          return this._http.patch(endpoint, data);
      }
  }
}
