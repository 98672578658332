import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  UrlTree,
  Resolve
} from '@angular/router';
import { AuthService } from './services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, Resolve<User> {
  constructor(
    private _modals: NgbModal,
    private _auth: AuthService,
    private _router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._auth.isAuthenticated()) {
      return true;
    } else {
      this.goToLogin(state.url);
      return false;
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Promise<User> | User {
    return this._auth.authenticatedUser();
  }

  goToLogin(backUrl?: string): Promise<boolean> {
    const extras = backUrl ? {queryParams: {next: backUrl}} : undefined;
    return this._router.navigate(['/login'], extras);
  }
}
