<ng-container *ngIf="!authenticated">
  <h3>Зарегистрируйтесь сейчас!</h3>
  <app-sign-up-form [labels]="false" [placeholders]="true"></app-sign-up-form>
</ng-container>

<ng-container *ngIf="authenticated && !isFactor">
  <h3 class="mt-3">Подайте заявку сейчас!</h3>
  <a [routerLink]="['/', 'account', type, 'create']" class="btn btn-primary btn-lg btn-block create-request-btn">Подать заявку</a>
</ng-container>

<ng-container *ngIf="authenticated && isFactor">
  <h3 class="mt-3">Начните работу с заявками!</h3>
  <a [routerLink]="['/', 'account', type]" class="btn btn-primary btn-lg btn-block create-request-btn">К списку заявок</a>
</ng-container>

