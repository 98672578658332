import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ServicesTypesService } from '../../../services/services-types.service';
import { creditTargets } from '../../../constants';
import { IClientService } from '../../../types';
import { CreditDetailed } from '../../../../models/client-request/credit-detailed';
import { AuthService } from '../../../services/auth.service';
import { IRequestSubComponent } from '../interfaces';

const VAT_FORMAT: RegExp = /^\d{10,12}$/;
const TERM_VALIDATOR = [Validators.required, Validators.min(1)];
const PRICE_VALIDATOR = [Validators.required, Validators.min(100000), Validators.max(10000000000)];

function genValidatorArray(count: number, validator: any, data = []) {
  return new Array(count)
    .fill(null)
    .map((v, i) => [parseFloat(data[i]) || '', validator]);
}

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss']
})
export class CreditComponent implements OnInit, IRequestSubComponent {
  @Output() submitted = new EventEmitter<FormGroup>();
  @Output() loadInn1Clicked = new EventEmitter<{inn: string}>();
  @Input() service: IClientService;
  @Input() enrichOngoing = false;
  @Input() inn1Error: string;
  @Input() loading: boolean;
  @Input() data: CreditDetailed;

  form: FormGroup;

  creditTargets = creditTargets;
  lockInn = false;

  get revenue(): FormArray {
    return this.form.get('revenue') as FormArray;
  }

  static validateTerm(form: FormGroup) {
    const limit = form.get('credit_target').value
      && creditTargets[form.get('credit_target').value].term_limit < form.get('credit_term').value;
    const errors = form.get('credit_term').errors || {};

    if (limit) {
      errors.limit = true;
    } else {
      delete errors['limit'];
    }

    if (Object.keys(errors).length === 0) {
      form.get('credit_term').setErrors(null);
    } else {
      form.get('credit_term').setErrors(errors);
    }
  }

  static validateSum(form: FormGroup) {
    const
      errors = form.get('sum').errors || {},
      revenueArray = form.get('revenue') as FormArray,
      revenueSum = CreditComponent.getRevenueSum(revenueArray);

    if (revenueSum <= 0) {
      errors.no_revenue = true;
    } else {
      delete errors['no_revenue'];
      const limit = CreditComponent.calcSumLimit(revenueSum) < form.get('sum').value;

      if (limit) {
        errors.limit = true;
      } else {
        delete errors['limit'];
      }
    }

    if (Object.keys(errors).length === 0) {
      form.get('sum').setErrors(null);
    } else {
      form.get('sum').setErrors(errors);
    }
  }

  static getRevenueSum(array: FormArray): number {
    return array.controls.reduce((s, e) => s + (e.value || 0), 0);
  }

  static calcSumLimit(revenueSum: number): number {
    return Math.round(revenueSum / 15 * 2);
  }

  constructor(private _servicesTypes: ServicesTypesService, private _fb: FormBuilder, private _auth: AuthService) {
  }

  ngOnInit(): void {
    const user = this._auth.authenticatedUser();

    const {
      company_declarer,
      credit_target,
      credit_term,
      sum,
      material_losses,
      revenue
    } = this.data || {} as {[k: string]: any};

    let { inn } = company_declarer || {inn: ''};

    if (user.rank.slug === 'clients' && user.legal_detail && user.legal_detail.inn) {
      inn = user.legal_detail.inn;
      this.lockInn = true;
    }

    this.form = this._fb.group({
      contract_period: [0],
      credit_target: [credit_target || 1],
      credit_term: [credit_term || '', TERM_VALIDATOR],
      sum: [sum || '', PRICE_VALIDATOR],
      material_losses: [material_losses || false],
      revenue: this._fb.array(genValidatorArray(5, PRICE_VALIDATOR, revenue || [])),
      inn: [inn, [Validators.required, Validators.pattern(VAT_FORMAT)]]
    }, {
      validators: [
        CreditComponent.validateSum,
        CreditComponent.validateTerm
      ]
    });
  }

  exportData(): object {
    return this.form.value;
  }

  revenueLabel(n) {
    return (parseInt(n, 10) + 1) + '-й квартал';
  }

  returnFirstError(name: string): ValidationErrors | null {
    const controls = (this.form.get(name) as FormArray).controls;

    for (const control of controls) {
      if (control.touched && control.invalid) {
        return control.errors;
      }
    }

    return null;
  }

  loadInn1() {
    this.form.updateValueAndValidity({ emitEvent: true });

    if (!this.validScoring1()) { return; }

    this.loadInn1Clicked.emit({ inn: this.form.get('inn').value });
  }

  validScoring1(): boolean {
    return this.form.get('inn').valid
      && this.form.get('credit_term').valid
      && this.form.get('sum').valid
      && !this.inn1Error;
  }

  getSumLimit(): number {
    return CreditComponent.calcSumLimit(this.getRevenueSum());
  }

  getRevenueSum(): number {
    return CreditComponent.getRevenueSum(this.form.get('revenue') as FormArray);
  }
}
