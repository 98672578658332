<div class="modal-header">
  <h2 class="modal-title">Инструкция пользователя</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()"></button>
</div>
<div class="modal-body" style="padding: 0;">
  <object
  [data]="manualUrl"
  type="application/pdf"
  width="100%"
  height="500px"
  (load)="loaded()"
  style="height: 75vh;">
    <p>Ваш брузер не поддерживает просмотр PDF</p>
    <a class="btn btn-info" href="https://storage.yandexcloud.net/myfi-docs/user-manual.pdf">Скачать инструкцию</a>
</object>
</div>
