<div class="messages" #messagesElement (scroll)="onMessagesScroll($event)">
  <div class="message clearfix" [ngClass]="{my:  message.is_mine}" *ngFor="let message of messages$ | async">
    <span class="message-time">{{ messageTime(message.created_at) }}</span>
    <h5>{{ message.is_mine ? 'Я' : message.user }}</h5>
    {{ message.message}}
    <ng-container *ngIf="message.docs && message.docs.length">
      <p class="file" *ngFor="let doc of message.docs">
        <fa-icon [icon]="iconFile"></fa-icon>
        <a [href]="doc.doc" class="name" target="_blank" [title]="doc.filename" [download]="doc.doc">{{ doc.filename }}</a>
        <span class="size">{{ doc.human_size }}</span>
      </p>
    </ng-container>
    <div class="message-status" *ngIf="message.is_mine && message.delivery_at" [title]="messageStatus(message)">
      <fa-icon [icon]="message.read_at ? iconDoubleCheck : iconCheck"></fa-icon>
    </div>
  </div>

  <ng-container *ngIf="messages$ | async as messages">
    <div class="placeholder" *ngIf="!messages.length">
      <div class="placeholder-icon"></div>
      <p class="placeholder-text"><ng-container *ngIf="enabled; then canStartText else canNotStartText"></ng-container></p>
      <ng-template #canStartText>Вы можете начать чат с вашим клиентом. Так же можно прикрепить файлы к сообщению.</ng-template>
      <ng-template #canNotStartText>Чат станет доступен, когда предложение будет принято.</ng-template>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="enabled">
  <div class="text">
    <button class="attach-file" [disabled]="fileDisabled()" (click)="attachFile()"><fa-icon [icon]="iconClip" size="lg"></fa-icon></button>
    <textarea placeholder="Текст вашего сообщения" (keypress)="areaKeyPress($event)" [(ngModel)]="text"></textarea>
    <button class="send" (click)="send()"><fa-icon [icon]="iconPlane" size="lg"></fa-icon></button>
  </div>
  <ngb-progressbar class="my-2" *ngIf="fileUploading" type="info" height="10px" [striped]="true" [animated]="true" [value]="fileProgress"></ngb-progressbar>
  <p class="text-danger" *ngIf="fileError">{{ fileError }}</p>
  <div class="docs" *ngIf="files.length">
    <h5>Прикреплённые документы:</h5>
    <p class="file" *ngFor="let file of files">
      <fa-icon [icon]="iconFile"></fa-icon><span class="name" [title]="file.name">{{ file.name }}</span> <fa-icon (click)="removeFile(file.id)" class="remove" [icon]="iconTimes"></fa-icon>
    </p>
  </div>
</ng-container>

<app-file-uploader
  class="d-none"
  [docType]=""
  #uploader
></app-file-uploader>
