<div class="modal-header">
  <h2 class="modal-title">{{ title }}</h2>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-8 offset-2">
      <form [formGroup]="form" (submit)="send()">
        <h4 class="text-center">Мы прислали SMS-код на ваш номер</h4>
        <h4 class="mb-4 text-center">{{ phone }}</h4>

        <div class="form-group mb-4">
          <label>Введите SMS-код</label>
          <div class="row">
            <div class="col-4 code-group" [attr.data-placeholder]="getPlaceholder()">
              <input type="text"
                     class="form-control code"
                     formControlName="sms_code"
                     maxlength="6"
                     [class.is-invalid]="code.touched && code.invalid || backendErrors?.sms_code || backendErrors?.non_field_errors"
              />
            </div>
            <div class="col-8">
              <button type="submit" class="btn btn-primary btn-lg btn-block" [disabled]="form.invalid || loading">
                Подтвердить
                <fa-icon [icon]="iconLoading" class="ml-2" *ngIf="loading" [spin]="true"></fa-icon>
              </button>
            </div>
          </div>

          <ng-container *ngIf="code.touched && code.invalid || backendErrors">
            <div class="text-danger mt-1" *ngIf="code.errors?.required">Необходимо ввести код</div>
            <div class="text-danger mt-1" *ngIf="code.errors?.pattern">Код - это 6 цифр. Он должен придти по смс.</div>
            <ng-container *ngFor="let errorName of ['new_mobile_phone', 'non_field_errors']">
              <ng-container *ngIf="backendErrors && backendErrors[errorName]">
                <div class="text-danger mt-1" *ngFor="let error of backendErrors[errorName]">{{ error }}</div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
