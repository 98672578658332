import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faChevronDown, faChevronUp, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { AnyClientRequest, AnyOffer, ClientServiceType, IOffer } from '../../../types';
import { Offer } from '../../../../models/offer/offer';
import { AnalyticService } from '../../../services/analytic.service';
import { guaranteeOfferStatusText } from '../../../constants';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-request-content-user',
  templateUrl: './request-content-user.component.html',
  styleUrls: ['./request-content-user.component.scss']
})
export class RequestContentUserComponent implements OnInit {
  @Input() clientRequest: AnyClientRequest;
  @Input() offers: AnyOffer[];
  @Input() clientServiceType: ClientServiceType;

  @ViewChild('factoringView')
  set factoringView(tmpl) {
    if (this.clientRequest.service.type === ClientServiceType.FACTORING) {
      this.view = tmpl;
      this._changeDetector.detectChanges();
    }
  }

  @ViewChild('leasingView')
  set leasingView(tmpl) {
    if (this.clientRequest.service.type === ClientServiceType.LEASING) {
      this.view = tmpl;
      this._changeDetector.detectChanges();
    }
  }

  @ViewChild('creditView')
  set creditView(tmpl) {
    if (this.clientRequest.service.type === ClientServiceType.CREDIT) {
      this.view = tmpl;
      this._changeDetector.detectChanges();
    }
  }

  @ViewChild('guaranteeView')
  set guaranteeView(tmpl) {
    if (this.clientRequest.service.type === ClientServiceType.GUARANTEE) {
      this.view = tmpl;
      this._changeDetector.detectChanges();
    }
  }

  ClientServiceType = ClientServiceType;
  iconLoading = faCircleNotch;
  iconDown = faChevronDown;
  iconUp = faChevronUp;
  iconLightbulb = faLightbulb;
  view: TemplateRef<any>;

  isGuarantee = false;

  guaranteeOfferStatusText = guaranteeOfferStatusText;

  loading = false;
  selectedOfferId: number = null;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _analytics: AnalyticService,
    private _changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.isGuarantee = this.clientRequest.service.type === ClientServiceType.GUARANTEE;
    console.log(this.offers);
  }

  select(id) {
    if (id === undefined || id === this.selectedOfferId) {
      this.selectedOfferId = null;
    } else {
      this.selectedOfferId = id;
    }

    const offer = this.offers.find((o: Offer<AnyClientRequest>) => o.updated);
    if (offer) {
      offer.updated = false;
      offer.reload().subscribe();
    }
  }

  toDate(created_at: string) {
    return new Date(created_at).toLocaleString();
  }

  accept(offer: IOffer) {
    this._metrikaReachGoal('acceptResponse');
    this.loading = true;

    console.log(offer);
    offer.accept().subscribe(
      () => {
        this.loading = false;
        this._analytics.eventGoogle('Accepted', 'Offers', 'Client', offer.id);
      },
      () => {
        this.loading = false;
      }
    );
    return false;
  }

  cancel(offer: IOffer) {
    this._metrikaReachGoal('cancelResponse');
    this.loading = true;

    offer.reject().subscribe(
      () => {
        this.loading = false;
        this._analytics.eventGoogle('Rejected', 'Offers', 'Client', offer.id);
      },
      () => {
        this.loading = false;
      }
    );
    return false;
  }

  done(offer: IOffer) {
    this._metrikaReachGoal('completeResponse');
    this.loading = true;

    offer.done().subscribe(
      () => {
        this.loading = false;
        this._analytics.eventGoogle('Funded', 'Offers', 'Client', offer.id);
      },
      () => {
        this.loading = false;
      }
    );
    return false;
  }

  isShowControls(offer: Offer<AnyClientRequest>) {
    return !this.clientRequest.archived && offer.isActive();
  }

  private _metrikaReachGoal(target: string) {
    this._analytics.eventYandex(`requestUser_${target}`, {
      params: {
        requestId: this.clientRequest.id,
        responseId: this.selectedOfferId
      }
    });
  }
}
