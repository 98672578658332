import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError, debounceTime } from "rxjs/operators";
import { of } from "rxjs";
import { FormControl } from "@angular/forms";

export function emailAsyncValidator(http: HttpClient) {
  return (emailInput: FormControl) => {
    const email = emailInput.value;
    return http.post(environment.apiEndpoint + '/users/factoring', { email })
    .pipe(
      debounceTime(2000),
      catchError(err => {
        if (err.error && err.error.email) {
          return of({backend: err.error.email});
        }

        return of(null);
      })
    )
  }
}
