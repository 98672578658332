import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {HeaderConfig} from './header-config.interface';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ArticlesService} from '../../../services/articles.service';
import {ArticleType} from '../../../types';
import {
  articleTypeUrls,
  supportEmail,
  supportPhone,
  supportPhoneFormat,
  personalDataAgreementFile
} from '../../../constants';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  set variant(value: 'white' | 'light') {
    this.bgClass = `bg-${value}`;
  }

  @Input()
  set scrollUpShow(value: boolean) {
    this._scrollUpShow = value;

    if (value) {
      this.getHostElement().style.position = 'fixed';
    } else {
      this.getHostElement().style.position = 'absolute';
    }
  }

  @Input() shadowed = false;

  @Input() set config(config: HeaderConfig) {
    if (config.hasOwnProperty('shadowed')) {
      this.shadowed = config.shadowed;
    } else {
      this.shadowed = false;
    }

    if (config.hasOwnProperty('scrollUpShow')) {
      this.scrollUpShow = config.scrollUpShow;
    } else {
      this.scrollUpShow = false;
    }

    if (config.hasOwnProperty('variant')) {
      this.variant = config.variant;
    } else {
      this.variant = 'light';
    }
  }

  bgClass = 'bg-light';
  menuOpen = false;
  user: User;
  supportPhone = supportPhone;
  supportPhoneFormat = supportPhoneFormat;
  supportEmail = supportEmail;
  personalDataAgreementFile = personalDataAgreementFile;

  articles$ = this._articles.tree(ArticleType.digital);
  articlesAdditional$ = this._articles.tree(ArticleType.additional);

  digitalServicesLink = articleTypeUrls[ArticleType.digital];
  additionalServicesLink = articleTypeUrls[ArticleType.additional];

  private _scrollUpShow = false;
  private _lastPageOffset = 0;
  private _staticHeight = 40;
  private _routerSub: Subscription;
  private _authSub: Subscription;

  constructor(
    private _hostElement: ElementRef,
    private _router: Router,
    private _articles: ArticlesService,
    private _auth: AuthService
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    if (this._scrollUpShow) {
      if (window.pageYOffset > this._staticHeight) {
        this.getHostElement().classList.add('detached');

        if (window.pageYOffset < this._lastPageOffset) {
          this.getHostElement().classList.remove('hidden');
        } else {
          this.getHostElement().classList.add('hidden');
        }
      } else if (window.pageYOffset === 0) {
        this.getHostElement().classList.remove('detached');
      }
    }

    this._lastPageOffset = window.pageYOffset;
  }

  ngOnInit(): void {
    if (this.scrollUpShow) {
      this.getHostElement().style.position = 'fixed';
    }

    this._routerSub = this._router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.menuOpen = false;
        this.getHostElement().classList.remove('opened');
        document.body.style.overflow = 'auto';
      }
    });

    this.user = this._auth.authenticatedUser();

    this._authSub = this._auth.onUpdate().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    if (this._routerSub) {
      this._routerSub.unsubscribe();
    }
  }

  getHostElement(): HTMLElement {
    return this._hostElement.nativeElement;
  }

  getName() {
    const maxLength = 18;

    const firstNameLength = this.user.first_name.length;
    const lastNameLength = this.user.last_name.length;
    const total = firstNameLength + lastNameLength + 1;

    let name;

    /*
     * If "LONGFIRSTNAME LONGLASTNAME" length > maxLength
     * then it should be shortened to
     * "LONGFIRSTNAME L."
     * or to
     * "LONGFIRSTNAME"
     * or to
     * "LONGFIRSTN..." maxLength symbols length
     */

    if (total > maxLength) {
      if (firstNameLength > maxLength) {
        name = this.user.first_name.slice(0, maxLength - 3) + '...';
      } else if (lastNameLength - 2 > maxLength - total) {
        name = `${this.user.first_name} ${this.user.last_name.slice(0, 1)}.`;
      } else {
        name = this.user.first_name;
      }
    } else {
      name = `${this.user.first_name} ${this.user.last_name}`;
    }

    return name;
  }

  logout() {
    this._auth.logout().subscribe(() => {
      this._router.navigate(['/login']);
    });
  }
}
